import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Text, Avatar, Heading, Icon } from '.';
import styled from '@emotion/styled';
import cross from '../assets/images/cross.svg';
import { InputText } from '../components/form';
import { TemplateCard } from '.';
import './Modal.css';
import profile_pic from '../assets/images/profile_pic.png';
import { REQUEST_GENERATE_SUMMARY_DATA } from '../constants/analysis/generateSummaryConstants';
import {  useDispatch ,useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';

import { style } from 'd3';
import crossImg from '../images/cross.svg';
import commentIcon from '../images/comment.svg';
import successIcon from '../images/successIcon.svg';

const Pill = styled.span`
	font-size: 8px;
	font-family: "Readex Pro",
	 sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
`;
const modal1 = {
    width: '300px'
}

const InputTextContainer = styled.div`

    .iconContainer {
        position: absolute;
        top: 30%;
        right: 15px;

    }
    span {
        font-size: 18px;
        color: var(--greyBlue);
    }

`;

export default function SummaryGeneratedModal(props) {
    const [isShown, setIsShown] = useState('d-block');
    const {id} = useParams();
    const dispatch = useDispatch();

    const handleClose = () => {
        setIsShown('');
        props.setGenerated(false)
    }
   
    
    const handleSummaryGeneration = () =>{
        dispatch({
            type: REQUEST_GENERATE_SUMMARY_DATA,
            payload: {
                project_ID:id
               
            }
        });

        setIsShown('')
    }
    return (
        
                       

                <div className={`modal ${isShown}`} style={{backgroundColor: 'rgba(64,64,64,0.8)'}}>
                   
                    <div className="modal-dialog  modal-dialog-centered"  role="document" tabIndex="-1" style={{ maxWidth: "450px",padding: '1%'}}>
                        <div class="modal-content" style={{borderRadius: "10px"}} >
                            <div class="modal-body" >
                                <div style={{textAlign: 'right'}}>
                                    <button style={{border: 'none', background: 'inherit'}} onClick={handleClose}>
                                        <img src={crossImg} style={{width: '100%'}}></img>
                                    </button>
                                    
                                </div>
                                <div style={{textAlign: 'center', width: '100%',  marginBottom: '4%'}}>
                                    <img style={{width: '20%'}} src={successIcon}></img>
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    <h3 style={{color: "#38454F",fontWeight: "600"}}>AI Summaries</h3>
                                    <div style={{ marginTop: '4%', marginBottom: '4%'}}>
                                        <h5 style={{margin: 0}}>AI Summaries will be available in the</h5>
                                        <h5>Analysis section in a while</h5>
                                    </div>
                                </div>
                               

                                <div className='row' style={{ paddingTop: '20px',display:'flex',justifyContent: 'space-between' }}>
                              
                                        <Button
                                            className='ml-3'
                                            size='small'
                                            width='20px'
                                            variant='outlined'
                                            data-dismiss='modal'
                                            onClick={()=> {window.location.href = `/project/${props.id}/field`}}
                                            
                                        >
                                            Back to Field
                                        </Button>
                                   
                                    
                                        <Button
                                            
                                            size='small'
                                            width='20px'
                                            data-dismiss="modal"

                                            onClick={() => {props.setStep(3)}}
                                            style={{marginLeft: 0,marginRight:'3%'}}
                                        >
                                           Edit Video
                                        </Button>


                                    
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                
            
        
    )
}