import React, { useEffect, useState } from 'react';

import Wordcloud from './charts/dthree/wordcloud';
import { Button, Heading } from '../../common';
import styled from '@emotion/styled';
import copy from '../../images/copy.svg';
import copy_active from '../../images/copy_active.svg';
import { Checkbox } from '../../components/form';
import { words } from './charts/temp_data';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_WORD_ANSWER } from '../../constants/analysis/analysisConstants';
import SummaryTextContainer from '../../common/SummaryTextContainer';
import loading from '../../assets/images/loading.svg';
import downArrow from '../../images/downArrow.svg';
import upArrow from '../../images/uparrow.svg';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useInView } from 'react-intersection-observer';
import { Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
const SeeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1;
  padding: 0 !important;
  padding-top: 2px !important;
  .reverse {
    transform: rotate(-180deg);
  }
`;
const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--midWhite);
  color: var(--sapGreen);
  border-radius: 3px;
  padding: 0.4rem 0.9rem;
  img {
    padding-right: 5px;
  }
  :hover {
    background: var(--midWhite);
    color: ${props => props.buttonColor};
  }

  :disabled,
  [disabled] {
    background: var(--midWhite);
    border: none;
    color: var(--middleGrey);

    :hover {
      background: var(--midWhite);
      border: none;
      color: var(--middleGrey);
      cursor: not-allowed;
    }
  }
`;

const ResponseContainer = styled.div`
  /* Colour/White */

  background: #ffffff;

  border: 1px solid rgba(121, 142, 143, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
  &:last-child {
    margin-right: 0rem;
  }
  p {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;

    /* Colour/DeepBlue */

    color: var(--deepBlue);
    span {
      font-weight: 700;
    }

    margin: 0;
    padding: 0;
  }
`;
const StyledAccordion = styled(Accordion)`
  display: block;
  border: none;
  background-color: transparent;

  .accordion-item {
    border: none;
    background-color: transparent;
  }

  .accordion-header {
    button {
      background-color: transparent;
      border: none;
    white-space: nowrap;
  }
`;
const Response = ({ id, name, information, selection, onUpdate }) => {
  const [selected, setSelected] = useState(selection);

  useEffect(() => {
    setSelected(selection);
  }, [selection]);

  return (
    <ResponseContainer>
      <Checkbox
        color="var(--deepBlue)"
        fontSize="10px"
        iconSize="10px"
        inactiveColor="var(--white)"
        className="mr-1"
        checked={selected.indexOf(id.toString()) != -1}
        onChange={ev => {
          onUpdate(ev.target.value);
        }}
        value={id}
      />
      <div>
        <Heading fontSize="10px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0">
          {name}
        </Heading>
        <p className="mb-0 pb-0 mt-1">{information}</p>
      </div>
    </ResponseContainer>
  );
};
function AnalysisWordCloud({
  questionObject,
  qid,
  question,
  information,
  handleCopyClick,
  dataset,
  questionID,
  isLoadingQuestion
}) {
  const dispatch = useDispatch();
  const responses = useSelector(state => state.analysisReducer.wordGraphData);
  const [activeWord, setActiveWord] = useState('');
  const [selection, setSelection] = useState([]);
  const [allResponse, setAllResponse] = useState({});
  const [selectedItems, setSelectedItems] = useState(false);
  const [selectedGenerateSummary, setSelectedGenerateSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showResponses, setShowResponses] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  const onWordClick = word => {
    dispatch({
      type: REQUEST_WORD_ANSWER,
      payload: {
        word: word,
        question_ID: questionID
      }
    });
    setActiveWord(word);
  };
  useEffect(() => {
    if (responses?.length > 0 && activeWord !== '') {
      let t = allResponse;
      t[responses[0].question_ID] = responses;
      setAllResponse({ ...t });
    } else {
      setAllResponse({});
    }
  }, [responses, activeWord]);

  const toggleShowResponses = () => {
    setShowResponses(!showResponses);
  };
  useEffect(() => {
    if (responses?.length > 0 && activeWord != '') {
      let t = allResponse;
      t[responses[0].question_ID] = responses;
      setAllResponse({ ...t });
    } else {
      setAllResponse({});
    }
  }, [responses]);
 
 
  const [showMoreResponses, setShowMoreResponses] = useState(false);

  const toggleShowMoreResponses = () => {
    setShowMoreResponses(!showMoreResponses);
  };

  const onAnswerSelectionChange = id => {
    // if(selection.length >= 4){
    // 	console.log("Maximum of 4 selections are allowed")
    // 	return
    // }
    let t = selection;
    if (t.indexOf(id.toString()) != -1) {
      t.splice(t.indexOf(id.toString()), 1);
      if (t.length == 0) {
        setSelectedItems(false);
      }
    } else {
      t.push(id);
      setSelectedItems(true);
    }

    setSelection([...t]);
  };

  const selectAllVerbatims = (e, questionId) => {
    let t = [];
    if (e.target.checked) {
      allResponse[questionId].map(response => {
        t = [...t, response.questionAnswer_ID.toString()];
      });
    }
    setSelection(t);
    setSelectedItems(e.target.checked);
  };

  return (
    <div className=" question" style={{ borderBottom: '1px solid var(--greyBlue30)' }} ref={ref}>
      {inView ? (
        <StyledAccordion>
          <Accordion.Item eventKey="0" style={{ border: 'none' }}>
            <div className="d-flex justify-content-between my-2">
              <Accordion.Header style={{ border: 'none' }}>
                <div style={{ width: '100%' }}>
                  <Heading
                    fontSize="14px"
                    fontWeight="700"
                    color="var(--deepBlue)"
                    className="mb-0 pb-0"
                    breakHeading={false}
                    border="none"
                    onClick={() => setActiveKey(activeKey === '0' ? null : '0')}
                  >
                    {question}
                    {activeKey === '0' ? (
                      <FaChevronUp size={10} style={{ marginLeft: '8px' }} />
                    ) : (
                      <FaChevronDown size={10} style={{ marginLeft: '8px' }} />
                    )}

                    {information && (
                      <span>
                        <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                          {information}
                        </Heading>
                      </span>
                    )}
                  </Heading>
                </div>
              </Accordion.Header>
              <div className="d-flex align-items-center">
                <CustomButton
                  fontWeight="400"
                  fontSize="10px"
                  className="ml-2"
                  size="small"
                  variant="outlined"
                  buttonColor="var(--sapGreen)"
                  onClick={() => handleCopyClick(qid, {}, question, questionObject)}
                >
                  <img src={copy_active} /> Copy to Report
                </CustomButton>
              </div>
            </div>
            <Accordion.Body>
              {isLoadingQuestion !== false ? (
                <div style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
                  <Skeleton height={60} width="100%" style={{ marginBottom: '0.5rem' }} />
                </div>
              ) : (
                <div>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      background: '#F2F4F4',
                      textAlign: 'center',
                      padding: '0.5rem 1rem'
                    }}
                  >
                    <div>
                      <Wordcloud dataset={dataset} onWordClick={onWordClick} />
                    </div>
                  </div>
                  <div>
                    {!allResponse.hasOwnProperty(questionID) && (
                      <div className="d-flex justify-content-between my-3 align-items-center">
                        <div className="d-flex">
                          <Heading
                            fontSize="12px"
                            fontWeight="500"
                            color="var(--deepBlue)"
                            className="mb-0 pb-0"
                            breakHeading={false}
                          >
                            See Verbatims:
                          </Heading>

                          <Heading
                            fontSize="12px"
                            fontWeight="500"
                            color="var(--middleGrey)"
                            className="mb-0 pb-0 ml-2"
                          >
                            Click a keyword in the wordcloud
                          </Heading>
                        </div>

                        <div className="d-flex align-items-center">
                          <CustomButton
                            fontWeight="400"
                            fontSize="10px"
                            className="ml-3"
                            size="small"
                            variant="outlined"
                            buttonColor="var(--sapGreen)"
                            disabled={true}
                            onClick={handleCopyClick}
                          >
                            {true ? <img src={copy} /> : <img src={copy_active} />} Copy to Report
                          </CustomButton>
                        </div>
                      </div>
                    )}
                    {allResponse.hasOwnProperty(questionID) && (
                      <div className="d-flex justify-content-between my-3 align-items-center">
                        <div className="d-flex">
                          <Heading
                            fontSize="12px"
                            fontWeight="500"
                            color="var(--deepBlue)"
                            className="mb-0 pb-0"
                            breakHeading={false}
                          >
                            See Verbatims:
                          </Heading>
                          <Heading fontSize="12px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0 ml-2">
                            {activeWord === '' ? 'Section' : activeWord} (
                            {responses && responses.filter(q => q.question_ID == questionID).length})
                          </Heading>
                          <SeeButton
                            className="mb-0 pb-0 ml-2"
                            simple
                            color="var(--primaryColor)"
                            fontSize="0.750rem"
                            fontWeight="500"
                            onClick={toggleShowResponses}
                          >
                            {/* {showResponses ? 'Show Less' : 'Show More'} */}
                            {showResponses ? (
                              <>
                                See Less <img src={GreenDropdown} className="ml-2" />
                              </>
                            ) : (
                              <>
                                See More <img src={GreenDropdown} className="ml-2 reverse" />
                              </>
                            )}
                          </SeeButton>
                        </div>
                        {/* <div className='d-flex align-items-center'>
				<SeeButton
									style={{ display: 'inline-block' }}
									className="mt-0"
									simple
									color="var(--primaryColor)"
									fontSize="0.875rem"
									fontWeight="500"
									onClick={toggleShowResponses}

								>
									{showResponses ? 'Show Less' : 'Show More'}

								</SeeButton>
							</div> */}

                        <div className="d-flex align-items-center">
                          <Checkbox
                            color="var(--deepBlue)"
                            fontSize="10px"
                            iconSize="10px"
                            inactiveColor="var(--white)"
                            fontWeight="400"
                            className="mb-0"
                            onChange={e => selectAllVerbatims(e, questionID)}
                          >
                            {`Select All`}{' '}
                          </Checkbox>

                          {selectedItems == false ? (
                            <CustomButton
                              fontWeight="400"
                              fontSize="10px"
                              className="ml-3"
                              size="small"
                              variant="outlined"
                              buttonColor="var(--sapGreen)"
                              disabled={true}
                              onClick={handleCopyClick}
                            >
                              {true ? <img src={copy} /> : <img src={copy_active} />} Copy to Report
                            </CustomButton>
                          ) : (
                            <CustomButton
                              fontWeight="400"
                              fontSize="10px"
                              className="ml-3"
                              size="small"
                              variant="outlined"
                              buttonColor="var(--sapGreen)"
                              disabled={false}
                              onClick={() => {
                                let data = {};
                                data['word'] = activeWord;
                                let r = allResponse[questionID];
                                let t = [];
                                for (let i = 0; i < r.length; i++) {
                                  if (selection.indexOf(r[i].questionAnswer_ID.toString()) != -1) {
                                    t.push({
                                      name: r[i].name,
                                      text: r[i].answerText
                                    });
                                  }
                                }
                                data['answers'] = t;
                                handleCopyClick(qid, data, question, questionObject);
                              }}
                            >
                              {false ? <img src={copy} /> : <img src={copy_active} />} Copy to Report
                            </CustomButton>
                          )}
                        </div>
                      </div>
                    )}

                    {/* <div>
						<div
							style={{
								display: 'grid',
								gridTemplateColumns:
									'repeat(5, 1fr)',
								gap: '5px',
								gridAutoRows:
									'minmax(100px, auto)',
								justifyContent: 'space-between',
							}}
						>
							{allResponse.hasOwnProperty(questionID) && allResponse[questionID].map((d,i)=>{
								return (
									<Response
										name={d.name}
										information={d.answerText}
										selection={selection}
										onUpdate={onAnswerSelectionChange}
										id={d.questionAnswer_ID}
									/>
								)
							})}

						</div>
					</div> */}
                    {/* {showResponses && (
							<div>
									<div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '5px', gridAutoRows: 'minmax(100px, auto)', justifyContent: 'space-between' }}>
											{allResponse.hasOwnProperty(questionID) &&
													allResponse[questionID].map((d, i) => {
															return (
																	<Response
																			key={i}
																			name={d.name}
																			information={d.answerText}
																			selection={selection}
																			onUpdate={onAnswerSelectionChange}
																			id={d.questionAnswer_ID}
																	/>
															);
													})}
									</div>
							</div>
					)} */}
                    {/* <div>
					<div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '5px', gridAutoRows: 'minmax(100px, auto)', justifyContent: 'space-between' }}>
		{allResponse.hasOwnProperty(questionID) &&
													allResponse[questionID].map((d, i) => {
															return (
																	<Response
																			key={i}
																			name={d.name}
																			information={d.answerText}
																			selection={selection}
																			onUpdate={onAnswerSelectionChange}
																			id={d.questionAnswer_ID}
																	/>
															);
													})}
					</div>
					{allResponse.hasOwnProperty(questionID) && allResponse[questionID].length > 5 && (
							<div style={{ textAlign: 'center', marginTop: '10px' }}>
									{showAllResponses ? (
											<button onClick={toggleShowAllResponses}>Show Less</button>
									) : (
											<button onClick={toggleShowAllResponses}>Show More</button>
									)}
							</div>
					)}
			</div> */}
                    {showResponses && (
                      <div>
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 1fr)',
                            gap: '5px',
                            gridAutoRows: 'minmax(100px, auto)',
                            justifyContent: 'space-between'
                          }}
                        >
                          {allResponse.hasOwnProperty(questionID) &&
                            (showMoreResponses
                              ? allResponse[questionID].map((d, i) => (
                                  <Response
                                    key={i}
                                    name={d.name}
                                    information={d.answerText}
                                    selection={selection}
                                    onUpdate={onAnswerSelectionChange}
                                    id={d.questionAnswer_ID}
                                  />
                                ))
                              : allResponse[questionID]
                                  .slice(0, 5)
                                  .map((d, i) => (
                                    <Response
                                      key={i}
                                      name={d.name}
                                      information={d.answerText}
                                      selection={selection}
                                      onUpdate={onAnswerSelectionChange}
                                      id={d.questionAnswer_ID}
                                    />
                                  )))}
                        </div>
                        <center>
                          {' '}
                          {allResponse.hasOwnProperty(questionID) && allResponse[questionID].length > 5 && (
                            <div style={{ textAlign: 'center', marginTop: '10px', marginLeft: '45%' }}>
                              {/* <div onClick={toggleShowMoreResponses} style={{color: 'var(--primaryColor)',fontSize: '12px',cursor: 'pointer'}}>
							{showMoreResponses ? (
								<>
									Show Less <img src={GreenDropdown} className="ml-2" />
								</>
							) : (
								<>
									Show More <img src={GreenDropdown} className="ml-2 reverse" />
								</>
								)}
						</div> */}

                              <SeeButton
                                className="mb-0 pb-0 ml-2"
                                simple
                                color="var(--primaryColor)"
                                fontSize="0.750rem"
                                fontWeight="500"
                                onClick={toggleShowMoreResponses}
                              >
                                {/* {showResponses ? 'Show Less' : 'Show More'} */}
                                {showMoreResponses ? (
                                  <>
                                    See Less <img src={GreenDropdown} className="ml-2" />
                                  </>
                                ) : (
                                  <>
                                    See all <img src={GreenDropdown} className="ml-2 reverse" />
                                  </>
                                )}
                              </SeeButton>
                            </div>
                          )}{' '}
                        </center>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </StyledAccordion>
      ) : (
        <div>
          <div style={{ marginBottom: '1.5rem' }}>
            <Skeleton height={30} width="60%" style={{ marginBottom: '0.5rem' }} />
            <Skeleton count={3} height={20} width="100%" style={{ marginBottom: '0.5rem' }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default AnalysisWordCloud;
