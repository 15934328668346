import React, { useEffect, Suspense, lazy, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import { Nav, AppNav, SideNav, Spinner } from './common';
import { BeARespondentScreen, HowItWorksScreen, LandingScreen } from './views/StaticScreen';
import SignInScreen from './views/SignInScreen/SignInScreen';
import VerifyCode from './views/VerifyCode/VerifyCode';
import ResetPassword from './views/ResetPassword/ResetPassword';
import ResetPasswordEmailNotification from './views/ResetPassword/ResetPasswordEmailNotification';


const EditSetupScreen = lazy(() => import('./views/Project/EditSetupScreen'));
const SetupScreen = lazy(() => import('./views/Project/SetupScreen'));
const FieldsScreen = lazy(() => import('./views/Project/FieldsScreen'));
const CriteriaBudgetScreen = lazy(() => import('./views/Project/CriteriaBudgetScreen'));
const SetupSummary = lazy(() => import('./views/Project/SetupSummary'));
const ScheduleScreen = lazy(() => import('./views/Project/ScheduleScreen'));

export default function AuthScreen (){
    

    return (
        <div>
            {/* <Nav /> */}
            <Routes>
                <Route path="/how-it-works" element={<HowItWorksScreen />} />
                <Route path="/be-a-respondent" element={<BeARespondentScreen />} />
                <Route path="/sign-in" element={<SignInScreen />} />
                <Route path="/verify-code/:email" element={<VerifyCode />} />
                <Route path="/reset-password/:email" element={<ResetPassword />} />
                <Route path="/reset-password-via-link/:token" element={<ResetPasswordEmailNotification />} />
                <Route path="/" element={<LandingScreen />} />
            </Routes>
        </div>
    );
};