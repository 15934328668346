import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthScreen from './AuthScreen';

export default function AuthWrapper() {
  const location = useLocation();
  const token = localStorage.getItem('token');

  return token && token !== 'null' && token !== '' ? (
    <Outlet />
  ) : (
    // <Navigate to="/" replace />
    <AuthScreen />
  );
};