import React, { Component } from 'react';
import { useRef } from 'react';
import styled from '@emotion/styled';
import Select, { components } from 'react-select';
import ErrorText from './ErrorText';
import DropdownIcon from '../../images/dropdown.svg';
import { Icon } from '../../common';

import { color } from 'd3';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius: 0
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    ...state.selectProps.valueContainerStyle,
    padding: '0px'
  }),

  control: (provided, state) => ({
    ...provided,
    outline: 0,
    borderRadius: 0,
    border: 0,
    background: 'transparent',
    color: state.isSelected ? 'green' : '',
    borderBottom: state.selectProps.error
      ? '1px solid var(--errorColor)'
      : state.selectProps.selectedBorderBottom
      ? state.selectProps.selectedBorderBottom
      : '1px solid var(--darkBorderColor)',
    borderColor: state.isFocused ? 'var(--inputColor)' : 'var(--borderColor)',
    boxShadow: 'none',
    // minHeight: '36px',
    '&:hover': {
      borderColor: state.isFocused ? 'var(--inputColor)' : 'var(--borderColor)'
    },
    ...state.selectProps.controlStyle
  }),

  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #EFEFEF',
    borderRadius: '5px',
    width: 'auto',
    padding: '0 10px',
    // position: 'absolute',
    background: '#FFFFFF',
    // width: '220px',
    zIndex: 5,
    boxShadow: 'none',
    color: 'red'
  }),

  option: (provided, state) => ({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
    fontWeight: '500',
    color: 'var(--inputColor)',
    padding: '12px 7px',
    borderBottom: '1px solid #E2E2E2',
    cursor: 'pointer',
    whiteSpace: 'nowrap',

    '&:last-child': {
      borderBottom: 0
    },
    ...state.selectProps.optionStyle
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '1px'
  }),

  singleValue: (provided, state) => ({
    fontSize: state.selectProps.selectedFontSize ? state.selectProps.selectedFontSize : '1rem',
    fontWeight: state.selectProps.selectedFontWeight ? state.selectProps.selectedFontWeight : '800',
    color: state.selectProps.selectedColor ? state.selectProps.selectedColor : 'var(--inputColor)',

    ...state.selectProps.singleValueStyle
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  placeholder: (provided, state) => ({
    fontSize: '0.75rem',
    color: 'var(--mediumGrey)',
    ...state.selectProps.placeholderStyle
  }),

  IndicatorsContainer: (provided, state) => ({
    ...provided,
    padding: '0px',
    ...state.selectProps.indicatorContainer
  }),

  DropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0
  })
  // menu: (provided, state) => ({
  //   ...provided,
  //   width: state.selectProps.width,
  //   borderBottom: '1px dotted pink',
  //   color: state.selectProps.menuColor,
  //   padding: 20,
  // }),
};

const IconCustom = styled(Icon)`
  font-size: 0.75rem;
  margin-left: 5px;
  padding-top: 5px;
`;

const DownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={DropdownIcon} alt="Dropdown Icon" />
    </components.DropdownIndicator>
  );
};
const options = [{ lable: 'MP3', value: 'MP3' }];
export default function DeletableDropdown({
  onChange,
  options = [],
  cohortList = [],
  setCohortList = null,
  width,
  controlWidth,
  defaultValue,
  placeholder = 'Select one',
  className = '',
  Menu,
  SingleValue,
  isClearable = false,
  isSearchable = false,
  name,
  error = false,
  selectedFontSize,
  selectedFontWeight,
  selectedColor,
  DropdownIndicator = DownIndicator,
  selectedBorderBottom,
  menuIsOpen = false,
  setMenuIsOpen = null,
  crossOption,
  singleValueStyle = {},
  valueContainerStyle = {},
  controlStyle = {},
  indicatorContainer = {},
  optionStyle,
  value,
  passClickedonDelete = null,
  passCohortlist,
  passHandleDeleteDispatch,
  placeholderStyle,
  isDisabled,
  key
}) {
  const clickedOnDelete = useRef(false);
  const cohortlistref = useRef();

  const handleChange = opt => {
    onChange(opt);
    if (clickedOnDelete.current) setMenuIsOpen(true);
  };

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const handleDeleteOption = id => {
    let index = cohortList.findIndex(tag => tag.value === id);
    if (index !== -1) {
      let tempTagsList = [...cohortList];
      tempTagsList.splice(index, 1);
      cohortlistref.current = tempTagsList;
      setCohortList(tempTagsList);
      console.log('delete tag');
    } else {
      console.log('Tag Not Found');
    }
    clickedOnDelete.current = true;
    passClickedonDelete(clickedOnDelete.current);
    passHandleDeleteDispatch(id);
    passCohortlist(cohortlistref.current);
  };

  const handleClickOption = () => {
    clickedOnDelete.current = false;
    cohortlistref.current = options;
    setMenuIsOpen(false);
    if (crossOption) passClickedonDelete(clickedOnDelete.current);
  };

  const Option = ({ ...props }) => {
    return (
      <div>
        <components.Option {...props}>
          <span onClick={() => handleClickOption()}>{props.label}</span>
          {crossOption && props.value !== null && (
            <IconCustom
              color="var(--inputColor)"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleDeleteOption(props.value);
              }}
            >
              close
            </IconCustom>
          )}
        </components.Option>
      </div>
    );
  };

  let Components = {
    DropdownIndicator,
    Option
  };

  if (Menu) {
    Components = { ...Components, Menu };
  }
  if (SingleValue) {
    Components = { ...Components, SingleValue };
  }
  return (
    <>
      <Select
        key={defaultValue}
        value={value}
        styles={customStyles}
        components={Components}
        options={options}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        width={width}
        controlWidth={controlWidth}
        placeholder={placeholder}
        onChange={handleChange}
        menuIsOpen={menuIsOpen}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        error={error}
        className={`${error ? 'is-invalid' : { className }}`}
        selectedFontSize={selectedFontSize}
        selectedFontWeight={selectedFontWeight}
        selectedColor={selectedColor}
        selectedBorderBottom={selectedBorderBottom}
        singleValueStyle={singleValueStyle}
        valueContainerStyle={valueContainerStyle}
        controlStyle={controlStyle}
        indicatorContainer={indicatorContainer}
        optionStyle={optionStyle}
        placeholderStyle={placeholderStyle}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
}
