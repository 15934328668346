import styled from '@emotion/styled';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Heading, Icon } from '../../common';
import Panel from '../../common/Panel';
import indicator from '../../images/timeIndicator.svg';
import { ResizableBox } from 'react-resizable';
import { Resizable } from 'react-resizable';
import timeBars from '../../images/timebars.svg';
import './videoTimeline.css';
import ResizeBox from '../../common/ResizeBox';
const moment = extendMoment(Moment);
const time = [
  '00:10:00',
  '00:10:30',
  '00:11:00',
  '00:11:30',
  '00:12:00',
  '00:12:30',
  '00:13:00',
  '00:13:30',
  '00:14:00',
  '00:14:30',
  '00:15:00',
  '00:15:30',
  '00:16:00',
  '00:16:30',
  '00:17:00'
];

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TimeBarContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Timeline = styled.div`
  display: flex;
  align-items: center;
  background: var(--darkPurple50);
  width: 100%;
  height: 50px;
  border-radius: 3px;
  position: relative;
`;
const Block = styled.div`
  border: 0;
  width: 100%;
  background: ${props => (props.active ? ' rgba(255, 255, 255, 0.25)' : 'transparent')};
  height: 50px;
`;
const BlockContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  border: 0;
  width: ${props => props.width};
  background: transparent;
  height: 50px;

  span {
    display: none;
  }
  &:hover {
    .block {
      background: rgba(255, 255, 255, 0.75);
    }
    .tooltipBlock {
      visibility: visible;
      background: #101010;
      border: 1px solid var(--white);
    }
  }

  .tooltipBlock {
    // visibility: hidden;

    background-color: var(--sapGreen);
    color: #fff;
    text-align: center;

    border-radius: 2px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    padding: 4px 4px 5px 5px;
    top: -30px;
    z-index: 1;
    p {
      color: #fff;
      font-weight: 500;
      font-size: 8px;
      padding: 0;
      margin: 0;
    }
  }

  &:focus {
    padding: 0 !important;
    .tooltipBlock {
      visibility: visible;
    }
    &:hover {
      .tooltipBlock {
        visibility: visible;
        background-color: var(--sapGreen);
        color: #fff;
        text-align: center;
        border: none;
      }
    }
    .block {
      border: 2px solid var(--sapGreen);
      box-sizing: border-box;
      border-radius: 2px;
      background: rgba(255, 255, 255, 0.75);
      z-index: 1000 !important;
    }

    span {
      padding: 0 !important;
      margin: 0 !important;
      display: block;
      background: var(--sapGreen);
      color: var(--white);
      padding: 5px 0 !important;
      font-size: 10px;
      position: absolute;
      z-index: 100 !important;
      text-align: center;
    }
    .left-arrow {
      z-index: 100 !important;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      top: 30%;
      left: -10px;

      padding-left: 1.5px !important;
      padding-right: 0 !important;
      // margin-right: -2px !important;
    }
    .right-arrow {
      z-index: 100 !important;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      top: 30%;
      right: -10px;
      // padding-right: 3x !important;
    }
  }
`;

const TimeIndicator = styled.div`
  width: 1px;
  height: 100%;
  background: var(--white);
  position: absolute;
  left: 0px;

  img {
    position: absolute;
    top: -6px;
    left: -4.5px;
  }
`;

const block = [
  {
    width: '5%',
    active: false
  },
  {
    width: '10%',
    active: true
  },
  {
    width: '2%',
    active: false
  },
  {
    width: '5%',
    active: true
  },
  {
    width: '5%',
    active: false
  },
  {
    width: '3%',
    active: true
  },
  {
    width: '15%',
    active: false
  },
  {
    width: '10%',
    active: true
  },
  {
    width: '20%',
    active: false
  },
  {
    width: '15%',
    active: true
  },
  {
    width: '10%',
    active: false
  }
];
const time_bars = [];
for (var i = 0; i < 50; i++) {
  time_bars.push(i);
}

const ClippedBoxContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 0;
  width: ${props => props.width};
  background: rgba(255, 255, 255, 0.25);

  height: 50px;
  &:hover {
    .block {
      background: rgba(255, 255, 255, 0.75);
    }
    .tooltipBlock {
      visibility: visible;
      background: #101010;
      border: 1px solid var(--white);
    }
  }
`;

const UnClippedBoxContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 0;
  width: ${props => props.width};
  background: trasparent;
  height: 50px;
  &:hover {
    .block {
      background: rgba(255, 255, 255, 0.75);
    }
    .tooltipBlock {
      visibility: visible;
      background-color: var(--sapGreen);
      color: #fff;
      text-align: center;
      border: none;
    }
  }
  .block {
    border: 0;
    width: 10px;
    background: rgba(255, 255, 255, 0.75);
    height: 50px;
    border: 2px solid var(--sapGreen);
    box-sizing: border-box;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1000 !important;
  }
  &.active {
    background: rgba(255, 255, 255, 0.25);

    .block {
      width: 100%;
    }

    height: 50px;
    &:hover {
      .block {
        background: rgba(255, 255, 255, 0.75);
      }
      .tooltipBlock {
        visibility: visible;
        background: #101010;
        border: 1px solid var(--white);
      }
    }
  }
  span {
    padding: 0 !important;
    margin: 0 !important;
    display: block;
    background: var(--sapGreen);
    color: var(--white);

    font-size: 10px;
    position: absolute;
    z-index: 100 !important;
    text-align: center;
    height: 20px;
    width: 10px;
    display: flex;
    align-items: center;
  }
  .left-arrow {
    z-index: 100 !important;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding-left: 2px !important;
    position: absolute;
    left: -11px;
    top: 30%;
  }
  .right-arrow {
    z-index: 100 !important;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    right: -11px;
    top: 30%;
  }
`;
const ClipContainer = styled.div`
  position: absolute;
  left: 5px;
  display: flex;
  align-items: center;
  border: 0;
  width: 10px;
  background: red;
  height: 50px;
`;
const TooltipBlock = styled.div`
  // visibility: hidden;

  background-color: var(--sapGreen);
  color: #fff;
  text-align: center;

  border-radius: 2px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  padding: 1px 4px;
  top: -20px;
  z-index: 1;
  max-width: 60px;

  p {
    color: #fff;
    font-weight: 500;
    font-size: 8px;
    padding: 0;
    margin: 0;
    min-width: 50px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 5px;
    width: 2px;
    top: 100%;
    background: var(--sapGreen);
    left: 4px;
  }
`;
const ClippedBox = ({ width }) => {
  return (
    <ClippedBoxContainer width={width}>
      {/* <TooltipBlock className="tooltipBlock ">
				<p className="tooltiptextblock">tooltip text</p>
			</TooltipBlock> */}
    </ClippedBoxContainer>
  );
};

const Clip = () => {
  return <ClipContainer></ClipContainer>;
};
const UnClippedBox = ({
  className,
  width,
  startTime,
  endTime,
  singlePointDifferenceInTermsOfCoordinates,
  setStartClipTime,
  setEndClipTime,
  isCreateClip,
  playerRef,
  setPlaying,
  unclippedIndex,
  selectedTimelineKey,
  setSelectedTimelineKey,
  startTimeOfClip,
 
  setStartTimeOfClip,
  startTimeOfClipForNew,
  nextTileAvailable,
  prevTileAvailable,
  nextClipData,
  prevClipData,
  currentPlayedSecond,
  createClip,
  isActiveClip,
  setIsSaveBtnDisabled,
  setVideoTimelineRef,
  setVideoTimelineStartTime,
  setVideoTimelineEndTime,
  setVideoTimelinePrevClipData,
  setVideoTimelineNextClipData
}) => {
  const [addClip, setAddClip] = useState(false);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (startTimeOfClip == startTime) {
      var a = startTime.split(':');
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      playerRef.current.seekTo(seconds, 'seconds');
      setPlaying(true);
      setAddClip(true);
      setStartClipTime(startTime);
      // const startSecondsInMili = moment(startTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss']))
      // const endSecondsInMili = moment(endTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss']))
      setEndClipTime(endTime);
      setIsSaveBtnDisabled(false);
      /*if(startSecondsInMili + 10000 > endSecondsInMili) {
					setEndClipTime(endTime);
				} else {
					setEndClipTime(moment.utc(startSecondsInMili + 10000).format('HH:mm:ss'));
				}*/
      createClip(false);
      setSelectedTimelineKey(unclippedIndex);
    }
  }, [startTimeOfClip]);
  useEffect(() => {
    if (isCreateClip) {
      const startInSeconds =
        moment(startTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss'])) / 1000;
      const endInSeconds =
        moment(endTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss'])) / 1000;

      if (currentPlayedSecond >= startInSeconds && currentPlayedSecond <= endInSeconds) {
        if (isActiveClip) {
          createClip(false);
        } else {
          setStartClipTime(moment.utc(currentPlayedSecond * 1000).format('HH:mm:ss'));
          setSelectedTimelineKey(unclippedIndex);
          setStartTimeOfClip(moment.utc(startTime * 1000).format('HH:mm:ss'));
          // setStartClipTime(moment.utc(startInSeconds * 1000).format('HH:mm:ss'));
        }
      }
    }
  }, [isCreateClip]);

  useEffect(() => {}, [selectedTimelineKey]);

  const handleVideoTimelineRef = ref => {
    setVideoTimelineRef(ref);
  };

  const handleVideoTimelineStartTime = stime => {
    setVideoTimelineStartTime(stime);
  };

  const handleVideoTimelineEndTime = etime => {
    setVideoTimelineEndTime(etime);
  };

  const handleVideoTimelinePrevClipData = pdata => {
    setVideoTimelinePrevClipData(pdata);
  };

  const handleVideoTimelineNextClipData = ndata => {
    setVideoTimelineNextClipData(ndata);
  };

  return (
    <UnClippedBoxContainer className={className} width={width}>
      {((startTimeOfClip == startTime) ||
        (selectedTimelineKey == unclippedIndex && isCreateClip)) && (
        <div style={{ position: 'relative', width: '100%' }}>
          <ResizeBox
            onClick={() => setClicked(prev => !prev)}
            // onClick={handleResizeBoxClick}
            // panelRef={panelRef}
            className="block"
            startTime={startTime}
            endTime={endTime}
            setStartClipTime={setStartClipTime}
            setEndClipTime={setEndClipTime}
            prevTileAvailable={prevTileAvailable}
            nextTileAvailable={nextTileAvailable}
            nextClipData={nextClipData}
            prevClipData={prevClipData}
            isCreateClip={isCreateClip}
            currentPlayedSecond={currentPlayedSecond}
            singlePointDifferenceInTermsOfCoordinates={singlePointDifferenceInTermsOfCoordinates}
            passReference={handleVideoTimelineRef}
            passStartTime={handleVideoTimelineStartTime}
            passEndTime={handleVideoTimelineEndTime}
            passPrevClipData={handleVideoTimelinePrevClipData}
            passNextClipData={handleVideoTimelineNextClipData}
            LeftComponent={() => (
              <Icon style={{ fontSize: 10 }} className="left-arrow">
                arrow_back_ios
              </Icon>
            )}
            RightComponent={() => (
              <Icon style={{ fontSize: 10 }} className="right-arrow">
                arrow_forward_ios
              </Icon>
            )}
          >
            <TooltipBlock className="tooltipBlock ">
              <p className="tooltiptextblock">tooltip text</p>
            </TooltipBlock>
          </ResizeBox>
        </div>
      )}
    </UnClippedBoxContainer>
  );
};
export let globalStartTime;
export let globalEndTime;
function VideoTimeline(props) {
  const [blockList, setBlockList] = useState([...block]);
  const [timesArrayForTimeline, setTimesArrayForTimeline] = useState([]);
  const [timeBlockArray, setTimeBlockArray] = useState([]);
  const [durationBetweenTwoMarkerPoints, setDurationBetweenTwoMarkerPoints] = useState(0);
  const [betweenIndexOfStartAndEnd, setBetweenIndexOfStartAndEnd] = useState({});
  const [singlePointDifferenceInTermsOfCoordinates, setSinglePointDifferenceInTermsOfCoordinates] = useState('');
  const [guidesTimings, setGuidesTimings] = useState([]);
  const [selectedTimelineKey, setSelectedTimelineKey] = useState('');
  const [videoTimelineRef, setVideoTimelineRef] = useState();
  const [videoTimelineStartTime, setVideoTimelineStartTime] = useState();
  const [videoTimelineEndTime, setVideoTimelineEndTime] = useState();
  const [videoTimelinePrevClipData, setVideoTimelinePrevClipData] = useState();
  const [videoTimelineNextClipData, setVideoTimelineNextClipData] = useState();
  props.passReferenceToVideoEdit(videoTimelineRef);
  props.passStartTimeToVideoEdit(videoTimelineStartTime);
  props.passEndTimeToVideoEdit(videoTimelineEndTime);
  props.passSinglePointDifference(singlePointDifferenceInTermsOfCoordinates);
  props.passGuideTimings(guidesTimings);
  props.passPrevClipDatatoVideoEdit(videoTimelinePrevClipData);
  props.passNextClipDatatoVideoEdit(videoTimelineNextClipData);
  props.passTimeBlocksArray(timeBlockArray);

  const dispatch = useDispatch();
  const timelineMarkerRef = useRef();
  const deleteVideoclipStatus = useSelector(state => state.DeleteVideoClipReducer.deleteVideoclipStatus);

 
  useEffect(() => {
    if (props.playerRef.current?.getDuration()) {
      divideTimeinTenSpilts();
    }
  }, [props.playerRef.current?.getDuration(), props.captureGuideTimes]);
  useEffect(() => {}, [props.videoUrl]);
  useEffect(() => {
    if (props.selectedGuideNotes) {
      checkAndSetStartAndEndTime(
        moment(props.selectedGuideNotes.startTime.split(' ')[1], [moment.ISO_8601, 'HH:mm:ss']),
        moment(props.selectedGuideNotes.endTime.split(' ')[1], [moment.ISO_8601, 'HH:mm:ss'])
      );
    }
  }, [props.selectedGuideNotes]);
  useEffect(() => {
    if (timesArrayForTimeline.length > 0) {
      getXPositionofSelectedGuide();
    }
  }, [timesArrayForTimeline]);

  //   globalStartTime = props.selectedGuideNotes.startTime;
  //   globalEndTime = props.selectedGuideNotes.endTime;
  useEffect(() => {}, [props.startTimeOfClip]);

  useEffect(() => {
    getPositionsforAddedGuides();
  }, [singlePointDifferenceInTermsOfCoordinates, guidesTimings]);
  useEffect(() => {
    if (singlePointDifferenceInTermsOfCoordinates) {
      timelineMarkerRef.current.style.left =
        `${props.currentPlayedSecond * singlePointDifferenceInTermsOfCoordinates - 1}px`;
    }
  }, [props.currentPlayedSecond]);

  useEffect(() => {
    if (props.captureGuideTimes && props.captureGuideTimes.length > 0) {
      let guides = [];
      for (let i = 0; i < props.captureGuideTimes.length; i++) {
        if (props.captureGuideTimes[i].length > 0) {
          guides = [...guides, ...props.captureGuideTimes[i]];
        }
      }
      setGuidesTimings(guides);
    }
  }, [props.captureGuideTimes]);

  props.passTimelineRef(timelineMarkerRef);

  const divideTimeinTenSpilts = function() {
    let timesArrayHoldingSeconds = [];
    let singleSplit = props.playerRef.current?.getDuration() / 10;
    for (let i = 0; i <= 10; i++) {
      timesArrayHoldingSeconds.push(moment.utc(singleSplit * i * 1000).format('HH:mm:ss'));
    }
    setTimesArrayForTimeline(timesArrayHoldingSeconds);
    let differenceInMiliSeconds = moment(timesArrayHoldingSeconds[1], [moment.ISO_8601, 'HH:mm:ss']).diff(
      moment(timesArrayHoldingSeconds[0], [moment.ISO_8601, 'HH:mm:ss'])
    );
    setDurationBetweenTwoMarkerPoints(differenceInMiliSeconds / 1000);
  };

  const checkAndSetStartAndEndTime = function(startTime, endTime) {
    for (let i = 0; i < timesArrayForTimeline.length; i++) {
      if (
        moment(props.selectedGuideNotes?.startTime.split(' ')[1], [moment.ISO_8601, 'HH:mm:ss']).isBefore(
          moment(timesArrayForTimeline[i], [moment.ISO_8601, 'HH:mm:ss'])
        )
      ) {
        setBetweenIndexOfStartAndEnd({ startIndex: i - 1, endIndex: i });
        // getXPositionofSelectedGuide(i-1, i);
        // getPositionsforAddedGuides();
        break;
      }
    }
  };

  const getPositionsforAddedGuides = function() {
    let initialTime = '00:00:00';
    let timeBlocks = [];
    let totalWidthsCalculated = [];
    // let totalWidth = singlePointDifferenceInTermsOfCoordinates * durationBetweenTwoMarkerPoints * 10
    let totalWidth = document.getElementsByClassName('time-block-holder')[0].parentElement.offsetWidth;
    if (guidesTimings.length) {
      guidesTimings.sort(function(a, b) {
        let firstTime = moment(a.startTime.split(' ')[1], [moment.ISO_8601, 'HH:mm:ss']).diff(
          moment(initialTime, [moment.ISO_8601, 'HH:mm:ss'])
        );
        let secondTime = moment(b.startTime.split(' ')[1], [moment.ISO_8601, 'HH:mm:ss']).diff(
          moment(initialTime, [moment.ISO_8601, 'HH:mm:ss'])
        );
        return firstTime - secondTime;
      });
    }
    for (let i = 0; i < guidesTimings.length; i++) {
      if (guidesTimings[i].startTime.split(' ')[1] != initialTime) {
        let difference = moment(guidesTimings[i].startTime.split(' ')[1], [moment.ISO_8601, 'HH:mm:ss']).diff(
          moment(initialTime, [moment.ISO_8601, 'HH:mm:ss'])
        );
        timeBlocks.push({
          width:
            ((((difference / 1000) * singlePointDifferenceInTermsOfCoordinates) / totalWidth) * 100).toString() + '%',
          active: false,
          startTime: initialTime,
          endTime: guidesTimings[i].startTime.split(' ')[1]
        });
        totalWidthsCalculated.push({
          width: (((difference / 1000) * singlePointDifferenceInTermsOfCoordinates) / totalWidth) * 100
        });
      }

      let difference = moment(guidesTimings[i].endTime.split(' ')[1], [moment.ISO_8601, 'HH:mm:ss']).diff(
        moment(guidesTimings[i].startTime.split(' ')[1], [moment.ISO_8601, 'HH:mm:ss'])
      );
      timeBlocks.push({
        width:
          ((((difference / 1000) * singlePointDifferenceInTermsOfCoordinates) / totalWidth) * 100).toString() + '%',
        active: true,
        startTime: guidesTimings[i].startTime.split(' ')[1],
        endTime: guidesTimings[i].endTime.split(' ')[1]
      });
      totalWidthsCalculated.push({
        width: (((difference / 1000) * singlePointDifferenceInTermsOfCoordinates) / totalWidth) * 100
      });
      initialTime = guidesTimings[i].endTime.split(' ')[1];
    }
    let total = totalWidthsCalculated.reduce(function(acc, obj) {
      return acc + obj.width;
    }, 0);
    if (total < 100) {
      timeBlocks.push({
        width: (100 - total).toString() + '%',
        active: false,
        startTime: initialTime,
        endTime: moment.utc(props.playerRef.current?.getDuration() * 1000).format('HH:mm:ss')
      });
    }
    setTimeBlockArray(timeBlocks);
  };

  const getXPositionofSelectedGuide = function(startIndex, endIndex) {
    let totalWidth = document.getElementsByClassName('time-block-holder')[0].parentElement.offsetWidth;
    setSinglePointDifferenceInTermsOfCoordinates(totalWidth / props.playerRef.current?.getDuration());
  };
  return (
    <div className="pt-2 time-block-holder" style={{ position: 'relative' }}>
      <TimeContainer>
        {timesArrayForTimeline.map((item, index) => (
          <Heading className="time" type="3" index={index} fontSize="8px" fontWeight="500" color="var(--middleGrey)">
            {item}
          </Heading>
        ))}
      </TimeContainer>
      <TimeBarContainer className="d-flex ">
        {time_bars.map((item, index) => (
          <img key={index} src={timeBars} style={{ paddingRight: '2px' }} />
        ))}
      </TimeBarContainer>

      <Timeline>
        <TimeIndicator ref={timelineMarkerRef}>
          <img src={indicator} />
        </TimeIndicator>
        {timeBlockArray.map((item, index) => {
          return item.active ? (
            // <ClippedBox width={item.width}></ClippedBox>
            <UnClippedBox
              className={'active clip-no' + index}
              width={item.width}
              startTime={item.startTime}
              endTime={item.endTime}
              singlePointDifferenceInTermsOfCoordinates={singlePointDifferenceInTermsOfCoordinates}
              setStartClipTime={props.setStartClipTime}
              isCreateClip={props.isCreateClip}
              createClip={props.createClip}
              playerRef={props.playerRef}
              setPlaying={props.setPlaying}
              setEndClipTime={props.setEndClipTime}
              unclippedIndex={index}
              selectedTimelineKey={selectedTimelineKey}
              startTimeOfClip={props.startTimeOfClip}
              isActiveClip={true}
              currentPlayedSecond={props.currentPlayedSecond}
              setIsSaveBtnDisabled={props.setIsSaveBtnDisabled}
              setSelectedTimelineKey={setSelectedTimelineKey}
              nextTileAvailable={timeBlockArray[index + 1]?.active ? false : true}
              nextClipData={timeBlockArray[index + 1]?.startTime ? timeBlockArray[index + 1] : ''}
              prevClipData={timeBlockArray[index - 1]?.startTime ? timeBlockArray[index - 1] : ''}
              prevTileAvailable={timeBlockArray[index - 1]?.active ? false : true}
              setVideoTimelineRef={setVideoTimelineRef}
              setVideoTimelineStartTime={setVideoTimelineStartTime}
              setVideoTimelineEndTime={setVideoTimelineEndTime}
              setVideoTimelinePrevClipData={setVideoTimelinePrevClipData}
              setVideoTimelineNextClipData={setVideoTimelineNextClipData}
            ></UnClippedBox>
          ) : (
            <UnClippedBox
              key={index}
              className={'clip-no' + index}
              width={item.width}
              startTime={item.startTime}
              endTime={item.endTime}
              singlePointDifferenceInTermsOfCoordinates={singlePointDifferenceInTermsOfCoordinates}
              setStartClipTime={props.setStartClipTime}
              isCreateClip={props.isCreateClip}
              createClip={props.createClip}
              playerRef={props.playerRef}
              setPlaying={props.setPlaying}
              setEndClipTime={props.setEndClipTime}
              unclippedIndex={index}
              selectedTimelineKey={selectedTimelineKey}
              startTimeOfClip={props.startTimeOfClip}
            
              currentPlayedSecond={props.currentPlayedSecond}
              isActiveClip={false}
              setIsSaveBtnDisabled={props.setIsSaveBtnDisabled}
              setStartTimeOfClip={props.setStartTimeOfClip}
              setSelectedTimelineKey={setSelectedTimelineKey}
              setVideoTimelineRef={setVideoTimelineRef}
              setVideoTimelineStartTime={setVideoTimelineStartTime}
              setVideoTimelineEndTime={setVideoTimelineEndTime}
              setVideoTimelinePrevClipData={setVideoTimelinePrevClipData}
              setVideoTimelineNextClipData={setVideoTimelineNextClipData}
            ></UnClippedBox>
          );
        })}
      </Timeline>
    </div>
  );
}

export default VideoTimeline;
{
  /* <BlockContainer
						key={index}
						onClick={() => {}}
						width={item.width}
						active={item.active}
					>
						<div className='tooltipBlock '>
							<p className='tooltiptextblock'>
								tooltip text
							</p>
						</div>

						<ResizeBox
							LeftComponent={() => (
								<Icon
									style={{ fontSize: 10 }}
									className='left-arrow'
								>
									arrow_back_ios
								</Icon>
							)}
							RightComponent={() => (
								<Icon
									style={{ fontSize: 10 }}
									className='right-arrow'
								>
									arrow_forward_ios
								</Icon>
							)}
						>
							<Block
								className='block'
								onClick={() => {}}
								width={item.width}
								active={item.active}
							/>
						</ResizeBox>
					</BlockContainer> */
}
