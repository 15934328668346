import React, { useEffect, useState } from 'react';
import ProjectWrapper from './ProjectWrapper';
import { Heading, HelperText, Card, Text, ListMenu, SingleValue, Button, Icon, Avatar, Divider } from '../../common';
import { Dropdown, InputText, Switch } from '../../components/form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import videocamImg from '../../images/video-on.svg';
import videocamoffImg from '../../images/video-off.svg';
import micImg from '../../images/mic-on.svg';
import micoffImg from '../../images/mic-off.svg';
import VideoCam from '../../images/videoCam.png';
import Audio from '../../images/audio.svg';
import ShareScreen from '../../images/share_screen.png';
import Group from '../../images/Group.png';
import More from '../../images/more.png';
import FullScreen from '../../images/full_screen.png';
import CallEnd from '../../images/call_end.png';
import switchCam from '../../images/switchCam.svg';
import MinimizeScreen from '../../images/minimize.svg';
import stopScreenShare from '../../images/stopScreenShare.svg';
import Index from '../Project/meeting/index';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_FIELD_DATA } from '../../constants/field/GetFieldOverviewConstants';
import DownIndicator from '../../common/DownIndicator';
//import { REQUEST_STARTINTERVIEW } from '../../constants/field/StartInterviewConstant';
import translationIcon from '../../assets/images/translation_join_call.svg';
import DropdownIcon from '../../components/form/DropdownIcon';
import GenerateSumaryModal from '../../common/GenerateSummaryModal';
import SummaryGeneratedModal from '../../common/SummaryGeneratedModal';
import CreateClipTooltip from '../../common/CreateClipTooltip';
import '../../common/ReactTooltip.css';
import { SEND_PARTICIPANTS_LIST_ONEND } from '../../constants/projects/ProjectConstants';
import CreateClipTooltipBox from '../../common/CreateClipTooltipBox';
import mixPanel from '../../utils/helpers/mixPanel';

let GlobalStart;

let InterviewOn = null;
let GlobalChannelName = null;
const setInterviewOn = value => {
  InterviewOn = value;
};
const setGlobalChannelName = value => {
  GlobalChannelName = value;
};
export { InterviewOn, setInterviewOn, GlobalChannelName, setGlobalChannelName };

const ButtonBase = styled(Button)`
  width: 124px;
  height: 30px;
  padding: 0;
`;
const CustomCard = styled.div`
  z-index: 10;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);

  margin-left: -20px;
`;
const ButtonCustom = styled(Button)`
  width: 150px;
  height: 30px;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  .material-icons {
    color: var(--sapGreen);
  }
  :hover {
    .material-icons {
      color: var(--white);
    }
  }
`;

const ButtonCustom1 = styled(Button)`
  color: var(--sapGreen);
  .material-icons {
    color: var(--sapGreen);
  }
  :hover {
    .material-icons {
      color: var(--white);
    }
  }
  :focus {
    background: transparent;
    color: var(--sapGreen);
    outline: none;
    box-shadow: none;
    :hover {
      background: var(--sapGreen);
      color: var(--white);
    }
  }
  :disabled,
  [disabled] {
    background: transparent;
    border-color: var(--middleGrey);
    color: var(--middleGrey);
    .material-icons {
      color: var(--middleGrey);
    }
    :hover {
      box-shadow: none;
      background: #c2c2c2;
      color: #ffffff;
      cursor: not-allowed;
      .material-icons {
        color: var(--white);
      }
    }
  }
`;

const CustomSwitch = styled(Switch)`
  .inner {
    background: ${props => props.activeColor};
  }

  input {
    display: none;

    :checked ~ .outer .inner {
      background: var(--white);
    }
    :checked ~ .outer {
      background: ${props => props.activeColor};
    }
  }
`;

const CardContain = styled(Card)`
  border-radius: 5px;
  background: transparent;
`;

const VideoContainer = styled.div`
  .video {
    position: absolute;
    top: 540px;
    // left: 2rem;
    height: 39.33px;

    .iconContainer {
      width: 32px;
      margin: 0 4px;

      text-align: center;
    }

    .active {
      padding: 3px 0px;
      border-radius: 3px;
      background: var(--greyBlue30);
    }
    .video-rec {
      background: #526666;
      width: 92.37px;
      border-radius: 5px 0px 0px 5px;

      .video-rec-on {
        width: 8.23px;
        height: 8.23px;
        border-radius: 4px;
        background: #d70101;
      }
    }
    .video-handles {
      width: 300px;
      background: var(--deepBlue);
      opacity: 0.95;
    }
    .vido-call-end {
      background: #93293a 95%;
      width: 46.64px;
      border-radius: 0px 5px 5px 0px;
    }
  }
`;

const DownIndicatorCustom = ({ ...props }) => {
  return <DownIndicator color="var(--sapGreen)" iconStyle={{ fontSize: 16 }} {...props} />;
};

export default function VideoCall({
  callOn,
  setCallOn,
  unansweredCount,
  respondantInfo,
  setStep,
  id,
  setRespondantActive,
  cleanup,
  getAudioVideoState,
  setShowPrecallTest
}) {
  const [projectTitle, setProjectTitle] = useState('My Movie Binge');
  const navigate = useNavigate();
  const [videocam, setVideocam] = useState(false);
  const [mic, setMic] = useState(false);
  const loggedUser = useSelector(state => state.LoginReducer);
  const dispatch = useDispatch();
  const [sourceLanguage, setSourceLanguage] = useState('');
  const location = useLocation();
  const [generated, setGenerated] = useState(false);
  const captureguideData = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
  const userId = useSelector(state => state.LoginReducer.userId);
  mixPanel.init();
  const startInterViewData = useSelector(state => state.StartInterviewReducer);

  let uid;
  if (respondantInfo?.isInterviewStart == 0) {
    uid = Math.floor(100000 + Math.random() * 900000);
  } else if (respondantInfo?.isRead == 1) {
    uid = '1500';
  } else {
    uid = '240000';
  }

  const sendToMixPanel = (eventName, errCode, reason) => {
    const currentTimestamp = new Date().getTime();
    const responseObj = {
      statement: {
        timeStamp: currentTimestamp,
        Platform: 'web',
        role: 'researcher',
        channelId: respondantInfo?.agoraChannelName,
        name: loggedUser.userName,
        event: eventName,
        userId: loggedUser.userId,
        // RTMUserId: this.USER_ID,
        errorCode: errCode,
        reson: reason
      }
    };

    mixPanel.track(responseObj.statement.event, { ...responseObj.statement, memberId: uid });
  };

  useEffect(() => {
    if (startInterViewData?.data) {
      sendToMixPanel(('Start', startInterViewData?.data?.code, startInterViewData?.data?.message));
    }
  }, [startInterViewData]);

  // list of spoken languages
  const languages = [
    { label: 'Select Spoken language', value: 'NA' },
    {
      label: sourceLanguage?.value == 'en-IN' ? 'English ' + '                           ✔' : 'English',
      value: 'en-IN'
    },
    {
      label: sourceLanguage?.value == 'hi-IN' ? 'Hindi/हिंदी' + '                        ✔' : 'Hindi/हिंदी',
      value: 'hi-IN'
    },
    // {label: 'Telugu/తెలుగు', value: 'te-IN'},
    { label: sourceLanguage?.value == 'ta-IN' ? 'Tamil/தமிழ்' + '                  ✔' : 'Tamil/தமிழ்', value: 'ta-IN' },
    // {label: 'Marathi/मराठी', value: 'mr-IN'},
    // {label: 'Malayalam/മലയാളം', value: 'ml-IN'},
    { label: sourceLanguage?.value == 'kn-IN' ? 'Kannada/ಕನ್ನಡ' + '            ✔' : 'Kannada/ಕನ್ನಡ', value: 'kn-IN' }
    // {label: 'Gujarati/ગુજરાતી', value: 'gu-IN'},
  ];

  useEffect(() => {
    getAudioVideoState({
      mic,
      cam: videocam
    });
  }, [mic, videocam]);

  useEffect(() => {
    getAudioVideoState({
      mic,
      cam: videocam
    });
  }, [mic, videocam]);

  useEffect(() => {}, []);

  setGlobalChannelName(respondantInfo?.agoraChannelName);
  useEffect(() => {
    const performanceNavigation = performance.getEntriesByType('navigation')[0];
    if (performanceNavigation?.type === 'reload') {
      const payload = {
        channelName: GlobalChannelName,
        user_ID: userId
      };

      dispatch({ type: SEND_PARTICIPANTS_LIST_ONEND, payload });
    }
  }, [dispatch, GlobalChannelName, userId]);

  const handleCall = () => {
    cleanup();
    setShowPrecallTest(false);
    const data = {
      channelName: respondantInfo?.agoraChannelName,
      user_ID: loggedUser.userId,
      researcherName: loggedUser.userName
    };


    dispatch({ type: 'SEND_PARTICIPANTS_LIST_ONSTART', payload: data });
    setCallOn(1);
    setInterviewOn(1);
    // setRespondantActive(true)
    var today = new Date(),
      time =
        today.getFullYear().toString() +
        '-' +
        (today.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        today
          .getDate()
          .toString()
          .padStart(2, '0') +
        ' ' +
        today
          .getHours()
          .toString()
          .padStart(2, '0') +
        ':' +
        today
          .getMinutes()
          .toString()
          .padStart(2, '0') +
        ':' +
        today
          .getSeconds()
          .toString()
          .padStart(2, '0');
    localStorage.setItem('videostarttime', time);

    const data2 = {
      user_ID: loggedUser.userId,
      startTime: time,
      videoTask_ID: respondantInfo?.videoTask_ID,
      projectRespondent_ID: respondantInfo?.projectRespondent_ID,
      projectRepondentVideo_ID: captureguideData?.projectRepondentVideo_ID,
      newuid: uid,
      cname: respondantInfo?.agoraChannelName,
      //uid: String(uid), // can't use same UID as a user, this affects audio in recording. This UID is cloud recording  
      uid: '3613483512',
      clientRequest: {
        resourceExpiredHour: 24,
        scene: 0
      }
    };

    setTimeout(() => {
      if (respondantInfo?.isInterviewStart === 1) {
        dispatch({ type: 'REQUEST_STARTINTERVIEW', payload: data2 });
      }
    }, 5000);
    GlobalStart = respondantInfo?.isInterviewStart;
   
  };

  const loc = useLocation();
  // console.warn(loc.pathname);
  const urlSegments = loc.pathname.split('/');
  const lastPath = urlSegments[urlSegments.length - 1];


  const backtoField = () => {
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () => {
        dispatch({
          type: REQUEST_FIELD_DATA,
          payload: {
            user_ID: loggedUser.userId,
            project_ID: id
          }
        });
      },
      5000
    );
  };

  const handleSelectChange = e => {
    if (e.value != 'NA') {
      setSourceLanguage(e);
      localStorage.setItem('language', e.label);
      localStorage.setItem('languageValue', e.value);
    } else {
      setSourceLanguage('');
      return;
    }
  };

  // let timeout;
  // const [active, setActive] = useState(false);

  // const showTip = () => {
  // 	timeout = setTimeout(() => {
  // 		setActive(true);
  // 	}, 400);
  // };

  // const hideTip = () => {
  // 	clearInterval(timeout);
  // 	setActive(false);
  // };

  return (
    <div>
      <VideoContainer
        style={{
          background: 'var(--black)',
          height: 600,
          borderRadius: '5px'
        }}
        className="d-flex flex-column justify-content-center align-items-center mt-4"
      >
        {location?.state?.LastCall && location.state.LastCall === true ? (
          <GenerateSumaryModal setGenerated={setGenerated} />
        ) : (
          ''
        )}
        {generated === true ? <SummaryGeneratedModal setStep={setStep} setGenerated={setGenerated} id={id} /> : ''}
        {/* <SummaryGeneratedModal setStep={setStep} setGenerated={setGenerated} id={id}/> */}

        {callOn == 1 ? (
          <Index setCallOn={setCallOn} setRespondantActive={setRespondantActive} respondantInfo={respondantInfo} sourceLanguage={sourceLanguage} videocam={videocam} mic={mic} navigate = {navigate} project_ID= {id} unansweredCount = {unansweredCount}/>
          
        )
        : /* <VideoCallFunc
          // 	videocam={videocam}
          // 	setVideocam={setVideocam}
          // 	mic={mic}
          // 	setMic={setMic}
          // 	onCallEnd={setCallOn}
          /> */
        callOn == 2 ? (
          <CardContain className="mt-4">
            <div className="d-flex flex-column align-items-center">
              <Avatar src={respondantInfo?.profileImagePath} width="86px" height="86px" />
              <div className="d-flex align-item-center mt-3 ">
                {lastPath === 'pre-call-resp' ? (
                  <Link style={{ color: 'var(--white)' }} to={{ pathname: '/dashboard-respondent' }}>
                    <ButtonCustom
                      fontWeight="700"
                      buttonColor="var(--snapGreen)"
                      className="mt-3 mr-2"
                      variant="outlined"
                      onClick={backtoField}
                      // onClick={() => setCallOn(1)}
                    >
                      <Icon
                        style={{
                          fontSize: 18,
                          marginRight: '5px'
                        }}
                      >
                        arrow_back_ios
                      </Icon>{' '}
                      Back to Dashboard
                    </ButtonCustom>
                  </Link>
                ) : (
                  <>
                    <Link style={{ color: 'var(--white)' }} to={{ pathname: '/project/' + id + '/field' }}>
                      <ButtonCustom
                        fontWeight="700"
                        buttonColor="var(--snapGreen)"
                        className="mt-3 mr-2"
                        variant="outlined"
                        onClick={backtoField}
                        // onClick={() => setCallOn(1)}
                      >
                        <Icon
                          style={{
                            fontSize: 18,
                            marginRight: '5px'
                          }}
                        >
                          arrow_back_ios
                        </Icon>{' '}
                        Back to Field
                      </ButtonCustom>
                    </Link>
                    <ButtonBase
                      fontWeight="700"
                      buttonColor="var(--snapGreen)"
                      className="mt-3 ml-2"
                      onClick={() => {
                        setStep(3);
                      }}
                    >
                      Edit Video
                    </ButtonBase>
                  </>
                )}
              </div>
            </div>
          </CardContain>
        ) : (
          <CardContain className="mt-4">
            <div className="d-flex flex-column align-items-center">
              <Avatar src={respondantInfo?.profileImagePath} width="86px" height="86px" />

              <div className="mt-3 d-flex align-items-center justify-content-around w-100">
                <div className="w-25 d-flex justify-content-between align-items-center ">
                  <div className="d-flex">
                    {videocam ? (
                      <img
                        src={videocamImg}
                        alt=""
                        width="23.5px"
                        className="img-fluid "
                        style={{ position: 'relative', top: '2px' }}
                      />
                    ) : (
                      <img src={videocamoffImg} alt="" width="22.5px" className="img-fluid " />
                    )}
                    <CustomSwitch
                      inactiveColor="var(--snapGreen)"
                      activeColor="var(--snapGreen)"
                      className="mt-1 ml-1"
                      checked={videocam}
                      onChange={() => setVideocam(!videocam)}
                    />
                  </div>
                  <Divider
                    className="mr-5"
                    background="var(--greyBlue)"
                    margin="0"
                    orientation="vertical"
                    height="30px"
                    width="2px"
                  />
                </div>
                <div className="w-25 d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <Button buttonColor="var(--sapGreen)" simple className="p-0">
                      {mic ? (
                        <img src={micImg} alt="" width="15px" className="mb-auto img-fluid " />
                      ) : (
                        <img src={micoffImg} alt="" width="16px" className="mb-auto img-fluid" />
                      )}
                    </Button>
                    <CustomSwitch
                      inactiveColor="var(--snapGreen)"
                      activeColor="var(--snapGreen)"
                      className="mt-1 ml-1"
                      checked={mic}
                      onChange={() => setMic(!mic)}
                    />
                  </div>
                  <Divider
                    className="mr-5"
                    background="var(--greyBlue)"
                    margin="0"
                    orientation="vertical"
                    height="30px"
                    width="2px"
                  />
                </div>
                {/* <div className="w-25 d-flex justify-content-center align-items-center">
                  <Button simple className="p-0">
                    <Icon color="var(--snapGreen)">settings</Icon>
                  </Button>
                  <Button simple color="var(--snapGreen)" fontSize="12px" fontWeight="400" className="p-0 pl-1">
                    Settings
                  </Button>
                  <Divider className='ml-5' background="var(--greyBlue)" margin="0" orientation="vertical" height="30px" width="2px" />
                </div> */}
                <div className="w-25 d-flex justify-content-center align-items-center">
                  <DropdownIcon
                    cssClass="dropdownIcon-button p-1"
                    color="var(--snapGreen)"
                    iconSrc={translationIcon}
                    defaultState={true}
                    items={languages}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <div
              // onMouseOver={showTip}
              // onMouseLeave={hideTip}
              >
                <ButtonBase
                  disabled={sourceLanguage == ''}
                  buttonColor="var(--snapGreen)"
                  className="mt-3"
                  onClick={handleCall}
                >
                  Begin Interview
                </ButtonBase>
              </div>
            </div>
          </CardContain>
        )}
      </VideoContainer>
      {/* <div className="d-flex justify-content-begin mt-2 align-items-center">
        <ButtonCustom1
          fontWeight="500"
          fontSize="0.75rem"
          size="small"
          variant="outlined"
          buttonColor="var(--sapGreen)"
          // disabled={guideStep == 1 ? true : false}
          // onClick={() => setGuideStep(prev => prev - 1)}
        >
          <Icon style={{ fontSize: 10, position : 'relative', top : '2px' }}>mic</Icon>{" "}Microphone
        </ButtonCustom1>
        <ButtonCustom1
          className="ml-2"
          fontWeight="500"
          fontSize="0.75rem"
          size="small"
          buttonColor="var(--sapGreen)"
          variant="outlined"
          // disabled={guideStep == captureguideData?.captureGuide?.length ? true : false}
          // onClick={() => setGuideStep(prev => prev + 1)}
        >
          <Icon style={{ fontSize: 10, position : 'relative', top : '2px' }}>volume_up</Icon>{" "}Speaker
        </ButtonCustom1>
      </div> */}
    </div>
  );
}

export function VideoCallFunc({ videocam, setVideocam, mic, setMic, onCallEnd, setRespondantActive }) {
  const [screenShare, setScreenShare] = useState(true);
  const [group, setGroup] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <div className="d-flex video">
      <div className="d-flex video-rec align-items-center justify-content-center">
        <div className="video-rec-on" />
        <Text className="ml-1" fontSize="11px" type="3" color="var(--white)">
          Rec
        </Text>
        <Text className="ml-2" fontSize="11px" type="4" color="var(--white)">
          35:43
        </Text>
      </div>
      <div className="d-flex video-handles align-items-center justify-content-around">
        <div className="d-flex align-items-center  ">
          <div className="iconContainer" onClick={() => setVideocam(prev => !prev)}>
            {videocam ? <img width="16.54px" src={VideoCam} /> : <img width="16.54px" src={videocamoffImg} />}
          </div>
          <div className="iconContainer" onClick={() => setMic(prev => !prev)}>
            {mic ? <img src={Audio} /> : <img src={micoffImg} />}
          </div>
          <div className="iconContainer" onClick={() => setScreenShare(prev => !prev)}>
            {screenShare ? <img src={ShareScreen} /> : <img src={stopScreenShare} />}
          </div>
          <div className={`iconContainer ${group && 'active'}`} onClick={() => setGroup(prev => !prev)}>
            <img src={Group} />
          </div>
          <div className="iconContainer " onClick={() => setFullScreen(prev => !prev)}>
            {fullScreen ? <img src={FullScreen} /> : <img src={MinimizeScreen} />}
          </div>
          <div className="iconContainer">
            <img src={switchCam} />
          </div>

          <div className="iconContainer">
            <img src={More} />
          </div>
        </div>
      </div>
      <div className="d-flex vido-call-end align-items-center justify-content-center">
        <Button simple onClick={() => onCallEnd(2)}>
          <img src={CallEnd} />
        </Button>
      </div>
    </div>
  );
}
