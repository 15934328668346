import React, { useState } from 'react';
import VideoCall from '../../images/videocall.png';
import { DiscussionGuide, Notes, AddEditTags, CallCompleted } from '../../containers';
import { InputText } from '../../components/form';
import styled from '@emotion/styled';
import { VideoCallFunc } from '../../containers';
import {  useDispatch, useSelector } from 'react-redux';
import {useEffect } from 'react';
import Index from '../Project/meeting/index';
import { useNavigate } from 'react-router-dom';

const VideoCallContainer = styled.div`
	min-height: 396px;
	position: relative;
	background: var(--black);
	border-radius: 5px;
	opacity: 0.95;

	.video {
		position: absolute; 
		bottom: 6%;
		left: 3%;
		height: 39.33px;

		.video-rec {
			background: #526666;
			width: 92.37px;
			border-radius: 5px 0px 0px 5px;

			.video-rec-on {
				width: 8.23px;
				height: 8.23px;
				border-radius: 4px;
				background: #d70101;
			}
		}
		.video-handles {
			width: 300px;
			background: var(--deepBlue);
			opacity: 0.95;
		}
		.vido-call-end{
			background: #93293A 95%;
			width:46.64px;
			border-radius: 0px 5px 5px 0px;
		}
	}
`;

export default function VideoCallScreen() {
	const loggedUser = useSelector(state => state.LoginReducer);
	const project_ID = useSelector(state => state.ProjectReducer.project_ID);
	const dispatch = useDispatch(); 
	const [ callOn, setCallOn ] = useState(true);
	const navigate = useNavigate();
	
	useEffect(() => {
		dispatch({ type: 'REQUEST_GET_VIDEO_TASK' });
	},[]);

	// useEffect(() => {

	// 	const payload = {
    //         "user_ID" : "183",
    //         "emailID" : "hinaaa.inaamdar@e-stonetech.com",
    //         "interviewSchedule_ID":"10"
    //     }
	// 	dispatch({ type: 'COUNT_NO_OF_PARTICIPANTS', payload: payload });
	// });

	
	const handleChange = (e) => {
		if (e.key === 'Enter') {
			const data1 = {user_ID:loggedUser.userId, projectRepondentVideo_ID:"1", note:e.target.value}
			dispatch({ type: 'REQUEST_ADDVIDEONOTE', payload: data1 });
			e.target.value = "";
		}
				
	  };
	  const videoValue = useSelector(state => state.TaskReducer.videoTaskList);
	  
	return (
		<React.Fragment>
			<div className="row">
				<div className="col-md-8 p-0">
					{/* {callOn ? (
						<VideoCallContainer>
							<VideoCallFunc onCallEnd={setCallOn}/>
						</VideoCallContainer>
					) : (
						<CallCompleted />
					)} */}

					<Index navigate = {navigate}/>
					{console.log("video calling")}
					
					<AddEditTags />
				</div>
				<div className="col-md-4">
					<DiscussionGuide videoValue={videoValue}/>
					<div className="mt-4">
						<Notes className="mt-3 p-3" />
						<div className="mt-2">
							<InputText
								placeholder="Enter note"
								showCharacters={false}
								showIcon={true}
								iconName="add_circle_outline"
								iconStyle="p-1 icon-input-style cursor"
								color="var(--grey20)"
								placeholderSize="12px"
								fontWeight="500"
								fontSize="0.7rem"
								onKeyDown={(e) => handleChange(e)}
								id = "addvideonote"
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
