import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../common/Icon';
import { useEffect, useState } from 'react';

const StyledSideNav = styled.div`
	position: fixed;
	left: 0;
	top: 71px;
	background: var(--secondaryColor);
	height: 100%;
	width: 160px;
	padding-top: 1rem;
`;

const SideNavLink = styled(Link)`
	color: var(--white);
	font-weight: 800;
	width: 100%;
	padding: 1rem;
	display: block;
	display: flex;
	align-items: center;
	margin-top: 0.2rem;
	font-size: 0.75rem;
	border-left: 5px solid transparent;
	:hover,
	&.active {
		background: var(--white);
		color: var(--secondaryColor);
		text-decoration: none;
		border-left: 5px solid var(--snapGreen);
		span {
			color: var(--secondaryColor);
		}
	}

	span {
		display: inline-block;
		margin-right: 0.5rem;
		color: var(--white);
	}
`;

export default function SideNav_Respondent({ children }) {
    const location = useLocation();
    const [selected, setSelected] = useState('');

    useEffect(() => {
        const pathname = location.pathname;

        if (pathname.includes('project')) {
            setSelected('project');
        } else if (pathname.includes('empty-dashboard')) {
            setSelected('tasks');
        } else if (pathname.includes('calendar')) {
            setSelected('calendar');
        } else if (pathname.includes('notifications')) {
            setSelected('notifications');
        } else if (pathname.includes('data')) {
            setSelected('data');
        } else {
            setSelected('dashboard');
        }
    }, [location.pathname]);

	return (
		<StyledSideNav>
			<SideNavLink to='/dashboard-respondent' onClick={() => setSelected('dashboard')} className={`${selected === 'dashboard' ? 'active' : ''}`}>
				<Icon>grid_view</Icon> Dashboard
			</SideNavLink>
			{/* Add other links here as needed */}
		</StyledSideNav>
	);
}