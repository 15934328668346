import styled from '@emotion/styled';
import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Heading, Icon, Button, ActionIcon } from '../../common';
import SearchableDropdown from '../../components/form/SearchableDropdown';

const IconCustom = styled(Icon)`
  font-size: 0.75rem;
  // margin-top: 7px;
`;

const FormContainer = styled.div`
  display: flex;
  width: auto;
  padding: 0 8px;
  margin: 0;
  border: ${props => (props.color ? `1px solid ${props.color}` : `1px solid var(--greyBorder)`)};
  border-radius: 20px;
  .css-tlfecz-indicatorContainer {
    display: none;
  }
  input {
    font-size: 11px;
    font-weight: 500;
    color: ${props => (props.color ? `${props.color} ` : `var(--white)`)};

    color: red;
  }
  span {
    align-self: flex-end;
    margin-bottom: 0.5rem;
  }
`;

const customeStylesField = {
  container: (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius: 0
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    ...state.selectProps.valueContainerStyle
  }),

  control: (provided, state) => ({
    ...provided,
    outline: 0,
    borderRadius: 0,
    border: 0,
    background: 'transparent',
    // border: '1px solid var(--white)',
    // borderRadius: '20px',
    borderColor: state.isFocused ? 'var(--inputColor)' : 'var(--borderColor)',
    boxShadow: 'none',
    minHeight: '30px',
    '&:hover': {
      borderColor: state.isFocused ? 'var(--inputColor)' : 'var(--borderColor)'
    },
    ...state.selectProps.controlStyle
  }),

  menu: (provided, state) => ({
    ...provided,

    borderRadius: '5px',
    width: 'auto',
    padding: '0 10px',
    // position: 'absolute',
    background: '#8A8A8A',
    // width: '220px',
    zIndex: 5,
    boxShadow: 'none',
    textAlign: 'left'
  }),

  option: (provided, state) => ({
    fontSize: '0.75rem',
    fontWeight: '500',
    color: 'var(--white)',
    padding: '0px 3px',
    textAlign: 'left',
    cursor: 'pointer',

    '&:last-child': {
      borderBottom: 0
    }
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '1px',
    fontSize: '11px',
    fontWeight: '500',
    color: 'var(--white)',
    ...state.selectProps.inputStyle
  }),

  singleValue: (provided, state) => ({
    fontSize: state.selectProps.selectedFontSize ? state.selectProps.selectedFontSize : '11px',
    fontWeight: state.selectProps.selectedFontWeight ? state.selectProps.selectedFontWeight : '500',
    color: state.selectProps.selectedColor ? state.selectProps.selectedColor : 'var(--white)',

    ...state.selectProps.singleValueStyle
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),
  IndicatorsContainer: () => ({
    display: 'none'
  }),
  placeholder: () => ({
    fontSize: '0.75rem',
    color: 'var(--mediumGrey)'
  })

  // menu: (provided, state) => ({
  //   ...provided,
  //   width: state.selectProps.width,
  //   borderBottom: '1px dotted pink',
  //   color: state.selectProps.menuColor,
  //   padding: 20,
  // }),
};

function Input({
  submit,
  key,
  className,
  value,
  color={tagColor},
  singleValueStyle,
  filters,
  tagsList,
  tagDeleted,
  Options,
  setTagsList,
  clipData
}) {
  const formRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const loggedUser = useSelector(state => state.LoginReducer);
  const dispatch = useDispatch();
  const projectId = useSelector(state => state.ProjectReducer.project_ID);
 
  const [text, setText] = useState({ value: value.tag_ID, label: value.tagName });
  const [masterTagList, setMasterTagList] = useState([]);
  useEffect(() => {
    setText({ value: value.tag_ID, label: value.tagName });
  }, [tagsList]);

  const handleSubmit = e => {
    e.preventDefault();
    setEdit(true);
    setMenuIsOpen(false);
  };

  useEffect(() => {
    const formattedTags = Options.map(option => ({
      value: option.tag_ID,
      label: option.tag
    }));
    setMasterTagList(formattedTags);


    const handleOutsideForm = e => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        setEdit(false);
      }
    };
    document.addEventListener('click', handleOutsideForm);
    return () => {
      document.removeEventListener('click', handleOutsideForm);
    };
  }, []);

  const updateTag = (tag_id, newTagValue) => {

    const updatedTags = tagsList.map(tag => 
      tag.tag_ID === tag_id ? { ...tag, tagName: newTagValue } : tag
    );

    setTagsList(updatedTags);
  
    const addPayload = {
      user_ID: loggedUser.userId,
      projectTagName: newTagValue,
      project_ID: projectId,
      clip_ID: clipData?.Clip_ID,
      tag_ID: tag_id,
      questionType_ID: 10,
      mediaAnswerID :  null
    };
  
    dispatch({ type: 'REQUEST_ADDVIDEOTAG', payload: addPayload });
  };
  

  const handleDropdownChange = opt => {
    setEdit(true);
    const newTagValue = opt.label;
    console.log("opt",opt);
    updateTag(text.value, newTagValue);
  };

  const onClickInput = () => {
    setEdit(true);
  };

  const deleteTag = tag_id => {
    let payload = {
      user_ID: loggedUser.userId,
      tag_ID: tag_id
    };

    tagDeleted(tag_id);
    dispatch({ type: 'REQUEST_DELETEVIDEOTAG', payload: payload });

   
  };

  return (
    <div className="mt-1 mb-1 mr-1">
      <FormContainer ref={formRef} color={color} className={className} disable={!edit}>
        <form onSubmit={handleSubmit} onClick={onClickInput}>
          <SearchableDropdown 
            isSearchable
            singleValueStyle={singleValueStyle}
            valueContainerStyle={{
              padding: edit ? '0 10px 0 0px' : '0 0px 0 0px'
            }}
            controlStyle={{ minHeight: 'auto' }}
            inputStyle={{
              color: color
            }}
            selectedColor={color}
            defaultValue={text}
            Options={Options}
            masterTagList={masterTagList}
            setMasterTagList={setMasterTagList}
            onChange={handleDropdownChange}
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={setMenuIsOpen}
            crossOption={false}
            customStylesForDropdown={customeStylesField}
          />
        </form>

        <div style={{ height: '12px', paddingTop: '4px' }}>
          <IconCustom
            color={color}
            onClick={e => {
              deleteTag(text.value);
              e.target.blur();
            }}
            style={{ cursor: 'pointer' }}
             tabIndex="-1"
          >
            {/* {'close'} */}
            {!edit ? 'close' : 'subdirectory_arrow_left'}
          </IconCustom>
        </div>
      </FormContainer>
    </div>
  )

}


function EditingTagPreCall({
    tagColor,
    addTagButtonColor = 'var(--greyBorder)',
    AddTagButton,
    singleValueStyle,
		tagsList,
		setTagsList,
    filters,
		clipId,
		clipData,
    Options,
		projectId,
		selectedRespondantVideoId,
		props
}) {
   const [showResults, setShowResults] = useState(false);


   const handleAddtag = () => {
    const newTag = { tag_ID: Date.now(), tagName: '' }; // Temporary unique ID
    setTagsList(prev => [...prev, newTag]);
    setShowResults(true);
  };
  
 
  const removeTag = tag_id => {
    let index = tagsList.findIndex(tag => tag.tag_ID === tag_id);
    if (index !== -1) {
      let tempTagsList = [...tagsList];
      tempTagsList.splice(index, 1);
      setTagsList(tempTagsList);
      console.log('delete tag');
    } else {
      console.log('Tag Not Found');
    }
    // console.log('setShowResults', showResults);
  };


	return(
		<div>
			<div className="d-flex align-items-center flex-wrap">
        {tagsList?.map((item, index) => (
          <Input
            submit={text => console.log(text)}
            key={index}
            className=""
            value={item}
            color={tagColor}
            filters={filters}
            Options={Options}
            singleValueStyle={singleValueStyle}
            tagsList={tagsList}
            tagDeleted={removeTag}
            setTagsList={setTagsList}
            clipData={clipData}
          />
        ))}
        <div className="d-flex align-items-center" onClick={handleAddtag}>
          {!showResults ? (
            AddTagButton ? (
              <AddTagButton />
            ) : (
              <>
                <Icon style={{ fontSize: '16px' }} color={addTagButtonColor}>
                  add
                </Icon>{' '}
              </>
            )
          ) : (
            ''
          )}
        </div>
      </div>
		</div>
	)
}

export default EditingTagPreCall;