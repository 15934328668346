import React, { useState } from 'react';
import ProjectWrapper from './ProjectWrapper';
import { HashRouter as Router, Route, useNavigate } from 'react-router-dom'
import Index from './index'
import  {useEffect} from 'react';


import {
	Heading,
	HelperText,
	Card,
	Text,
	ListMenu,
	SingleValue,
	Button,
	Icon,
	Avatar,
	Divider
} from '../../common';
import { Dropdown, InputText, Switch } from '../../components/form';
import {Link, useLocation} from "react-router-dom";
import styled from '@emotion/styled';
import videocamImg from '../../images/videocam.svg';
import videocamoffImg from '../../images/videocamoff.svg';
import { useDispatch ,useSelector} from 'react-redux';

const ButtonBase = styled(Button)`
    width: 124px;
    height: 30px;
    padding:0;
`;

const CardContain = styled(Card)`
    width: 397px;
    height: 410px;
`;

export default function PreVideoCallScreen() {
	const navigate = useNavigate();
	const [projectTitle, setProjectTitle] = useState('My Movie Binge');
	const [videocam, setVideocam] = useState(false);
	const [mic, setMic] = useState(false);
    const dispatch = useDispatch();
	const userId = useSelector(state=>state.LoginReducer.userId);
	let location = useLocation();
	const project_ID = useSelector(state => state.ProjectReducer.project_ID);
	//const agorachannelname = location.state != undefined ? location.state.data : "";
	const agorachannelname = location.state != undefined ? 1806092021063926 : 1806092021063926;
	// console.log("this.props.location.state",this.props);
    
	const data = {user_ID : userId}
    useEffect(() => {

        dispatch({
            type: 'GET_RESOLUTION',
            payload:data
        }); 
	});

	return (
		<React.Fragment>
			<Heading type="2" fontWeight="400" className="d-flex align-items-center">
				<Link to="/">
					<Button simple className="pr-0 pl-2">
						<Icon color="var(--primaryColor)" style={{ fontSize: '3rem', lineHeight: '1' }}>
							keyboard_arrow_left
						</Icon>
					</Button>
				</Link>

				{projectTitle}
			</Heading>

			<section className="pre-video-call">
				<div className="d-flex flex-column justify-content-center align-items-center mt-4">
					<Text type="3" fontSize="14px">
						Choose your video & audio settings for
					</Text>
					<Text type="4" fontSize="22px" color="var(--seaBlue)">
						Task Analysis
					</Text>
					<CardContain className="mt-4" height="390px">
						<div className="d-flex flex-column align-items-center">
							<Avatar src="https://i.pravatar.cc/150?img=60" width="86px" height="86px" />
							<div className="mt-3">
								<Text type="1" fontSize="14px">
									Interview with R3 - Sahil R.
								</Text>
								<Text type="3" fontSize="12px" className="mt-1">
									G1 - Intending Buyer Affluent Class
								</Text>
							</div>
							<div className="mt-3 d-flex align-items-center justify-content-around w-100">
								<div className="w-25 d-flex justify-content-between align-items-center ">
									<div className="d-flex">
										{videocam ? (
											<img src={videocamImg} alt="" width="22.5px" className="mb-auto" />
										) : (
											<img src={videocamoffImg} alt="" width="22.5px" className="mb-auto" />
										)}
										<Switch
											className="mt-1"
											checked={videocam}
											onChange={() => setVideocam(!videocam)}
										/>
									</div>
									<Divider margin="0" orientation="vertical" height="30px" width="2px" />
								</div>
								<div className="w-25 d-flex justify-content-between align-items-center">
									<div className="d-flex">
										<Button simple className="p-0">
											<Icon>{mic ? 'mic' : 'mic_off'}</Icon>
										</Button>
										<Switch className="mt-1" checked={mic} onChange={() => setMic(!mic)} />
									</div>
									<Divider margin="0" orientation="vertical" height="30px" width="2px" />
								</div>
								<div className="d-flex justify-conent-center align-items-center">
									<Button simple className="p-0">
										<Icon>settings</Icon>
									</Button>
									<Button
										simple
										color="var(--greyBlue)"
										fontSize="12px"
										fontWeight="400"
										className="p-0 pl-1"
									>
										Settings
									</Button>
								</div>
							</div>

							{/* <div className="columns wrapper index">
								<div className="column">
									<div id="attendeeMode" className="control">
										<label className="radio">
											<input onChange={e => this.setState({ attendeeMode: e.target.value })}
												value="video" type="radio"
												name="attendee" defaultChecked />
											<span className="radio-btn">
											</span>
											<span className="radio-img video">
											</span>
											<span className="radio-msg">Video Call : join with video call</span>
										</label>
										<br />
										<label className="radio">
											<input onChange={e => this.setState({ attendeeMode: e.target.value })}
												value="audio-only" type="radio"
												name="attendee" />
											<span className="radio-btn">
											</span>
											<span className="radio-img audio">
											</span>
											<span className="radio-msg">Audio-only : join with audio call</span>
										</label>
										<br />
										<label className="radio">
											<input onChange={e => this.setState({ attendeeMode: e.target.value })}
												value="audience" type="radio"
												name="attendee" />
											<span className="radio-btn">
											</span>
											<span className="radio-img audience">
											</span>
											<span className="radio-msg">Audience : join as an audience</span>
										</label>
									</div>
								</div>
							</div>*/}
							{/* <div class="button">
								<Link to="/project/1/video-call">
									<ButtonBase className="mt-3">Begin Interview</ButtonBase>
								</Link>
							</div> */}
						</div> 

						<Index agorachannelname={agorachannelname} navigate = {navigate} project_ID= {project_ID}/>


					</CardContain>
				</div>
			</section>
		</React.Fragment>
	);
}
