import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { Button, Heading, Icon } from '.';
import recording from '../images/recording.svg';
import recordingActive from '../images/recording-active.svg';
import onRecording from '../images/onRecording.svg';

import note from '../images/note.svg';
import noteActive from '../images/note-active.svg';
import noteWhite from '../images/note-white.svg';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_CAPTURE_GUIDE } from '../constants/field/GetCaptureGuideConstants';
import { useParams } from 'react-router-dom';
import tag_grey from '../../src/assets/images/tag_grey.svg';
import EditingTag from '../../src/views/Project/EditingTagPreCall';

const CustomButton2 = styled(Button)`
  color: ${props => (props.disabled ? 'var(--lightGrey)' : props.buttonColor)};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '2px')};

  &:focus {
    background: transparent !important;
    &:hover {
      color: var(--white);
    }
  }
  &:hover {
    color: var(--quadColor);
    background: transparent !important;
    box-shadow: none;
  }
  &.time {
    background: #101010;
    border: 0.5px solid #8a8a8a;
  }

  :disabled,
  [disabled] {
    color: var(--mediumGrey);
    border: 1px solid var(--mediumGrey);
    background: transparent;
    :hover {
      box-shadow: none;
      background: transparent;
      color: var(--mediumGrey);
      cursor: not-allowed;
    }
  }
`;

const TagButton = styled(CustomButton2)`
  border: none;
  display: flex;
  align-items: center;
  color: #8a8a8a;
  padding: 4px 8px;

  span {
    font-size: 17px;
    color: #8a8a8a;
    padding-right: 3px;
  }
`;

const Container = styled.div`
  background: white;
  margin-left: 0;

  label {
    margin-bottom: 0 !important;
  }
  .img {
    position: relative;
  }
  .video_play {
    position: absolute;
    left: 40%;
    top: 38%;
  }
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .imgContainer {
      position: relative;
      background: var(--darkWhite);
      margin-left: 5px;
      padding: 10px;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .popup {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.18);
        border-radius: 5px;
        background: var(--white);
        font-size: 10px;
        font-weight: 500;
        color: var(--deepBlue);
        padding: 0.5rem;
        position: absolute;
        width: 180px;
        top: 40px;
        right: 0;
        z-index: 1080;
        display: block;
      }
    }
  }
`;

const QuickNoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3px;
`;

const RecordingContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--greyBlueLight);
  align-items: center;
  margin-right: 2px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
`;

const NoteContainer = styled.div`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 2px 7px;

  background: ${props => (props.active ? 'var(--sapGreen)' : 'var(--greyBlueLight)')};
  img {
    margin-bottom: 2px;
  }
`;
const IconCustom = styled(Icon)`
  font-size: 0.75rem;
  margin-top: 5px;
`;
const TextInput = styled.input`
  background: transparent;
  font-weight: 600;
  width: 80px;
  font-size: 0.75rem;
  color: var(--deepBlue);
  border: 0;

  &:focus-visible {
    border: 0;

    outline: none;
  }
`;

const QuickNoteInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3px;
  background: var(--greyBlueLight);
  border-radius: 20px;
  width: 150px;

  .button {
    cursor: pointer;
  }
`;

const Badge = styled.div`
  font-size: 8px;
  font-weight: 500;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  text-align: center;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--greyBlue);
  color: var(--white);

  position: absolute;
  top: -8px;
  right: 2px;
`;
export function QuickNoteInput({ title = 'New 1', active = false, submit }) {
  const [text, setText] = useState(title);

  const onChangeText = e => {
    setText(e.target.value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    submit(text);
    setText('');
  };

  return (
    <QuickNoteInputContainer>
      <img src={active ? recordingActive : recording} />

      <div style={{ minWidth: 70, paddingRight: 10 }}>
        <div
          className="d-flex "
          style={{
            width: '100px',
            marginBottom: '5px',
            marginLeft: '3px',
            borderBottom: '1px solid var(--sapGreen)'
          }}
        >
          <form onSubmit={handleSubmit}>
            <TextInput value={text} onChange={onChangeText} />
          </form>
          <div className="button" onClick={handleSubmit}>
            <IconCustom color="var(--sapGreen)">subdirectory_arrow_left</IconCustom>
          </div>
        </div>
      </div>
    </QuickNoteInputContainer>
  );
}

const AddNoteInputContainer = styled.div`
  background: white;
  border: 1px solid red;
  width: 200px;
  height: 100px;
  position: absolute;
  /*right: 60px;*/
  margin-top: 5px;

  padding-bottom: 1rem;
  margin-bottom: 20px;
  border: 1px solid var(--greyBlue30);
  box-sizing: border-box;
  box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 2;
`;

const NoteTextArea = styled.textarea`
  border: 0;
  resize: none;
  font-size: 10px;
  font-weight: 500;
  color: var(--middleGrey);
  width: 100%;

  padding: 0 0.5rem;

  &:focus-visible {
    border: 0;

    outline: none;
  }
`;
const IconContainer = styled.div`
  float: right;
  margin-right: 5px;

  cursor: pointer;
`;
const CustomButton = styled(Button)`
  float: right;
  margin-right: 5px;
  padding: 2px;
  cursor: pointer;
`;

const AddNoteInput = ({ onClose, onSaveNote }) => {
  const [text, setText] = useState('');
  const handleSave = () => {
    onSaveNote(text);
    setText('');
    onClose();
  };
  return (
    <AddNoteInputContainer>
      <IconContainer onClick={onClose}>
        <Icon color="var(--deepBlue)" style={{ fontSize: '12px' }}>
          close
        </Icon>
      </IconContainer>

      <NoteTextArea
        value={text}
        onChange={e => {
          setText(e.target.value);
        }}
        placeholder="Add a note new note"
      />

      <CustomButton
        onClick={handleSave}
        className="px-3 py-1 "
        fontWeight="500"
        fontSize="10px"
        size="small"
        variant="outlined"
        buttonColor="var(--sapGreen)"
      >
        Save
      </CustomButton>
    </AddNoteInputContainer>
  );
};

function QuickNote({
  title = 'Hits',
  active = false,
  badge,
  questionId,
  questionPaperSection_ID,
  projectRepondentVideo_ID,
  projectRespondent_ID,
  setSelectedOption,
  ClipsData,
  callOn
}) {
  const [startRecording, setStartRecording] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [titleClicked, setTitleClicked] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState('');
  const [array, setArray] = useState([]);
  const loggedUser = useSelector(state => state.LoginReducer);
  const projectId = useSelector(state => state.ProjectReducer.project_ID);
  const [newNote, setnewNote] = useState();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [isTagPopupVisible, setIsTagPopupVisible] = useState(false);
  const [tagList, setTagList] = useState();
  const MasterTags = useSelector(state => state.GetCaptureGuideReducer.captureguideData.MasterTags);

 
  useEffect(() => {
    if (ClipsData?.tags) {
      setTagList(ClipsData.tags);
    }
  }, [ClipsData]);

  //this.videostarttime = localStorage.getItem("videostarttime") == undefined ? "00:00:00" : localStorage.getItem("videostarttime");
  const handleTime = title => {
    // active && setStartRecording((prev) => !prev);
    // var today = new Date(),
    //   	time = today.getFullYear().toString() + "-" + (today.getMonth()+1).toString().padStart(2, "0") + "-" + today.getDate().toString().padStart(2, "0") + " " + today.getHours().toString().padStart(2, "0") + ':' + today.getMinutes().toString().padStart(2, "0") + ':' + today.getSeconds().toString().padStart(2, "0");
    // setArray(oldArray => [...oldArray,time] );
    console.log('array', array);
    if (array.length == 2) {
      const data2 = {
        user_ID: loggedUser.userId,
        question_ID: questionId,
        startTime: null,
        endTime: null,
        questionPaperSection_ID: questionPaperSection_ID,
        systemStartTime: array[0],
        systemEndTime: array[1],
        projectRepondentVideo_ID: projectRepondentVideo_ID,
        videoName: newNote != undefined ? newNote : ''
      };
      console.log(data2);
      dispatch({ type: 'REQUEST_ADDTIMEVIDEOQUESTION', payload: data2 });
      setnewNote('');
      setTimeout(() => {
        dispatch({
          type: REQUEST_CAPTURE_GUIDE,
          payload: {
            user_ID: loggedUser.userId,
            project_ID: id,
            projectRespondent_ID: projectRespondent_ID
          }
        });
      }, 2000);

      setArray([]);
      setStartRecording(false);
    } else {
      setStartRecording(true);
    }
    setSelectedOption('');
    if (!startRecording) {
      setSelectedOption(title);
    }
  };

  useEffect(() => {
    if (titleClicked && array.length > 0) {
      handleTime(title);
    }
  }, [array]);

  useEffect(() => {}, [newNote]);

  const updateTagList = (updatedTagList) => {
    const updatedClipData = { ...clipData, tags: updatedTagList };
    setClipData(updatedClipData);
    console.log('Updated clipData:', updatedClipData);
  }

  return (
    <QuickNoteContainer>
      <RecordingContainer>
        <div
          onClick={() => {
            if (!active) {
              return;
            }
            setTitleClicked(true);
            let today = new Date(),
              time =
                today.getFullYear().toString() +
                '-' +
                (today.getMonth() + 1).toString().padStart(2, '0') +
                '-' +
                today
                  .getDate()
                  .toString()
                  .padStart(2, '0') +
                ' ' +
                today
                  .getHours()
                  .toString()
                  .padStart(2, '0') +
                ':' +
                today
                  .getMinutes()
                  .toString()
                  .padStart(2, '0') +
                ':' +
                today
                  .getSeconds()
                  .toString()
                  .padStart(2, '0');
            setArray(oldArray => [...oldArray, time]);
            // handleTime(title)
          }}
        >
          {startRecording ? <img src={onRecording} /> : <img src={active ? recordingActive : recording} />}
        </div>

        <div style={{ minWidth: 90 }}>
          <Heading
            className="pb-0 mb-0 px-1"
            type={3}
            fontWeight="600"
            fontSize="0.75rem"
            color={active ? 'var(--snapGreen)' : 'var(--middleGrey)'}
          >
            {title}
          </Heading>

          {active && badge && <Badge class="badge">{badge}</Badge>}
        </div>
      </RecordingContainer>

      <NoteContainer active={addNote}>
        {addNote ? (
          <img
            src={noteWhite}
            onClick={() => {
              active && setAddNote(prev => !prev);
            }}
          />
        ) : (
          <img
            onClick={() => {
              active && setAddNote(prev => !prev);
            }}
            src={active ? noteActive : note}
          />
        )}
        {addNote && (
          <AddNoteInput
            onSaveNote={note => {
              console.log('note', note);
              setnewNote(note);
              const data1 = {
                user_ID: loggedUser.userId,
                question_ID: questionId,
                VideoNote: note,
                questionPaperSection_ID: questionPaperSection_ID,
                projectRepondentVideo_ID: projectRepondentVideo_ID
              };
              dispatch({ type: 'REQUEST_ADDNEWNOTE', payload: data1 });
            }}
            onClose={() => setAddNote(prev => !prev)}
          />
        )}
      </NoteContainer>
      <Container>
        <div className="iconContainer">
          <div className="imgContainer">
            <img
              src={tag_grey}
              onClick={() => {
                if (badge > 0) {
                  setIsTagPopupVisible(prev => !prev);
                }
              }}
              style={{
                cursor: callOn ? 'pointer' : 'not-allowed',
                opacity: callOn ? 1 : 0.5
              }}
              alt="Tag Icon"
            />
            {isTagPopupVisible && callOn && (
              <div className="popup">
                <EditingTag
                  singleValueStyle={{
                    fontSize: '10px',
                    fontWeight: '700',
                    color: 'var(--greyBlue)'
                  }}
                  tagColor="var(--greyBlue)"
                  addTagButtonColor="#8A8A8A"
                  tagsList={tagList}
                  setTagsList={setTagList}
                  //   filters={filters}
                  onUpdateTagList={updateTagList}
                  clipData={ClipsData}
                  Options={MasterTags}
                  AddTagButton={() => (
                    <TagButton
                      fontSize="12px"
                      fontWeight="300"
                      variant="outlined"
                      buttonColor="#8A8A8A"
                      color="#8A8A8A"
                      // className='ml-3'
                    >
                      <Icon>add</Icon>
                    </TagButton>
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </QuickNoteContainer>
  );
}

export default QuickNote;
