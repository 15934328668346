import actions from "../../actions";

//reducer initial state

const initialState = {
    editClientAdminData: null,
    editClientAdminDataRecived : null
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case actions.EditClientAdminAction.REQUEST_EDITCLIENTADMINDATA:
            return {
                ...state
            };
        case actions.EditClientAdminAction.EDITCLIENTADMINDATA_RECEIVED:
            return {
                ...state,
                editClientAdminData: action.data,
                editClientAdminDataRecived : action.data
            };

        case actions.EditClientAdminAction.CLEAR_EDITCLIENTADMINDATA:
                return {
                  ...state,
                  editClientAdminDataRecived : null
                };    

        default:
            return state;
    }
};

export default reducer;