import styled from '@emotion/styled';
import { Children } from 'preact/compat';
import React, { useEffect, useState } from 'react';
import barChart from '../../images/bar_chart_2.svg';
import table from '../../images/table.svg';
import BarChart from './charts/BarChart';
import AnalysisLikerTable from './AnalysisLikerTable';
import Scale from './charts/Scale';
import HorizontalBarChart from './charts/HorizontalBarChart';
import useWindowDimensions from '../../common/useWindowDimensions';
import Likert from './charts/dthree/likert';
import { Heading, Icon, Button } from '../../common';
import copy_active from '../../images/copy_active.svg';
import SummaryTextContainer from '../../common/SummaryTextContainer';
import loading from '../../assets/images/loading.svg';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useInView } from 'react-intersection-observer';
import { Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Item = styled.div`
  width: 25px;
  height: 25px;
  color: var(--seaBlue);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background: white;
  }
`;
const Filter = styled.div`
  display: flex;
  background: var(--darkWhite);
  border: 2px solid var(--darkWhite);
  border-radius: 3px;
  div:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  div:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
const chartTypeList = [barChart, table];
const valueTypeList = ['#', '%'];

const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--midWhite);
  color: var(--sapGreen);
  border-radius: 3px;
  padding: 0.4rem 0.9rem;
  img {
    padding-right: 5px;
  }
  :hover {
    background: var(--midWhite);
    color: ${props => props.buttonColor};
  }

  :disabled,
  [disabled] {
    background: var(--midWhite);
    border: none;
    color: var(--middleGrey);

    :hover {
      background: var(--midWhite);
      border: none;
      color: var(--middleGrey);
      cursor: not-allowed;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  display: block;
  border: none;
  background-color: transparent;

  .accordion-item {
    border: none;
    background-color: transparent;
  }

  .accordion-header {
    button {
    background-color: transparent;
      border: none;
    white-space: nowrap;
  }
`;

function AnalysisLikert({ questionObject, qid, question, information, handleCopyClick, dataset, isLoadingQuestion }) {
  const [chartType, setChartType] = useState(barChart);
  const [valueType, setValueType] = useState('#');
  const [header, setHeader] = useState([]);
  const [high, setHigh] = useState([]);
  const [low, setLow] = useState([]);
  const [neutral, setNeutral] = useState([]);
  const [highList, setHighList] = useState([]);
  const [lowList, setLowList] = useState([]);
  const [mean, setMean] = useState([]);
  const [sum, setSum] = useState([]);
  const { height, width } = useWindowDimensions();
  const barWidth = width > 1500 ? width / 1.3 : width / 1.4;
  const barHeight = height / 2.4;
  const [selectedGenerateSummary, setSelectedGenerateSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [forceRender, setForceRender] = useState(0);

  useEffect(() => {
    setChartType(barChart); 
    setValueType('#')
  }, []);
  

   const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1
    });
  
  const [addReport, setAddReport] = useState({
    chartType: barChart,
    valueType: '#',
    data: {
      table: {}
    }
  });




  useEffect(() => {
    if (dataset != undefined) {
      let s = dataset.reduce((f, d) => {
        f.push(d['choiceName']);
        return f;
      }, []);

      setHeader([...s]);
      let hl = [[7], [6], [5]];
      hl = dataset.reduce((f, d) => {
        f[0].push(d['Sevend_']);
        f[1].push(d['Six_']);
        f[2].push(d['Five_']);
        return f;
      }, hl);

      setHighList(hl);

      let ll = [[3], [2], [1]];
      ll = dataset.reduce((f, d) => {
        f[0].push(d['Third_']);
        f[1].push(d['Second_']);
        f[2].push(d['First_']);
        return f;
      }, ll);
      setLowList(ll);

      let nl = [];
      nl = dataset.reduce((f, d) => {
        f.push(d['Four_']);
        return f;
      }, nl);

      setNeutral(nl);

      let hsum = [];
      for (let i = 0; i < hl[0].length - 1; i++) {
        hsum.push(0);
      }
      for (let i = 0; i < 3; i++) {
        for (let j = 1; j < hl[0].length; j++) {
          hsum[j - 1] = parseInt(hsum[j - 1]) + parseInt(hl[i][j]);
        }
      }

      setHigh(hsum);

      let lsum = [];
      for (let i = 0; i < ll[0].length - 1; i++) {
        lsum.push(0);
      }
      for (let i = 0; i < 3; i++) {
        for (let j = 1; j < ll[0].length; j++) {
          lsum[j - 1] = parseInt(lsum[j - 1]) + parseInt(ll[i][j]);
        }
      }

      setLow(lsum);

      let meanTemp = [];

      for (let i = 0; i < dataset.length; i++) {
        meanTemp.push(dataset[i].mean);
      }
      setMean(meanTemp);

      let t = addReport;
      t.data = {
        chart: dataset,
        table: {
          header: header,
          highList: highList,
          lowList: lowList,
          neutral: neutral,
          low: low,
          mean: meanTemp,
          high: high,
          sum: sum
        }
      };
      setAddReport(t);
    }

    let tt = addReport;
    tt.chartType = chartType;
    tt.valueType = valueType;

    let sumTemp = [];
    dataset.forEach(ds => {
      let dk = Object.keys(ds);
      let data = [];

      for (let i = 0; i < dk.length; i++) {
        if (dk[i] != 'id' && dk[i] != 'choiceName' && dk[i] != 'mean') {
          data.push(parseInt(ds[dk[i]]));
        }
      }
      sumTemp.push(data.reduce((a, b) => a + b, 0));
    });
    if (valueType == '%') {
      setSum(sumTemp);
      tt.data.table = { ...tt.data.table, sum: sumTemp };
    } else {
      setSum([]);
    }

    setAddReport(tt);
  }, [dataset, chartType, valueType]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(prev => !prev);
    setTimeout(() => {
      setForceRender(prev => prev + 1);
    }, 100);
  };


 useEffect(() => {
    if (isAccordionOpen) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isAccordionOpen]);


  // useEffect(() => {

  // 	let tt = addReport
  // 	tt.chartType = chartType
  // 	tt.valueType = valueType

  // 	let sumTemp = [];
  // 	dataset.forEach(ds => {
  // 		let dk = Object.keys(ds)
  // 		let data = []

  // 		for (let i = 0; i < dk.length; i++) {
  // 			if (dk[i] != 'id' && dk[i] != 'choiceName' && dk[i] != 'mean') {
  // 				data.push(parseInt(ds[dk[i]]))
  // 			}
  // 		}
  // 		sumTemp.push(data.reduce((a, b) => a + b, 0));
  // 	});
  // 	if (valueType == '%') {
  // 		setSum(sumTemp)
  // 		tt.data.table = { ...tt.data.table, sum: sumTemp }
  // 	}
  // 	else {
  // 		setSum([])
  // 	}

  // 	setAddReport(tt)

  // }, [chartType, valueType])

  return (
    <div className="question " style={{ borderBottom: '1px solid var(--greyBlue30)' }} ref={ref}>
      {inView ? (
        <StyledAccordion>
          <Accordion style={{ display: 'block' }}>
            <Accordion.Item eventKey="0">
              <div className="d-flex justify-content-between my-2">
                <Accordion.Header>
                  <div style={{ width: '100%' }}>
                    <Heading
                      fontSize="14px"
                      fontWeight="700"
                      color="var(--deepBlue)"
                      className="mb-0 pb-0"
                      breakHeading={false}
                      onClick={() => {
                        setActiveKey(activeKey === '0' ? null : '0');
                        handleAccordionToggle();
                      }}
                      
                    >
                      {question}
                      {activeKey === '0' ? (
                        <FaChevronUp size={10} style={{ marginLeft: '8px' }} />
                      ) : (
                        <FaChevronDown size={10} style={{ marginLeft: '8px' }} />
                      )}
                      {information && (
                        <span>
                          <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                            {information}
                          </Heading>
                        </span>
                      )}
                    </Heading>
                  </div>
                </Accordion.Header>
                <div className="d-flex align-items-center">
                  <CustomButton
                    fontWeight="400"
                    fontSize="10px"
                    className="ml-2"
                    size="small"
                    variant="outlined"
                    buttonColor="var(--sapGreen)"
                    onClick={() => handleCopyClick(qid, addReport, question, questionObject)}
                  >
                    <img src={copy_active} /> Copy to Report
                  </CustomButton>
                </div>
              </div>
              <Accordion.Body>
                {isLoading === true ? (
                  <div className="pb-2">
                    <Heading fontSize="12px" fontWeight="500" color="var(--deepBlue)" className="mb-0 pb-0 mt-4">
                      Clips Loading...
                    </Heading>
                    <img src={loading} className="loading" alt="loading" />
                  </div>
                ) : (
                  ''
                )}

                <div className="d-flex">
                  <Filter className="mr-2">
                    {chartTypeList.map((item, index) => (
                      <Item
                        key={index}
                        className={`${chartType == item && 'active'}`}
                        onClick={() => {
                          setChartType(item);
                        }}
                      >
                        <img src={item} />
                      </Item>
                    ))}
                  </Filter>
                  <Filter>
                    {valueTypeList.map((item, index) => (
                      <Item
                        key={index}
                        className={`${valueType == item && 'active'}`}
                        onClick={() => setValueType(item)}
                      >
                        {item}
                      </Item>
                    ))}
                  </Filter>
                </div>
                {isLoadingQuestion !== false && chartType == barChart ? (
                  <div style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
                    <Skeleton count={2} height={30} width="100%" style={{ marginBottom: '0.5rem' }} />
                  </div>
                ) : (
                  chartType == barChart && (
                    <div className="py-3">
                      {dataset != undefined && (
                        <div className="row">
                          <div className="col-md-12 row" style={{ float: 'left', width: '200px' }}>
                            <div className="offset-md-9 col-md-3 col-sm-12 row" style={{ fontSize: '9px' }}>
                              <div className="col-4" style={{ textAlign: 'left', color: 'rgba(201, 4, 3, 1.0)' }}>
                                LOWEST
                              </div>
                              <div className="col-4" style={{ textAlign: 'center', color: 'rgba(166, 166, 166, 1.0)' }}>
                                NEUTRAL
                              </div>
                              <div className="col-4" style={{ textAlign: 'right', color: 'rgba(22, 62, 102, 1.0)' }}>
                                HIGHEST
                              </div>
                              <div className="col-12" style={{ display: 'flex', height: '5px' }}>
                                <div style={{ flex: 1, backgroundColor: 'rgba(201, 4, 3, 1.0)' }}></div>
                                <div style={{ flex: 1, backgroundColor: 'rgba(229,85,85,1.0)' }}></div>
                                <div style={{ flex: 1, backgroundColor: 'rgba(237, 125, 125, 1.0)' }}></div>
                                <div style={{ flex: 1, backgroundColor: 'rgba(166, 166, 166, 1.0)' }}></div>
                                <div style={{ flex: 1, backgroundColor: 'rgba(80, 131, 204, 1.0)' }}></div>
                                <div style={{ flex: 1, backgroundColor: 'rgba(35, 97, 161, 1.0)' }}></div>
                                <div style={{ flex: 1, backgroundColor: 'rgba(22, 62, 102, 1.0)' }}></div>
                              </div>
                              <div className="col-12" style={{ display: 'flex' }}>
                                <div style={{ flex: 1, color: 'rgba(201, 4, 3, 1.0)' }}>1</div>
                                <div style={{ flex: 1, color: 'rgba(229,85,85,1.0)' }}>2</div>
                                <div style={{ flex: 1, color: 'rgba(237, 125, 125, 1.0)' }}>3</div>
                                <div style={{ flex: 1, color: 'rgba(166, 166, 166, 1.0)' }}>4</div>
                                <div style={{ flex: 1, color: 'rgba(80, 131, 204, 1.0)' }}>5</div>
                                <div style={{ flex: 1, color: 'rgba(35, 97, 161, 1.0)' }}>6</div>
                                <div style={{ flex: 1, color: 'rgba(22, 62, 102, 1.0)' }}>7</div>
                              </div>
                            </div>
                          </div>
                          {dataset.map((d, i) => {
                            return (
                              <div className="col-md-12" key={`${i}-${forceRender}`}>
                                <div style={{ fontSize: '12px', marginBottom: '8px' }}>
                                  <strong>{d.choiceName}</strong>&nbsp;<i>&mu;{d.mean}</i>
                                </div>
                                <Likert datasets={d} valueType={valueType} />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )
                )}
                {chartType == table && (
                  <div className="mt-3">
                    <AnalysisLikerTable
                      High={high}
                      HighList={highList}
                      Neutral={neutral}
                      Low={low}
                      LowList={lowList}
                      MeanScore={mean}
                      Header={header}
                      Sum={sum}
                    />
                  </div>
                )}
                {/* <div className='py-3'>
				{chartType == chartTypeList[0] ? (
					<div>
						<div
							className='pb-4'
							style={{ position: 'relative' }}
						>
							<Scale />

							<HorizontalBarChart
								data={
									valueType === valueTypeList[0]
										? horizontalBarChart
										: horizontalPercentageBarChart
								}
							/>
						</div>
						<div
							className='mt-5'
							style={{ position: 'relative' }}
						>
							<Scale />

							<div className='py-2'>
								<BarChart
									addData={false}
									heightRatio={50}
									data={barChartData}
									width={barWidth}
									height={barHeight}
									top={20}
									bottom={30}
									left={30}
									right={0}
								/>
							</div>
						</div>
					</div>
				) : (
					<AnalysisLikerTable
						High={High}
						HighList={HighList}
						Neutral={Neutral}
						Low={Low}
						LowList={LowList}
						MeanScore={MeanScore}
					/>
				)}
			</div> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </StyledAccordion>
      ) : (
        <div>
          <div style={{ marginBottom: '1.5rem' }}>
            <Skeleton height={30} width="60%" style={{ marginBottom: '0.5rem' }} />
            <Skeleton count={3} height={20} width="100%" style={{ marginBottom: '0.5rem' }} />
          </div>
        </div>
        
      )}
    </div>
  );
}

export default AnalysisLikert;
