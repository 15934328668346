import styled from '@emotion/styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icon from './Icon';
import { useEffect, useState } from 'react';

const StyledSideNav = styled.div`
	position: fixed;
	left: 0;
	top: 71px;
	background: var(--secondaryColor);
	height: 100%;
	width: 160px;
	padding-top: 1rem;
`;

const SideNavLink = styled(Link)`
	color: var(--white);
	font-weight: 800;
	width: 100%;
	padding: 1rem;
	display: flex;
	align-items: center;
	margin-top: 0.2rem;
	font-size: 0.75rem;
	border-left: 5px solid transparent;
	:hover,
	&.active {
		background: var(--white);
		color: var(--secondaryColor);
		text-decoration: none;
		border-left: 5px solid var(--snapGreen);
		span {
			color: var(--secondaryColor);
		}
	}

	span {
		display: inline-block;
		margin-right: 0.5rem;
		color: var(--white);
	}
`;

export default function SideNav({ children }) {
    const location = useLocation();
    const [selected, setSelected] = useState('');

    useEffect(() => {
        const path = location.pathname;
        
        if (path.includes('project')) {
            setSelected('project');
        } else if (path.includes('empty-dashboard')) {
            setSelected('tasks');
        } else if (path.includes('notifications')) {
            setSelected('notifications');
        } else if (path.includes('data')) {
            setSelected('data');
        } else {
            setSelected('dashboard');
        }
    }, [location]);

    return (
        <StyledSideNav>
            <SideNavLink to='/dashboard' onClick={() => setSelected('dashboard')} className={`${selected === 'dashboard' ? 'active' : ''}`}>
                <Icon>grid_view</Icon> Dashboard
            </SideNavLink>
            <SideNavLink to='/project' onClick={() => setSelected('project')} className={`${selected === 'project' ? 'active' : ''}`}>
                <Icon>business_center</Icon> Projects
            </SideNavLink>
            <SideNavLink to='/notifications' onClick={() => setSelected('notifications')} className={`${selected === 'notifications' ? 'active' : ''}`}>
                <Icon>notifications</Icon> Notifications
            </SideNavLink>
        </StyledSideNav>
    );
}