export class maverickPortalconfig {
  static appName = 'Maverick Desktop Portal';
 
  static description = 'Configuration for API hostName';
 
  static version = '0.0.1';
 
  static private = true;
 
  static apiKey = '';
 
  static mock = true;
 
  static env = [
    {
      PROD: {
        hostname: '',
        version: 'v1'
      },
      ITG: {
        hostname: '',
        version: 'v1'
      },
      STG: {
        hostname: '',
        version: 'v1'
      },
      DEV: {
         hostname: 'https://api.app.humanifytech.com/api',
      //hostname: 'https://api.dev.humanifytechnologies.com/api',
        // hostname: 'https://api.demo.humanifytech.com/api',
        // hostname: 'http://localhost:8000/api',
        version: 'v1'
      },
      MOCK: {
        token: '',
        version: '',
        cache: true
      }
    }
  ];
}
