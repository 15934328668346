import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditTeamMethods from '../../utils/api/clientAdmin/EditTeamData';

function* EditTeamData(payload) {
  const promise = EditTeamMethods.EditTeamData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EditTeamdataAction.EDITTEAMDATA_RECEIVED, data: res });
}

export { EditTeamData };
