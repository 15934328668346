import React, { useEffect, Suspense, lazy, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Store, persistor } from './config/sagaConfig';
import './App.css';
import AppScreen from './AppScreen';
import AuthScreen from './AuthScreen';
import AuthWrapper from './AuthWrapper';
import { Nav } from './common';
import { LandingScreen } from './views/StaticScreen';

export default function App() {
    const { isloggedIn } = Store.getState().LoginReducer;
		// const [userToken, setUserToken] = useState(
		// 	JSON.parse(localStorage.getItem("token"))
		// );
	
		// useEffect(() => {
		// 	localStorage.setItem("token", JSON.stringify(userToken));
		// }, [userToken]);
    return (
        <Provider store={Store}>
            <PersistGate loading={null} persistor={persistor}>
							<Router>
									<Nav />
									<Routes>
										<Route element={<AuthWrapper />}>
											<Route path="*" element={<AppScreen />} />
										</Route>
										{/* <Route path="*" element={<AuthScreen />} /> */}
										{/* <Route path="/sign-in" element={<AuthScreen />} /> */}
									</Routes>
							</Router>
            </PersistGate>
        </Provider>
    );
}