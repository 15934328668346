import ProjectWrapper from './ProjectWrapper';
import styled from '@emotion/styled';
import { Button, Card, Heading } from '../../common';
import { Link, useParams } from 'react-router-dom';
import { Dropdown } from '../../components/form';
import { useEffect, useState } from 'react';
import Breadcurb from '../../common/Breadcurb';
import { Circle } from '../../common/Circle';
import { NavTab, NavTabs, NavTabsContainer } from '../../common/NewTab';
import FeedbackMessage from '../../components/form/FeedbackMessage';

import expand from '../../images/expand.svg';
import minlScreen from '../../images/minlScreen.svg';

import rightArrowLight from '../../images/RightArrowLight.svg';

import InterviewTab from './InterviewTab';
import DownIndicator from '../../common/DownIndicator';
import EditTab from './EditTab';
import TranscriptEditor from './TranscriptEditor';
import { useLocation } from 'react-router-dom';
import { edit_status, fieldsData, form_status, interview_status } from './data';
import { useDispatch, useSelector, connect } from 'react-redux';
import { REQUEST_CAPTURE_GUIDE } from '../../constants/field/GetCaptureGuideConstants';
import { pointer } from 'd3';
import { AGORA_APP_ID } from "../Project/agora.config"
import AgoraRTC from 'agora-rtc-sdk-ng';
import { SEND_PARTICIPANTS_LIST_ONEND } from '../../constants/projects/ProjectConstants';
import { GlobalChannelName } from './VideoCall';
const RightArrow = () => {
  return <img src={rightArrowLight} />;
};

const HeaderCard = styled(Card)`
  border: 1px solid var(--greyBlue30);
  box-shadow: none;
  margin-left: 0px;
  padding: 0;
  border-radius: 10px;
  height: auto;

  opacity: ${props => (props.callOn ? 0.5 : 1)};
`;

const CustomButton = styled(Button)`
  :disabled,
  [disabled] {
    background: transparent;
    border-color: var(--middleGrey);
    color: var(--middleGrey);

    :hover {
      box-shadow: none;
      background: #c2c2c2;
      color: #ffffff;
      cursor: not-allowed;
    }
  }
`;
const Progress = styled.div`
  & > div {
    width: 30%;
    padding-left: 1.2rem;
  }
  & > div:first-of-type {
    padding-left: 0rem;
  }
  & > div:last-child {
  }
`;
const Container = styled.div`
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--secondaryColor);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ResizeContainer = styled.div`
  transition: all 0.3s linear;
  &.fullScreen {
    transition: all 0.3s linear;
    background: white;
    position: absolute;
    top: -10px;
    z-index: 1050;
    width: 100%;
    padding: 0;
    margin: 0;
    padding: 0 1rem;
    left: 0;
    .row {
      margin-right: 0;
      margin-left: 0;
    }
    .ag-container {
      // width: 750px;
    }
    .ag-btn-group {
      z-index: 20;
    } // height: 100%;;
  }
`;

const Mapper = {
  2: InterviewTab,
  3: EditTab,
  4: TranscriptEditor
};
let editTab = false;
let TabScreen = Mapper[4];
function PreCallScreen(props) {
  const [step, setStep] = useState(2);
  const [usersList, setUsersList] = useState([]);
  const [callOn, setCallOn] = useState(false);
  const [respondantActive, setRespondantActive] = useState(false);
  TabScreen = Mapper[step];
  const [fullScreen, setFullScreen] = useState(false);
  let location = useLocation();
  const startInfo = location.state != undefined ? location.state.data : '';
  const editInfo = location.state?.editData;
  editTab = location.state?.editStatus;
  const [respondantInfo, setRespondentInfo] = useState(startInfo ? startInfo : editInfo);

  //const InterviewSchedule_ID = location.state && location.state.item.InterviewSchedule_ID;

  const projectRespondent_ID = respondantInfo?.projectRespondent_ID;
  const InterviewSchedule_ID = respondantInfo?.InterviewSchedule_ID != null ? respondantInfo?.InterviewSchedule_ID : '';
  const [selectedRespondantVideoId, setSelectedRespondantVideoId] = useState(respondantInfo?.projectRepondentVideo_ID);
  const dispatch = useDispatch();
  const userId = useSelector(state => state.LoginReducer.userId);
  const projectId = useSelector(state => state.ProjectReducer.project_ID);
  let { id } = useParams();
  const projectTitle = props.projectTitle != undefined ? props.projectTitle : '';
  const [preCallClipTimeErrorForEdit, setPreCallClipTimeErrorForEdit] = useState();
  const [preCallClipTimeError, setPreCallClipTimeError] = useState();
  const [preCallScreenSectionMessage, setPreCallScreenSectionMessage] = useState();
  const [preCallScreenSaveButtonClicked, setPreCallScreenSaveButtonClicked] = useState();
  const [intervalId, setIntervalId] = useState();
  const [downlinkClient] = useState(() => AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }))
  const [deviceState, setDeviceState] = useState({
    mic: false, cam: false
  });
  const [localTrack, setLocalTrack] = useState({audio: null, video:null});
  const [isAudioPlaying, setIsAudioPlaying] = useState(false)
  const [showPrecallTest, setShowPrecallTest] = useState(true);

  const getNormalizedValue = (value) => {
    if (value === 0)
    return 0
    return 7 - value;
  }

  useEffect(()=>{
    if (localTrack.audio)
    localTrack.audio.setEnabled(deviceState.mic)

    if (localTrack.video)
    localTrack.video.setEnabled(deviceState.cam)
  },[deviceState])

  const getAudioVideoState = (state) => { 
    setDeviceState(state)    
  }

  const getRandomVal = () => {
    return (Math.floor(Math.random() * 1000000001)).toString()
  }

  const getNetworkStrength = async () => {
    let uid = getRandomVal()
    let channelName = "channel" + uid
    await downlinkClient.join(AGORA_APP_ID, channelName, null, uid);

    // Get downlink network quality
    downlinkClient.on('network-quality', quality => {
      const networkBar = document.getElementById('network-bar');

      const normalizedVal = getNormalizedValue(quality.downlinkNetworkQuality);
      const fillWidth = (normalizedVal / 6) * 100;
      networkBar.style.width = `${fillWidth}%`;
    });
  };

  // useEffect(() => {
  //   // Get all audio and video devices
  //   AgoraRTC.getDevices()
  //     .then(devices => {
  //       const audioDevices = devices.filter(function(device) {
  //         return device.kind === 'audioinput';
  //       });
  //       const videoDevices = devices.filter(function(device) {
  //         return device.kind === 'videoinput';
  //       });

  //       var selectedMicrophoneId = audioDevices[0].deviceId;
  //       var selectedCameraId = videoDevices[0].deviceId;
  //       return Promise.all([
  //         AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
  //         AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId })
  //       ]);
  //     })
  //     .then(([videoTrack, audioTrack]) => {
  //       videoTrack.setEnabled(false)
  //       audioTrack.setEnabled(false)
  //       setLocalTrack({
  //         audio: audioTrack,
  //         video: videoTrack
  //       })
  //       const ele = document.getElementById("video");
  //       videoTrack.play(ele);
  //       const progressBar = document.getElementById('progress-bar');
  //       let interval = setInterval(() => {
  //         const level = audioTrack.getVolumeLevel();
  //         const width = level * 100;
  //         progressBar.style.width = `${width}%`;
  //       }, 50);

  //       setIntervalId(interval);

  //       getNetworkStrength();
  //     })
  //     .catch(err => {
  //       console.log(err, '**************videotrackaaaa err');
  //     });

  //   return () => cleanup();
  // }, []);
  
  const cleanup = () => {
    clearInterval(intervalId)
    downlinkClient.removeAllListeners()
    downlinkClient.leave()
  }
  useEffect(() => {
    if (localStorage.getItem('LocationKey') !== location.key) {
      localStorage.removeItem('ButtonClicked');
    }
    localStorage.setItem('LocationKey', location.key);

    if (editTab) {
      setStep(3);
    }
    if (location.state?.usersList?.length) {
      let users = location.state.usersList.map(user => ({
        ...user,
        value: user.RespondentName,
        label: user.RespondentName
      }));
      setUsersList(users);
    }
  }, []);
  useEffect(() => {}, [respondantActive]);
  useEffect(() => {
    if (id != undefined) {
      console.log("REQUEST_CAPTURE_GUIDE", projectRespondent_ID)
      dispatch({
        type: REQUEST_CAPTURE_GUIDE,
        payload: {
          user_ID: userId,
          project_ID: id,
          projectRespondent_ID: projectRespondent_ID
        }
      });
    }
  }, [id]);

  const captureguideData = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
  //  console.log("CaptureGuideData", captureguideData)

  


  useEffect(() => {
    localStorage.setItem('projectRepondentVideo_ID', captureguideData?.projectRepondentVideo_ID);
    localStorage.setItem('projectRespondent_ID', projectRespondent_ID);
    localStorage.setItem('userId', userId);
  });

  useEffect(() => {
    const Payload = {
      user_ID: userId,
      project_ID: id
    };
    // dispatch({ type: 'RESUME_DATA', payload: Payload });
  }, []);

  const projectStatus = props.projectStatus != undefined ? props.projectStatus : '';

  const changeScreenSize = () => {
    if (fullScreen) {
      setFullScreen(false);
      localStorage.setItem('respFullScreen', false);
    } else {
      setFullScreen(true);
      localStorage.setItem('respFullScreen', true);
    }
  };

  const handlePreCallScreenClipTimeErrorForEdit = cterror => {
    setPreCallClipTimeErrorForEdit(cterror);
  };

  const handlePreCallScreenClipTimeError = cterror => {
    setPreCallClipTimeError(cterror);
  };

  const handlePreCallScreenSectionMessage = smessage => {
    setPreCallScreenSectionMessage(smessage);
  };

  const handlePreCallScreenSaveButtonClicked = sclicked => {
    setPreCallScreenSaveButtonClicked(sclicked);
  };
  const handleAudio = () => {
    const element = document.getElementById('myAudio');
    if (!isAudioPlaying) {
      element.play();
    } else {
      element.pause();
    }
    setIsAudioPlaying(prev => !prev);
  };

  return (
    <ProjectWrapper projectTitle={projectTitle} step={4} projectStatus={projectStatus} projectId={id}>
      <Breadcurb>
        <li className="breadcrumb-item ">
          <Link to={`/project/${id}/field`}>Field Overview</Link>
          {/* <a href="#">Field Overview</a> */}
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Respondent Details
        </li>
      </Breadcurb>

      <HeaderCard callOn={callOn} className="row mt-2">
        <div
          className="col-md-3 py-2 px-4"
          style={{
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            background: 'var(--greyBlueLight)'
          }}
        >
          <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
            Respondent:{' '}
          </Heading>
          <Dropdown
            options={usersList.sort((a, b) => 
              a.RespondentName?.localeCompare(b.RespondentName) || 
              a.name?.localeCompare(b.name)
            )}
            isDisabled={respondantActive}
            selectedFontSize="0.875rem"
            selectedColor="var(--deepBlue)"
            selectedFontWeight="700"
            controlStyle={{ minHeight: '0px' }}
            valueContainerStyle={{
              padding: 0
            }}
            onChange={value => {
              setRespondentInfo(value);
              setSelectedRespondantVideoId(value.projectRepondentVideo_ID);
              if (value.editStatus) {
                setStep(3);
              } else {
                setStep(2);
              }
              dispatch({
                type: REQUEST_CAPTURE_GUIDE,
                payload: {
                  user_ID: userId,
                  project_ID: id,
                  projectRespondent_ID: value.projectRespondent_ID
                }
              });
            }}
            DropdownIndicator={DownIndicator}
            selectedBorderBottom="2px solid var(--deepBlue)"
            defaultValue={{
              value: respondantInfo?.Respondent_ID,
              label: respondantInfo?.RespondentName != undefined ? respondantInfo?.RespondentName : respondantInfo?.name
            }}
          />
        </div>
        <div className="col-md-2 px-4" style={{ borderRight: '1px solid var(--greyBlue30)', paddingTop: '15px' }}>
          <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
            Group:{' '}
          </Heading>
          <div className="d-flex">
            <div className="">
              <Heading type={3} fontSize="0.875rem" fontWeight="600" color="var(--deepBlue)">
                {respondantInfo?.ProjectGroup}
              </Heading>
            </div>
            <div className="pl-2">
              <Heading type={3} fontSize="0.875rem" fontWeight="600" color="var(--deepBlue)">
                {respondantInfo?.groupName}
              </Heading>
            </div>
          </div>
        </div>
        <div
          className="col-md-7 py-2 px-4"
          style={{
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10
          }}
        >
          <Progress className="d-flex  align-items-center">
            <div>
              <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
                Form:{' '}
              </Heading>
              <div className="d-flex align-items-center">
                <Circle status={respondantInfo?.formStatus} />

                <Heading
                  type={3}
                  className="pb-0 mb-0"
                  fontSize="0.875rem"
                  fontWeight="500"
                  color="var(--deepBlue)"
                  breakHeading={false}
                >
                  {form_status[respondantInfo?.formStatus]}
                </Heading>
              </div>
            </div>
            <RightArrow />
            <div>
              <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
                Interview:{' '}
              </Heading>
              <div className="d-flex align-items-center">
                <Circle
                  status={
                    respondantInfo?.interviewStatus && respondantInfo?.interviewStatus == 2
                      ? 1
                      : respondantInfo?.interviewStatus
                  }
                />
                <Circle
                  status={
                    respondantInfo?.interviewStatus && respondantInfo?.interviewStatus == 2
                      ? 0
                      : respondantInfo?.interviewStatus == 3
                      ? 0
                      : respondantInfo?.interviewStatus
                  }
                />
                <Heading
                  type={3}
                  className="pb-0 mb-0"
                  fontSize="0.875rem"
                  fontWeight="700"
                  color="var(--deepBlue)"
                  breakHeading={false}
                >
                  {interview_status[respondantInfo?.interviewStatus]}
                </Heading>
              </div>
            </div>
            <RightArrow />

            <div>
              <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
                Edit:{' '}
              </Heading>
              <div className="d-flex align-items-center">
                <Circle status={0} />

                <Heading
                  type={3}
                  className="pb-0 mb-0"
                  fontSize="0.875rem"
                  fontWeight="500"
                  color="var(--deepBlue)"
                  breakHeading={false}
                >
                  {edit_status[respondantInfo?.editStatus]}
                </Heading>
              </div>
            </div>
          </Progress>
        </div>
      </HeaderCard>
      <ResizeContainer className={`${fullScreen && 'fullScreen'}`}>
        <Container className="mt-4">
          <NavTabsContainer>
            <NavTabs>
              <NavTab fontSize="0.75rem" active={step === 1} Cursor="not-allowed">
                Form
              </NavTab>
              <NavTab fontSize="0.75rem" active={step === 2} Cursor="not-allowed">
                Interview
              </NavTab>
              { step === 2 ?
              <NavTab  fontSize="0.75rem" active={step === 3} Cursor="not-allowed">
              Edit
              </NavTab>
               : <NavTab onClick={() => setStep(3)} fontSize="0.75rem" active={step === 3}>
               Edit
             </NavTab>
              }
              { step === 2 ?
              <NavTab fontSize="0.75rem" active={step === 4} Cursor="not-allowed">
                Edit Transcript
              </NavTab> : 
              <NavTab onClick={() => setStep(4)} fontSize="0.75rem" active={step === 4}>
              Edit Transcript
              </NavTab>
              }
            </NavTabs>
          </NavTabsContainer>
          {preCallClipTimeErrorForEdit && <FeedbackMessage>{preCallClipTimeErrorForEdit}</FeedbackMessage>}

          {preCallClipTimeError && <FeedbackMessage>{preCallClipTimeError}</FeedbackMessage>}

          {preCallScreenSectionMessage && preCallScreenSaveButtonClicked && (
            <FeedbackMessage>{preCallScreenSectionMessage}</FeedbackMessage>
          )}
          {fullScreen ? (
            <img src={minlScreen} onClick={() => changeScreenSize()} style={{ cursor: 'pointer' }} />
          ) : (
            <img src={expand} onClick={() => changeScreenSize()} style={{ cursor: 'pointer' }} />
          )}
        </Container>
        <TabScreen
          selectedRespondantVideoId={selectedRespondantVideoId}
          setRespondantActive={setRespondantActive}
          callOn={callOn}
          cleanup={cleanup}
          getAudioVideoState={getAudioVideoState}
          setShowPrecallTest={setShowPrecallTest}
          setStep={setStep}
          setCallOn={setCallOn}
          respondantInfo={respondantInfo}
          projectRespondent_ID={projectRespondent_ID}
          id={id}
          InterviewSchedule_ID={InterviewSchedule_ID}
          fullScreen={fullScreen}
          passClipTimeErrorForEdittoPreCallScreen={handlePreCallScreenClipTimeErrorForEdit}
          passClipTimeErrortoPrecallScreen={handlePreCallScreenClipTimeError}
          passSectionMessagetoPreCallScreen={handlePreCallScreenSectionMessage}
          passSaveButtonClickedtoPreCallScreen={handlePreCallScreenSaveButtonClicked}
        />
      </ResizeContainer>
      {/* {showPrecallTest ? (
        <>
          <div className="container">
            <div id="progress-bar"></div>
          </div>
          <div className="container">
            <div id="network-bar"></div>
          </div>
          <div id="video"></div>
          <audio
            id="myAudio"
            src="https://file-examples.com/storage/fe3cb26995666504a8d6180/2017/11/file_example_MP3_700KB.mp3"
            controls
            hidden
          ></audio>
          <button onClick={handleAudio}>{isAudioPlaying ? 'Pause' : 'Play'} audio</button>
        </>
      ) : null} */}
    </ProjectWrapper>
  );
}

const mapStateToProps = state => {
  //console.log("Channel name", state)
  return {
    // will be available as props.captureData
    projectTitle:
      state.ProjectReducer.resumeData.project_data != undefined
        ? state.ProjectReducer.resumeData.project_data[0].identity
        : '',
    projectStatus:
      state.ProjectReducer.resumeData.projectStatus != undefined
        ? state.ProjectReducer.resumeData?.projectStatus[0]?.steps_array != undefined
          ? state.ProjectReducer.resumeData?.projectStatus[0]?.steps_array
          : ''
        : ''
  };
};

// export default EditTab;
export default connect(mapStateToProps)(PreCallScreen);
