import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditClipMethods from '../../utils/api/field/EditClipData';

function* EditClipData(payload) {
  const promise = EditClipMethods.EditClipData(payload.payload);
  const { res } = yield promise;
  const statusCode = res?.Status?.StatusCode;
  yield putResolve({ type: actions.EditClipAction.EDITCLIP_RECEIVED, data: { statusCode, response: res.Response} });
}

export  { EditClipData }