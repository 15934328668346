import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button, Text, Avatar, Heading } from '../../';
import styled from '@emotion/styled';
import {
  InputText,
  TextArea,
  DropFile,
  Dropdown,
  ButtonRadio,
  TaskTypeOption,
  TaskMenu
} from '../../../components/form';
import Divider from '../../Divider';
import Icon from '../../Icon';
import cross from '../../../assets/images/cross.svg';
import GreenDropdown from '../../../assets/images/green-dropdown.svg';
import profile_pic from '../../../assets/images/profile_pic.png';
import PlusGreyBold from '../../../assets/images/plus-grey-bold.svg';
import AddProfilePic from '../../../assets/images/addProfilePic.svg';
import ChangeProfilePicModal from '../ChangeProfilePicModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  REQUEST_EDITCLIENTADMINDATA,
  CLEAR_EDITCLIENTADMINDATA
} from '../../../constants/SuperAdmin/EditClientAdminConstants';
import { REQUEST_SUPERADMINDASHBOARD_DATA } from '../../../constants/SuperAdmin/SuperAdminDashboardConstants';
import { REQUEST_UPDATE_PASSWORD } from '../../../constants/SuperAdmin/UpdatedPasswordConstant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './sucesstoast.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
const field = {
  marginLeft: '0px'
};

const InputTextContainer = styled.div`
  .iconContainer {
    position: absolute;
    top: 30%;
    right: 15px;
  }
  span {
    font-size: 18px;
    color: var(--greyBlue);
  }
`;

const SeeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1;

  .reverse {
    transform: rotate(-180deg);
  }
`;
const CustomButton = styled(Button)`
  width: ${props => props.width};
  padding: 3px 3px !important;
  float: right;
  margin-right: 5px;
`;

const Pill = styled.span`
  font-size: 9px;
  font-family: 'Readex Pro', sans-serif;
  padding: 0.5em 1em;
  margin: 0.25em;
  // border-radius: 1em;
  // border: none;
  // outline: none;
  // background: #dddddd;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid grey;
  font-weight: bold;
`;

const gender_list = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
  { value: 3, label: 'Non-Binary' }
];

const city_list = [
  { value: 1, label: 'Hyderabad' },
  { value: 2, label: 'Bangalore' },
  { value: 3, label: 'Mumbai' },
  { value: 4, label: 'Kolkata' },
  { value: 5, label: 'Delhi' }
];

const qualification_list = [
  { value: 1, label: 'Illiterate' },
  { value: 2, label: 'Literate but no formal schooling' },
  { value: 3, label: 'School (5-9 yrs)' },
  { value: 4, label: 'SSC/HSC' },
  { value: 5, label: 'Some College(Including a diploma but not graduate)' },
  { value: 6, label: 'Graduate/Post-Graduate: General' },
  { value: 7, label: 'Graduate/Post-Graduate: Professional' }
];

const subscription_list = [
  { value: 1, label: 'A30' },
  { value: 2, label: 'A50' },
  { value: 3, label: 'A100' },
  { value: 4, label: 'A400' }
];

const SeeLess = () => (
  <>
    See less <img src={GreenDropdown} className="ml-2" />
  </>
);

const SeeMore = () => (
  <>
    See more <img src={GreenDropdown} className="ml-2 reverse" />
  </>
);

export default function EditClientAdminModal({ clientAdminModaldata, setIsFlag, IsFlag, showModal, setShowModal }) {
  const [seeMoreProfile, setSeeMoreProfile] = useState(true);
  const [seeMoreLicense, setSeeMoreLicense] = useState(false);
  const [seeMoreBilling, setSeeMoreBilling] = useState(false);
  const [GetClientAdminData, setGetClientAdminData] = useState([]);

  const [editClientAdminData, setEditClientAdminData] = useState({});
  const modalRef = useRef(null);

  const dispatch = useDispatch();
  // const [userType, setUserType] = useState('');
  const clientAdminData = useSelector(state => state.EditClientAdminReducer.editClientAdminData);
  const currentStatus = useSelector(state => state.EditClientAdminReducer.editClientAdminDataRecived);

  useEffect(() => {
    setGetClientAdminData(clientAdminModaldata);
  }, [clientAdminModaldata]);

  const closeModal = () => {
    $(modalRef.current).modal('hide');
    setShowModal(false);
  };

  useEffect(() => {
    if (GetClientAdminData) {
      const updatedData = {
        subscription_plan: GetClientAdminData?.subscriptionPlan || ''
      };

      Object.keys(GetClientAdminData).forEach(key => {
        const index = parseInt(key);
        if (!isNaN(index)) {
          updatedData[`fullname_${index}`] = GetClientAdminData[key]?.name || '';
          updatedData[`designation_${index}`] = GetClientAdminData[key]?.desigination || '';
          updatedData[`team_name_${index}`] = GetClientAdminData[key]?.teamName || '';
          updatedData[`team_email_${index}`] = GetClientAdminData[key]?.email || '';
          updatedData[`team_mobile_${index}`] = GetClientAdminData[key]?.mobile || '';
          updatedData[`division_name_${index}`] = GetClientAdminData[key]?.division || '';
        }
      });

      setEditClientAdminData(updatedData);
    }
  }, [GetClientAdminData]);

  // const handleChange = (evt) => {
  //   const { name, value } = evt.target;
  //   setGetClientAdminData((prevData) => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  // };

  const handleChange = evt => {
    const value = evt.target.value;
    setGetClientAdminData({
      ...GetClientAdminData,
      [evt.target.name]: value
    });
  };

  const handleLicenseChange = e => {
    const { name, value } = e.target;
    setEditClientAdminData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubscriptionChange = option => {
    //  console.log("option", option)
    setEditClientAdminData(prevData => ({
      ...prevData,
      subscription_plan: option.label
    }));
  };

  const change_password = () => {
    const user_id = GetClientAdminData?.user_id;

    dispatch({
      type: REQUEST_UPDATE_PASSWORD,
      payload: user_id
    });
  };

  const validateLicenseFields = () => {
    let isValid = true;

    for (let i = 1; i <= 3; i++) {
      let hasValue =
        editClientAdminData[`team_name_${i}`]?.trim() ||
        editClientAdminData[`fullname_${i}`]?.trim() ||
        editClientAdminData[`designation_${i}`]?.trim() ||
        editClientAdminData[`team_email_${i}`]?.trim() ||
        editClientAdminData[`team_mobile_${i}`]?.trim() ||
        editClientAdminData[`division_name_${i}`]?.trim();

      if (hasValue) {
        if (
          !editClientAdminData[`team_name_${i}`]?.trim() ||
          !editClientAdminData[`fullname_${i}`]?.trim() ||
          !editClientAdminData[`designation_${i}`]?.trim() ||
          !editClientAdminData[`team_email_${i}`]?.trim() ||
          !editClientAdminData[`team_mobile_${i}`]?.trim() ||
          !editClientAdminData[`division_name_${i}`]?.trim()
        ) {
          isValid = false;
        }
      }
    }

    return isValid;
  };
  const editClientAdmin = () => {
    if (!validateLicenseFields()) {
      setShowModal(true);
      toast.error('If one license field is filled, all related fields must be completed.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        style: { marginTop: '70px' }
      });

      return;
    }
    const originalSubscriptionPlan = GetClientAdminData?.subscriptionPlan;
    const currentSubscriptionPlan = editClientAdminData?.subscription_plan;
    const finalSubscriptionPlan =
      currentSubscriptionPlan !== originalSubscriptionPlan ? currentSubscriptionPlan : originalSubscriptionPlan;

    // Dynamically generate the license array based on available data
    const licenses = Object.keys(GetClientAdminData)
      .filter(key => !isNaN(parseInt(key))) // Ensure key is a number
      .map(key => {
        const index = parseInt(key);
        return {
          FullName: editClientAdminData[`fullname_${index}`] || '',
          TeamName: editClientAdminData[`team_name_${index}`] || '',
          email: editClientAdminData[`team_email_${index}`] || '',
          phone: editClientAdminData[`team_mobile_${index}`] || '',
          password: `license${index}`,
          desigination: editClientAdminData[`designation_${index}`] || '',
          identity_id: `${index}`,
          division: editClientAdminData[`division_name_${index}`] || '',
          id: GetClientAdminData[index]?.id || '',
          ClientTeam_ID: GetClientAdminData[index]?.client_ID || '' // Ensure ClientTeam_ID is included
        };
      })
      .filter(license => license.FullName && license.TeamName && license.email && license.phone); // Only include non-empty licenses

    const formData = {
      user_id: GetClientAdminData?.user_id,
      name: GetClientAdminData?.name,
      Client_ID: GetClientAdminData?.client_ID,
      CompanyName: GetClientAdminData?.clientsName,
      email: GetClientAdminData?.email,
      mobile: GetClientAdminData?.mobile,
      password: '1234',
      city: GetClientAdminData?.city,
      pincode: GetClientAdminData?.pincode,
      address: GetClientAdminData?.address,
      subscription_plan: finalSubscriptionPlan,
      validFrom: GetClientAdminData?.validFrom,
      validTo: GetClientAdminData?.validTo,
      HoursAllocated: '4',
      accountName: GetClientAdminData.accountName,
      accountNumber: GetClientAdminData.accountNumber,
      branchName: GetClientAdminData.branchName,
      swiftCode: GetClientAdminData.swiftCode,
      sortCode: GetClientAdminData.sortCode,
      IFSCCode: GetClientAdminData.IFSCCode,
      license: licenses // Ensure license array contains ClientTeam_ID
    };

    dispatch({
      type: REQUEST_EDITCLIENTADMINDATA,
      payload: formData
    });

    setTimeout(() => {
      if (currentStatus === 1) {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'var(--primaryColor)', marginRight: '8px', fontSize: '1.5em' }}
            />
            <span>Client admin data updated successfully!</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            style: { marginTop: '70px' },
            icon: false
          }
        );
        closeModal();
      } else {
        toast.error('Failed to update client admin data. Please try again.', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          style: { marginTop: '70px' }
        });
      }

      dispatch({ type: REQUEST_SUPERADMINDASHBOARD_DATA });
      dispatch({ type: CLEAR_EDITCLIENTADMINDATA });
    }, 500);

    // setIsFlag(0);
  };

  return (
    <div>
      {showModal && (
        <>
          <div
            className="modal fade"
            id="editClientAdminModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            ref={modalRef}
          >
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '700px' }}>
              <div className="modal-content" style={{ borderRadius: '10px' }}>
                <div className="modal-header border-0">
                  <Text>
                    <Pill className="mt-2 ml-2">
                      {' '}
                      {GetClientAdminData?.clientsName}
                      <img src={cross} className="ml-1 mb-1" style={{ cursor: 'pointer', borderRadius: '50%' }} />
                    </Pill>
                  </Text>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-1" data-toggle="modal" data-target="#changeProfilePicModal">
                        <img src={AddProfilePic} style={{ position: 'absolute', marginTop: '45px' }} />
                        <Avatar src={profile_pic} className="team-profile-pic" width="60" height="60" />
                      </div>
                      <div className="container w-75">
                        <div className="row">
                          {/* Profile Details */}
                          <div>
                            <Heading fontSize="14px" className="mt-3 ml-3">
                              Profile
                            </Heading>
                            {seeMoreProfile == true ? (
                              <>
                                <InputTextContainer>
                                  <div className="row" style={field}>
                                    <div className="col-8">
                                      <InputText
                                        name="name"
                                        placeholder="Name"
                                        showCharacters={false}
                                        defaultValue={GetClientAdminData?.name}
                                        value={GetClientAdminData?.name}
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="mobile"
                                        placeholder="Phone No."
                                        showCharacters={false}
                                        value={GetClientAdminData?.mobile}
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-8">
                                      <InputText
                                        name="email"
                                        placeholder="Email"
                                        showCharacters={false}
                                        value={GetClientAdminData?.email}
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                  {/* ///// reset password */}
                                  <div className="row" id="ResetPassword">
                                    <div className="col col-5 mt-2 mb-2 ml-1">
                                      <CustomButton
                                        className="ml-0"
                                        size="small"
                                        variant="outlined"
                                        fontSize="8px"
                                        data-toggle="modal"
                                        data-target="#ResetPasswordClientAdmin"
                                        style={{
                                          paddingLeft: '20px',
                                          marginRight: '85px',
                                          borderRadius: '7px',
                                          height: '20px',
                                          width: '85px'
                                        }}
                                        onClick={change_password}
                                      >
                                        Reset Passwords
                                      </CustomButton>
                                      {/* New Modal for Reset Password  */}
                                      <div
                                        className="modal fade"
                                        id="ResetPasswordClientAdmin"
                                        tabindex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalCenterTitle"
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog modal-dialog-centered "
                                          role="document"
                                          style={{ width: '400px' }}
                                        >
                                          <div className="modal-content" style={{ borderRadius: '10px' }}>
                                            <div className="modal-header border-0">
                                              <Heading fontSize="18px">Reset Your Password</Heading>
                                              {/* <button type="button" className="close" data-dismiss="modal" data-target='#DeleteModal' aria-label="Close">
                                                                                        <span aria-hidden="true">&times;</span>
                                                                                    </button> */}
                                            </div>
                                            <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                                              <div className="row mb-2">
                                                <div className="col col-12">
                                                  <Text type="3" color="var(--ternaryColor)" className="mb-0">
                                                    We sent a reset password email to {GetClientAdminData?.email}.
                                                    Please click the reset password link to set your new password.
                                                  </Text>
                                                </div>
                                              </div>
                                              <div className="row" style={{ paddingTop: '20px' }}>
                                                <div id="delete" className="col col-3">
                                                  <CustomButton
                                                    className="ml-1 mt-1"
                                                    size="small"
                                                    // variant='outlined'
                                                    width="60px"
                                                    data-dismiss="modal"
                                                    // style={{ color: "#798E8F", background: "none" }}
                                                  >
                                                    Done
                                                  </CustomButton>
                                                </div>
                                                <div
                                                  id="resendEmail"
                                                  className="col col-8 col-offset-6"
                                                  style={{ display: 'flex', justifyContent: 'end' }}
                                                >
                                                  <Text
                                                    type="3"
                                                    color="var(--ternaryColor)"
                                                    fontSize="10px"
                                                    className="mt-2"
                                                  >
                                                    <u>Resend Email</u>
                                                  </Text>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/* END */}
                                    </div>
                                  </div>
                                  {/* ///////////////////// */}
                                  <div className="row" style={field}>
                                    <div className="col-8">
                                      <InputText
                                        name="clientsName"
                                        placeholder="Company name"
                                        showCharacters={false}
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={GetClientAdminData?.clientsName}
                                        // value='Rohit Nimbalkar'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-12">
                                      <InputText
                                        name="address"
                                        placeholder="Company Address"
                                        showCharacters={false}
                                        value={GetClientAdminData?.address}
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-8">
                                      <Dropdown
                                        name="city"
                                        placeholder={GetClientAdminData?.city}
                                        showCharacters={false}
                                        options={city_list}
                                        selected={GetClientAdminData?.city}
                                        // onChange={(evt) =>
                                        //     //  console.warn(evt.label)
                                        //     setGetClientAdminData({
                                        //         ...GetClientAdminData,
                                        //         'city': evt.label
                                        //     })
                                        // }
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // value={GetClientAdminData?.city}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="pincode"
                                        placeholder="Pincode"
                                        showCharacters={false}
                                        value={GetClientAdminData?.pincode}
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                </InputTextContainer>
                              </>
                            ) : null}
                          </div>
                          {/* END */}
                        </div>
                        {/* See more Button */}
                        <SeeButton
                          className="mt-4"
                          simple
                          color="var(--primaryColor)"
                          fontSize="0.875rem"
                          fontWeight="500"
                          onClick={() => setSeeMoreProfile(!seeMoreProfile)}
                        >
                          {seeMoreProfile ? SeeLess() : SeeMore()}
                        </SeeButton>
                        {/* END */}

                        <Divider margin="0" height="2px" width="103%" color="#38454F" className="mt-2" />

                        <div className="">
                          {/* Primary Earning Member Details */}
                          <div>
                            <div className="row">
                              <div className="col-6">
                                <Heading fontSize="14px" className="mt-3">
                                  Licenses & Subscription
                                </Heading>
                              </div>
                              <div className="col-6 d-flex" style={{ justifyContent: 'end' }}>
                                <Heading fontSize="12px" className="mt-3 ml-3">
                                  <img src={PlusGreyBold} width="20px" height="15px" className="mr-1 mb-1" />
                                  Add License
                                </Heading>
                              </div>
                            </div>
                            {seeMoreLicense == true ? (
                              <>
                                <InputTextContainer>
                                  <div className="row" style={field}>
                                    <div className="col-4 mt-3 mb-3">
                                      <Text type="7">License 1</Text>
                                    </div>
                                    <div className="col-4 mt-3 mb-3">
                                      <Text type="7">License 2</Text>
                                    </div>
                                    <div className="col-4 mt-3 mb-3">
                                      <Text type="7">License 3</Text>
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-4">
                                      <InputText
                                        name="team_name_1"
                                        placeholder="Team name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_name_1'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="team_name_2"
                                        placeholder="Team name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_name_2'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="team_name_3"
                                        placeholder="Team name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_name_3'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-4">
                                      <InputText
                                        name="fullname_1"
                                        placeholder="Full name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['fullname_1'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="fullname_2"
                                        placeholder="Full name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['fullname_2'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="fullname_3"
                                        placeholder="Full name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['fullname_3'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-4">
                                      <InputText
                                        name="designation_1"
                                        placeholder="Designation"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['designation_1'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="designation_2"
                                        placeholder="Designation"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['designation_2'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="designation_3"
                                        placeholder="Designation"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['designation_3'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-4">
                                      <InputText
                                        name="team_email_1"
                                        placeholder="Email ID"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_email_1'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="team_email_2"
                                        placeholder="Email ID"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_email_2'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="team_email_3"
                                        placeholder="Email ID"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_email_3'] || ''}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-4">
                                      <InputText
                                        name="team_mobile_1"
                                        placeholder="Phone no."
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_mobile_1'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="team_mobile_2"
                                        placeholder="Phone no."
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_mobile_2'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="team_mobile_3"
                                        placeholder="Phone no."
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['team_mobile_3'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-4">
                                      <InputText
                                        name="division_name_1"
                                        placeholder="Division Name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['division_name_1'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="division_name_2"
                                        placeholder="Division Name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['division_name_2'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-4">
                                      <InputText
                                        name="division_name_3"
                                        placeholder="Division Name"
                                        showCharacters={false}
                                        onChange={handleLicenseChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={editClientAdminData['division_name_3'] || ''}
                                        // value='Bussiness'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                  </div>
                                  <div className="row mt-4" style={field}>
                                    <div className="col-6">
                                      <Dropdown
                                        name="subscription_plan"
                                        placeholder={editClientAdminData.subscription_plan}
                                        showCharacters={true}
                                        options={subscription_list}
                                        selected={editClientAdminData.subscription_plan}
                                        onChange={handleSubscriptionChange}
                                        value={editClientAdminData.subscription_plan}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                        selectedFontWeight="60"
                                        selectedFontSize="medium !important"
                                        selectedColor="#757c7cd9 !important"
                                      />
                                      {/* <Icon className='iconContainer'>create</Icon> */}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-5 mt-2 d-flex" style={{ justifyContent: 'end' }}>
                                      <Text type="7">Subscription Status: Active</Text>
                                    </div>
                                  </div>
                                  <div className="row mt-2" style={field}>
                                    <div className="col-6 mt-2">
                                      <Text type="7">Hours used: 0 hrs</Text>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-5 mt-2 d-flex" style={{ justifyContent: 'end' }}>
                                      <Text type="7">Hours Left: 0 hrs</Text>
                                    </div>
                                  </div>

                                  <div className="row mt-3" style={field}>
                                    <div className="col-6 mt-2">
                                      <Text type="7">Period of Subscription : </Text>
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-6">
                                      <text style={{ fontSize: '10px' }}>
                                        <strong>Valid from : </strong>
                                        <input
                                          type="date"
                                          style={{
                                            boxShadow: 'inset 0px 0px 2px 1.5px rgb(0 0 0 / 15%)',
                                            width: '120px',
                                            borderRadius: '3px',
                                            border: 'navajowhite'
                                          }}
                                          name="validFrom"
                                          onChange={handleChange}
                                          setIsFlag={setIsFlag}
                                          IsFlag={IsFlag}
                                          value={GetClientAdminData?.validFrom}
                                        />
                                      </text>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-5 mt-1 d-flex" style={{ justifyContent: 'end' }}>
                                      <text style={{ fontSize: '10px' }}>
                                        <strong>Valid till : </strong>
                                        <input
                                          type="date"
                                          style={{
                                            boxShadow: 'inset 0px 0px 2px 1.5px rgb(0 0 0 / 15%)',
                                            width: '120px',
                                            borderRadius: '3px',
                                            border: 'navajowhite'
                                          }}
                                          name="validTo"
                                          onChange={handleChange}
                                          setIsFlag={setIsFlag}
                                          IsFlag={IsFlag}
                                          value={GetClientAdminData?.validTo}
                                        />
                                      </text>
                                    </div>
                                  </div>
                                </InputTextContainer>
                              </>
                            ) : null}
                          </div>
                          {/* END */}
                        </div>
                        {/* See more Button */}
                        <SeeButton
                          className="mt-4"
                          simple
                          color="var(--primaryColor)"
                          fontSize="0.875rem"
                          fontWeight="500"
                          onClick={() => setSeeMoreLicense(!seeMoreLicense)}
                        >
                          {seeMoreLicense ? SeeLess() : SeeMore()}
                        </SeeButton>
                        {/* END */}

                        <Divider margin="0" height="2px" width="103%" color="#38454F" className="mt-2" />

                        <div className="row">
                          {/* Billing Details */}
                          <div>
                            <Heading fontSize="14px" className="mt-3 ml-3">
                              Billing & Payments Info
                            </Heading>
                            {seeMoreBilling == true ? (
                              <>
                                <InputTextContainer>
                                  <div className="row" style={field}>
                                    <div className="col-6">
                                      <InputText
                                        name="accountName"
                                        placeholder="Account name"
                                        showCharacters={false}
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={GetClientAdminData?.accountName}
                                        // value='Rohit Nimbalkar'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                    <div className="col-6">
                                      <InputText
                                        name="accountNumber"
                                        placeholder="Account Number"
                                        showCharacters={false}
                                        // onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}

                                        // value='7894563210'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-6">
                                      <InputText
                                        name="bank_name"
                                        placeholder="Select Bank"
                                        showCharacters={false}
                                        // onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // value='Rohit Nimbalkar'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                    <div className="col-6">
                                      <InputText
                                        name="branchName"
                                        placeholder="Branch Name"
                                        showCharacters={false}
                                        // onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // value='7894563210'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-6">
                                      <InputText
                                        name="swiftCode"
                                        placeholder="Swift Code"
                                        showCharacters={false}
                                        // onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        // value='Rohit Nimbalkar'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                    <div className="col-6">
                                      <InputText
                                        name="sortCode"
                                        placeholder="Sort Code"
                                        showCharacters={false}
                                        // onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}

                                        // value='7894563210'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                  <div className="row" style={field}>
                                    <div className="col-6">
                                      <InputText
                                        name="IFSCCode"
                                        placeholder="IFSC Code"
                                        showCharacters={false}
                                        onChange={handleChange}
                                        setIsFlag={setIsFlag}
                                        IsFlag={IsFlag}
                                        value={GetClientAdminData?.IFSCCode}
                                        // value='Rohit Nimbalkar'
                                        // showIcon={true}
                                        // iconName="create"
                                        // error='Hey, looks like you’ve missed this'
                                      />
                                      <Icon className="iconContainer">create</Icon>
                                    </div>
                                  </div>
                                </InputTextContainer>
                              </>
                            ) : null}
                          </div>
                          {/* END */}
                        </div>
                        {/* See more Button */}
                        <SeeButton
                          className="mt-4"
                          simple
                          color="var(--primaryColor)"
                          fontSize="0.875rem"
                          fontWeight="500"
                          onClick={() => setSeeMoreBilling(!seeMoreBilling)}
                        >
                          {seeMoreBilling ? SeeLess() : SeeMore()}
                        </SeeButton>
                        {/* END */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0"></div>
                {/* <div className='container-fluid'> */}
                <div className="row">
                  <div className="col-3 modal-footer border-0 ml-4" style={{ justifyContent: 'flex-start' }}>
                    <CustomButton
                      className="ml-2 mt-1"
                      size="small"
                      // variant='outlined'
                      width="60px"
                      //  data-dismiss="modal"
                      // style={{ color: "#798E8F", background: "none" }}
                      onClick={editClientAdmin}
                    >
                      Save
                    </CustomButton>

                    <CustomButton
                      className="ml-2 mt-1"
                      size="small"
                      variant="outlined"
                      width="60px"
                      data-dismiss="modal"
                      // style={{ color: "#798E8F", background: "none" }}
                    >
                      Cancel
                    </CustomButton>
                  </div>
                  {/* <div className='col-8'>
                                    <CustomButton
                                        className='ml-2 mt-3'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-toggle="modal" data-target="#DeleteModalRespondant" data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Delete
                                    </CustomButton>
                                </div> */}
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

          {/* New Modal for Delete User  */}
          <div
            className="modal fade"
            id="DeleteModalRespondant"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
              <div className="modal-content" style={{ borderRadius: '10px' }}>
                <div className="modal-header border-0" style={{ paddingBottom: '0px' }}>
                  <Heading fontSize="18px">Confirm Delete</Heading>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    data-target="#DeleteModal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                  <div className="row">
                    <div className="col col-12">
                      <Text type="7" color="var(--ternaryColor)" className="mb-0">
                        Are you sure you want to delete this Respondent?
                      </Text>
                    </div>
                  </div>
                  <div className="row" style={{ paddingTop: '20px' }}>
                    <div className="col">
                      <CustomButton
                        className="ml-2 mt-1"
                        size="small"
                        variant="outlined"
                        width="60px"
                        // width='5px'
                        // height='4px'
                        fontSize="10px"
                        style={{ float: 'left' }}
                        data-dismiss="modal"
                      >
                        Cancel
                      </CustomButton>
                    </div>
                    <div id="delete" className="col col-4 col-offset-6">
                      <CustomButton
                        className="ml-2 mt-1"
                        size="small"
                        // variant='outlined'
                        width="60px"
                        data-toggle="modal"
                        data-target="#UserDeletedModal"
                        data-dismiss="modal"
                        // style={{ color: "#798E8F", background: "none" }}
                      >
                        Delete
                      </CustomButton>
                    </div>
                  </div>
                </div>
                {/* <div className="modal-footer border-0">
                                <div className='row'>
                                    <div className='col col-4 col-offset-6'>
                                        <Button
                                            className='ml-4'
                                            size='small'
                                            variant='outlined'
                                            width='20px'

                                        >
                                            save changes
                                        </Button>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>

          {/* END */}
          <ToastContainer />
          {/* New Modal for change Profile picture */}
          <ChangeProfilePicModal />
          {/* END */}
        </>
      )}
    </div>
  );
}
