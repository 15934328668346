import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import '../../assets/fonts/css/icons.css';
import Validator from '../utils/Validator';
import { RESOLUTION_ARR } from '../utils/Settings';
import './index.css';
import {
  Heading,
  HelperText,
  Card,
  Text,
  ListMenu,
  SingleValue,
  Button,
  Icon,
  Avatar,
  Divider
} from '../../common';

const ButtonBase = styled(Button)`
  width: 124px;
  height: 30px;
  padding: 0;
`;

const Index = () => {
  const [joinBtn, setJoinBtn] = useState(true);
  const [channel, setChannel] = useState('');
  const [baseMode, setBaseMode] = useState('avc');
  const [transcode, setTranscode] = useState('interop');
  const [attendeeMode, setAttendeeMode] = useState('video');
  const [videoProfile, setVideoProfile] = useState('480p_4');
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 13) {
        handleJoin();
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [joinBtn, channel, baseMode, transcode, attendeeMode, videoProfile]);

  const handleChannel = (val, state) => {
    setChannel(val);
    setJoinBtn(state);
  };

  const handleJoin = () => {
    if (!joinBtn) {
      return;
    }

    localStorage.setItem('channel', channel);
    localStorage.setItem('baseMode', baseMode);
    localStorage.setItem('transcode', transcode);
    localStorage.setItem('attendeeMode', attendeeMode);
    localStorage.setItem('videoProfile', videoProfile);

    console.log('set data', localStorage);
    navigate('video-call');
  };

  return (
    <div className="wrapper index">
      <div className="ag-header"></div>
      <div className="ag-main">
        <section className="login-wrapper">
          <div className="login-body">
            <div className="columns">
              <div className="column">
                <div id="attendeeMode" className="control">
                  <label className="radio">
                    <input
                      onChange={(e) => setAttendeeMode(e.target.value)}
                      value="video"
                      type="radio"
                      name="attendee"
                      defaultChecked
                    />
                    <span className="radio-btn"></span>
                    <span className="radio-img video"></span>
                    <span className="radio-msg">Video Call : join with video call</span>
                  </label>
                  <br />
                  <label className="radio">
                    <input
                      onChange={(e) => setAttendeeMode(e.target.value)}
                      value="audio-only"
                      type="radio"
                      name="attendee"
                    />
                    <span className="radio-btn"></span>
                    <span className="radio-img audio"></span>
                    <span className="radio-msg">Audio-only : join with audio call</span>
                  </label>
                  <br />
                  <label className="radio">
                    <input
                      onChange={(e) => setAttendeeMode(e.target.value)}
                      value="audience"
                      type="radio"
                      name="attendee"
                    />
                    <span className="radio-btn"></span>
                    <span className="radio-img audience"></span>
                    <span className="radio-msg">Audience : join as an audience</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="button">
            <ButtonBase className="mt-3" onClick={handleJoin}>Begin Interview 1</ButtonBase>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Index;