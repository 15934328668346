import styled from '@emotion/styled';
import React, { useState, useRef, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { ActionButton2, Button, Divider, Heading, Icon } from '../../common';
import time from '../../images/time.svg';
import editGreem from '../../images/edit-green.svg';
import editIcon from '../../images/editDisable.svg';
import deleteGreen from '../../images/delete-green.svg';
import playLeft from '../../images/play-left.svg';
import playRight from '../../images/play-right.svg';
import playPause from '../../images/playPause.svg';
import whiteDropdown from '../../images/white-dropdown.svg';
import EditingTag from './EditingTagVideo';
import VideoTimeline from './VideoTimeline';
import { ErrorMessage, TaskMenu } from '../../components/form';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector, connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { GET_RECORD_TASK_VIDEO, RESET_TASK_VIDEO } from '../../constants/field/GetRecordTaskVideoConstants';
import Accordion2 from '../../common/Accordion2';
import CreateClipTooltip from '../../common/CreateClipTooltip';
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RescheduleToast.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'; 

const moment = extendMoment(Moment);
const Container = styled.div`
  /* Colour/Dark Grey */
  // height: 500px;
  width: 100%;
  background: var(--darkGrey);
  border-radius: 5px;
  padding: 1rem 0;
  padding-bottom: 0;
`;

const CustomIcon = styled(Icon)`
  color: var(--mediumGrey);
  font-size: 18px;
`;

const Slider = styled.input`
  /* The slider itself */
  margin: 0 1rem;

  appearance: none;
  -webkit-appearance: none;
  width: 100%; /* Full-width */
  height: 4px; /* Specified height */
  background: var(--sliderColor); /* Grey background */
  outline: none; /* Remove outline */
  // opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;

  /* Mouse-over effects */
  &:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }

  &::-webkit-progress-value {
    background-color: green;
    height: 1em;
  }
  &::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;

    margin-top: -1px;
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 8px; /* Set a specific slider handle width */
    height: 8px; /* Slider handle height */
    border-radius: 4px;
    background: var(--sliderThumbColor); /* Green background */

    cursor: pointer; /* Cursor on hover */
  }
`;

const BottomContainer = styled.div`
  background: #1f1f1f;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .add_new {
    padding: 0rem 1rem;
    padding-right: 2rem;
    border-right: 1px solid var(--darkGrey);
  }
  .end {
    padding: 0 2rem;
    img {
      padding-right: 1rem;
    }
  }
  .edit_time {
    border-right: 1px solid var(--darkGrey);
    padding: 0 2rem;
    img {
      padding-right: 1rem;
    }
    button {
      margin: 0 0.5rem;
    }
  }
`;

const CustomButton = styled(Button)`
  color: ${props => (props.disabled ? 'var(--lightGrey)' : props.buttonColor)};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '2px')};

  &:focus {
    background: transparent !important;
    &:hover {
      color: var(--white);
    }
  }
  &:hover {
    color: var(--quadColor);
    background: transparent !important;
    box-shadow: none;
  }
  &.time {
    background: #101010;
    border: 0.5px solid #8a8a8a;
  }

  :disabled,
  [disabled] {
    color: var(--mediumGrey);
    border: 1px solid var(--mediumGrey);
    background: transparent;
    :hover {
      box-shadow: none;
      background: transparent;
      color: var(--mediumGrey);
      cursor: not-allowed;
    }
  }
`;

const PrimaryButton = styled(Button)`
  padding: 2px 4px;
  &:hover {
    color: var(--darkGrey);
    span {
      color: var(--darkGrey);
    }
  }
  &:focus {
    bakground: red;
    box-shadow: none;
    background: transparent !important;
    color: var(--middleGreen);

    :hover {
      background: var(--middleGreen) !important;
      color: var(--darkGrey);
      span {
        color: var(--darkGrey);
      }
    }
  }

  &.time {
    color: var(--middleGrey);
    background: #101010;
    border: 0.5px solid #8a8a8a;
  }

  :disabled,
  [disabled] {
    color: var(--mediumGrey);
    border: 1px solid var(--mediumGrey);
    background: transparent;
    span {
      color: var(--mediumGrey);
    }
    :hover {
      box-shadow: none;
      background: transparent;
      color: var(--mediumGrey);
      cursor: not-allowed;
    }
  }
`;

const AccordionItem = ({
  title,
  time,
  clickFunction,
  id,
  ItemActive,
  setItemActive,
  userId,
  project_ID,
  projectRespondent_ID,
  clipIndex
}) => {
  const dispatch = useDispatch();
  const getUpdatedPreEditorData = () => {
    dispatch({
      type: 'REQUEST_PRE_EDITOR_DATA',
      payload: {
        user_ID: userId,
        project_ID: project_ID,
        projectRespondent_ID: projectRespondent_ID
      }
    });
  };
  return (
    <div
      onClick={e => {

        clickFunction(title + clipIndex);
        setItemActive(id);
      }}
      className={`d-flex align-items-center accordion_item_container ${id == ItemActive && 'item_active'}`}
      id={title + clipIndex}
    >
      <div className="pl-2" style={{ width: '10%' }}>
        <img src={drag} />
      </div>
      <li className="accordion_item" style={{ width: '80%' }}>
        <div className="title">{title}</div>
        <div className="time">{time}</div>
      </li>
      <div className="" style={{ width: '10%' }}>
        <img
          src={deleteImg}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch({ type: 'REQUEST_DELETEVIDEOCLIP', payload: { user_ID: userId, clip_ID: id } });
            setTimeout(
              /*Here it is in settimeout because it takes sometime to update values and the fetch*/
              () => getUpdatedPreEditorData(),
              500
            );
          }}
        />
      </div>
    </div>
  );
};

const VideoAcionButtons = () => {
  return (
    <div className="d-flex justify-content-around" style={{ width: '290px' }}>
      <CustomIcon outlined={false}>fast_rewind</CustomIcon>
      <CustomIcon outlined={false}>skip_previous</CustomIcon>
      <CustomIcon outlined={false}>play_arrow</CustomIcon>
      <CustomIcon outlined={false}>skip_next</CustomIcon>
      <CustomIcon outlined={false}>fast_forward</CustomIcon>
    </div>
  );
};

const RightSideContainer = styled.div`
  text-align: right;
  padding-right: 1rem;
`;

const NoteContainer = styled.div`
  background: var(--notebackground);
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: left;
  padding: 0.65rem 1rem;
  padding-bottom: 0.25rem;
  margin: 1rem 0;
  img {
    margin-left: 0.5rem;
  }
`;

const TextArea = styled.textarea`
  border: 0;
  background: transparent;
  resize: none;
  font-size: 11px;
  font-weight: 500;
  color: var(--greyBorder);
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  &:focus-visible {
    border: 0;

    outline: none;
  }
`;

const IconCustom = styled(Icon)`
  font-size: 0.75rem;
  margin-top: 5px;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  border-bottom: ${props => (props.disabled ? '1px solid transparent' : '1px solid var(--middleGreen)')};
  textarea {
    min-width: 200px;
  }
  span {
    align-self: flex-end;
    margin-bottom: 0.5rem;
  }
  .icon {
    cursor: pointer;
  }
`;

const NoteListContainer = styled.div`
  height: 150px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CustomInput = styled(InputMask)`
  background: #101010;
  color: ${props => (props.disabled ? 'var(--middleGrey)' : 'var(--middleGreen)')};
  font-style: normal;
  font-weight: bold;
  text-align: center;
  margin: 0 5px;
  font-size: 10px;
  width: 70px;
  border: none;
  padding: 2px 0;
  border: 0.5px solid #8a8a8a;
  box-sizing: border-box;
  border-radius: 10px;
  &:hover,
  &:focus {
    outline: none;
  }
`;

const TagButton = styled(CustomButton)`
  border: none;
  display: flex;
  align-items: center;
  color: #8a8a8a;
  padding: 4px 8px;

  span {
    font-size: 17px;
    color: #8a8a8a;
    padding-right: 3px;
  }
`;

const BtnAssignGroup = styled.a`
  background: transparent none repeat scroll 0% 0%;
  border: 0px none;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--quadColor);
  padding: 0.25rem;
  text-align: center;
`;

const EditInputForm = ({
  submit,
  value,
  disabled,
  className,
  playerRef,
  clipDetails,
  setSelectedGuideNotes,
  enableDisableNoteEdit,
  initialNote,
  setInitialNote,
  noteUpdated,
  tagList
}) => {
  const [text, setText] = useState(value);
  const noteForm = useRef();
  const dispatch = useDispatch();
  const loggedUser = useSelector(state => state.LoginReducer);

  const handleSubmit = e => {
    e.preventDefault();
    const data1 = {
      user_ID: loggedUser.userId,
      clip_ID: clipDetails.clip_ID,
      videoNote: noteForm.current.value
    };
    dispatch({ type: 'REQUEST_ADDVIDEONOTE', payload: data1 });
    noteUpdated(clipDetails, noteForm.current.value);
    submit();
    enableDisableNoteEdit();
    // setText('');
  };

  // const handleSubmitForCreate = e => {
  //   e.preventDefault();
  //   const updatedGuideNotes = { ...clipDetails, videoNote: initialNote };
  //   setSelectedGuideNotes(updatedGuideNotes);
  //   enableDisableNoteEdit();
  // };
  useEffect(() => {
    noteForm.current.value = clipDetails.videoNote ? clipDetails.videoNote : '';
  }, [clipDetails]);

  return (
    <div>
  <FormContainer className={`${className} ${!disabled && 'mb-2'}`} disabled={disabled}>
        <form onSubmit={handleSubmit} style={{ width: '90%' }}>
          <TextArea
            disabled={disabled}
            defaultValue={value}
            rows={!disabled && 1}
            placeholder="Add a new note"
            onChange={e => setText(e.target.value)}
            ref={noteForm}
          />
        </form>
        {!disabled && (
          <div onClick={handleSubmit} className="icon">
            <IconCustom color="var(--sapGreen)" style={{ height: '8px' }}>
              subdirectory_arrow_left
            </IconCustom>
          </div>
        )}
      </FormContainer>
      {/* )} */}
    </div>
  );
};


const notes = [
  {
    id: 1,
    value: 'Hairdryer too heavy to use for longer duration',
    edit: false
  }
];
const RightContainer = ({
  playerRef,
  selectedSideTab,
  tagList,
  selectedGuideNotes,
  setSelectedGuideNotes,
  enableNoteEdit,
  enableDisableNoteEdit,
  noteUpdated,
  projectId,
  selectedRespondantVideoId,
  setIsSaveBtnDisabled,
  initialNote,
  setInitialNote,
  setInitialTag
}) => {
  // const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [noteValue, setNoteValue] = useState('');
  const [noteTime, setNoteTime] = useState();
  const [addNewNote, setAddNewNote] = useState(false);
  const loggedUser = useSelector(state => state.LoginReducer);
  const [guideNotes, setGuideNotes] = useState(selectedGuideNotes);
  const [SelectedSideTab, setSelectedSideTab] = useState('');
  const handleAddNote = () => {
    setAddNewNote(true);
    enableDisableNoteEdit();
  };
  console.log("guideNotes", guideNotes)


  useEffect(() => {
    setAddNewNote(false);
    setGuideNotes(selectedGuideNotes);
  }, [selectedGuideNotes, selectedSideTab]);

  const handleEdit = editID => {
    /*tempNotes.map(item => {
      if (item.id == editID) {
      item.edit = !item.edit;
	
      return { ...item };
      }
      return { ...item };
    });
	
    setNotesList([...tempNotes]);*/
  };

  // if(!guideNotes.videoNote || guideNotes.Tag.length === 0){ setIsSaveBtnDisabled(true)}

  const Options = tagList.map((tag, index) => {
    return { value: tag.tad_ID, label: tag.tag };
  });

  return (
    <RightSideContainer>
     {guideNotes && !guideNotes.videoNote && (
        <PrimaryButton
          fontWeight="700"
          fontSize="10px"
          size="small"
          variant="outlined"
          className="px-0 py-0 pr-1  d-inline-flex align-items-center "
          borderRadius="3px"
          buttonColor="var(--middleGreen)"
          onClick={handleAddNote}
        >
          <Icon style={{ fontSize: '16px', marginRight: '3px' }} color="var(--middleGreen)">
            add
          </Icon>{' '}
          Add Note
        </PrimaryButton>
      )}
      {guideNotes?.videoNote && (
        <NoteListContainer>
          <NoteContainer key={guideNotes.id}>
            <div className="d-flex align-items-center justify-content-between">
              <Heading type={3} fontSize="9px" fontWeight="500" color="var(--middleGrey)" className="mb-0 pb-0">
                Note
              </Heading>
              <div className="d-flex align-items-center">
                <img
                  src={guideNotes.edit ? editIcon : editGreem}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    enableDisableNoteEdit();
                  }}
                />
                <img
                  style={{ cursor: 'pointer' }}
                  src={deleteGreen}
                  onClick={
                    () => {
                      // if (guideNotes.clip_ID === 0) {
                      //   setInitialNote(null);
                      //   const updatedGuideNotes = { ...guideNotes, videoNote: null };
                      //   setSelectedGuideNotes(updatedGuideNotes);
                      // } else {
                      dispatch({
                        type: 'REQUEST_DELETEVIDEONOTE',
                        payload: { user_ID: loggedUser.userId, videoNotes_ID: selectedGuideNotes.clip_ID.toString() }
                      });
                      // setNoteValid(false);
                      setIsSaveBtnDisabled(true);
                      noteUpdated(guideNotes, null, 'delete');
                    }
                    // }
                  }
                />
              </div>
            </div>
            <EditInputForm
              className="mb-0 pb-0 mt-2"
              disabled={enableNoteEdit}
              value={guideNotes.videoNote}
              clipDetails={guideNotes}
              setSelectedGuideNotes={setSelectedGuideNotes}
              enableDisableNoteEdit={enableDisableNoteEdit}
              noteUpdated={noteUpdated}
              initialNote={initialNote}
              setInitialNote={setInitialNote}
              submit={() => {
                handleEdit(guideNotes.question_ID);
              }}
              playerRef={playerRef}
            />
          </NoteContainer>
        </NoteListContainer>
      )}
      {addNewNote && !guideNotes?.videoNote && (
        <NoteListContainer>
          <NoteContainer key={guideNotes.id}>
            <div className="d-flex align-items-center justify-content-between">
              <Heading type={3} fontSize="9px" fontWeight="500" color="var(--middleGrey)" className="mb-0 pb-0">
                Note
              </Heading>
              {/*<div className="d-flex align-items-center">
									<img
										src={selectedGuideNotes.edit ? editIcon : editGreem}
										onClick={() => {
											enableDisableNoteEdit();
										}}
									/>
									<img src={deleteGreen} />
								</div>*/}
            </div>
            <EditInputForm
              className="mb-0 pb-0 mt-2"
              disabled={enableNoteEdit}
              value={guideNotes.videoNote}
              clipDetails={guideNotes}
              setSelectedGuideNotes={setSelectedGuideNotes}
              enableDisableNoteEdit={enableDisableNoteEdit}
              noteUpdated={noteUpdated}
              initialNote={initialNote}
              setInitialNote={setInitialNote}
              submit={() => {
                handleEdit(guideNotes.question_ID);
              }}
              playerRef={playerRef}
            />
          </NoteContainer>
        </NoteListContainer>
      )}
      {guideNotes?.clip_ID != undefined && (
        <>
          <Divider width="100%" className="m-0 p-0 mt-2" height="3px" background="var(--notebackground)" />
          <div className="pt-2">
            <EditingTag
              singleValueStyle={{
                fontSize: '10px',
                fontWeight: '700',
                color: 'var(--greyBlue)'
              }}
              selectedSideTab={selectedSideTab}
              tagColor="var(--greyBlue)"
              addTagButtonColor="#8A8A8A"
              tagList={guideNotes.Tag}
              clipDetails={guideNotes}
              setSelectedGuideNotes={setSelectedGuideNotes}
              clipId={guideNotes.clip_ID}
              Options={Options}
              projectId={projectId}
              selectedRespondantVideoId={selectedRespondantVideoId}
              AddTagButton={() => (
                <TagButton
                  fontSize="12px"
                  fontWeight="300"
                  variant="outlined"
                  buttonColor="#8A8A8A"
                  color="#8A8A8A"
                  // className='ml-3'
                >
                  <Icon>add</Icon>Add Tag
                </TagButton>
              )}
            />
          </div>
        </>
      )}
    </RightSideContainer>
  );
};
function VideoEdit({
  moveSeekTo,
  selectedSideTab,
  endTime,
  playing,
  setPlaying,
  tagList,
  enableNoteEdit,
  clipName,
  setClipNameError,
  setClipName,
  handleClipNameError,
  createClip,
  clipCreated,
  enableDisableNoteEdit,
  noteUpdated,
  captureGuideTimes,
  selectedGuideNotes,
  setSelectedGuideNotes,
  isCreateClip,
  projectRepondentVideo_ID,
  loggedUserId,
  projectRespondent_ID,
  video,
  selectedRespondantVideoId,
  getUpdatedPreEditorData,
  editClipResponse,
  projectId,
  clipTimeError,
  passClipTimeErrorForEdit,
  passSectionMessage,
  passSaveButtonClicked
}) {
  const [value, setValue] = useState('0');
  const playerRef = useRef();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.LoginReducer.userId);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isCreateClipDisabled, setIsCreateClipDisabled] = useState(false);
  const [startClipTime, setStartClipTime] = useState('00:00:00');
  const [endClipTime, setEndClipTime] = useState('00:00:00');
  const [videoUrl, setVideoUrl] = useState('');
  const [currentPlayedSecond, setCurrentPlayedSecond] = useState('');
  const [startTimeOfClipForNew, setStartTimeOfClipForNew] = useState('');
  const [startTimeOfClip, setStartTimeOfClip] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupColor, setPopupColor] = useState('');
  const [sectionmessage, setSectionMessage] = useState(null);
  const [initialTag, setInitialTag] = useState([]);
  const [initialNote, setInitialNote] = useState(null);
  const saveclipStatus = useSelector(state => state.AddVideoTrimReducer?.saveClip);
  const editClipStatus = useSelector(state => state.EditClipReducer?.editClip);
  const deleteVideoclipStatus = useSelector(state => state.DeleteVideoClipReducer.deleteVideoclipStatus);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [keyboardInput, setKeyboardInput] = useState(false);
  const [videoEditRef, setVideoEditRef] = useState();
  const [videoEditStartTime, setVideoEditStartTime] = useState();
  const [videoEditEndTime, setVideoEditEndTime] = useState();
  const [singlePointDifference, setSinglePointDifference] = useState();
  const [videoEditGuideTimings, setVideoEditGuideTimings] = useState([]);
  const [clipTimeErrorForEdit, setClipTimeErrorForEdit] = useState(null);
  const [videoEditPrevClipData, setVideoEditPrevClipData] = useState();
  const [videoEditNextClipData, setVideoEditNextClipData] = useState();
  const [videoeditTimeblocksArray, setVideoeditTimeblocksArray] = useState([]);
  const [videoEditTimelineRef, setVideoEditTimelineRef] = useState();
  const prevclipStartTime = videoEditPrevClipData?.startTime;
  const nextclipEndTime = videoEditNextClipData?.endTime;
 
  passClipTimeErrorForEdit(clipTimeErrorForEdit);
  passSectionMessage(sectionmessage);
  passSaveButtonClicked(saveButtonClicked);

  useEffect(() => {
    dispatch({
      type: GET_RECORD_TASK_VIDEO,
      payload: {
        user_ID: userId,
        project_ID: selectedRespondantVideoId
      }
    });
  }, [selectedRespondantVideoId]);

  // const videoUrl = useSelector(state => state.GetRecordTaskVideoReducer.recordedVideo);
  useEffect(() => {
    setVideoUrl(video);
  }, [video]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_TASK_VIDEO });
    };
  }, []);

  useEffect(() => {
    moveSeekTo ? handleSeekChange() : null;
    setStartTimeOfClip(moveSeekTo);
  }, [moveSeekTo, endTime]);

  useEffect(() => {
    const panel = videoEditRef;
    // console.log("panel",panel)
    if (panel && keyboardInput) {
      if (isCreateClip) {
        let duration =
          moment(endClipTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(startClipTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
        let duration1 =
          moment(videoEditEndTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(startClipTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
        let formattedEndTime = modifyByOneSec(videoEditEndTime, true);
        let formattedDuration =
          moment(formattedEndTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(startClipTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
        if (duration < duration1) panel.style.width = `${duration * singlePointDifference}px`;
        else {
          panel.style.width = `${formattedDuration * singlePointDifference}px`;
      
        }

        let durationLeft =
          moment(startClipTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(videoEditStartTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
        if (durationLeft < 0) {
          if (duration < duration1) panel.style.width = `${(duration + durationLeft) * singlePointDifference}px`;
          else panel.style.width = `${(formattedDuration + durationLeft) * singlePointDifference}px`;
          durationLeft = 1;
          panel.style.left = `${durationLeft * singlePointDifference}px`;
        } else {
          
          panel.style.left = `${durationLeft * singlePointDifference}px`;
        }
      } else {
    
        let duration =
          moment(endClipTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(videoEditStartTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
     
        let duration1 =
          moment(nextclipEndTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(videoEditStartTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
        let formattedEndTime = modifyByOneSec(nextclipEndTime, true);
        let formattedDuration =
          moment(formattedEndTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(videoEditStartTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
        if (duration < duration1) panel.style.width = `${duration * singlePointDifference}px`;
        else {
          panel.style.width = `${formattedDuration * singlePointDifference}px`;
   
        }

        let durationLeft =
          moment(startClipTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(videoEditStartTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
        let durationLeftEdit =
          moment(prevclipStartTime, [moment.ISO_8601, 'HH:mm:ss']).diff(
            moment(videoEditStartTime, [moment.ISO_8601, 'HH:mm:ss'])
          ) / 1000;
        if (durationLeft < durationLeftEdit) {
          durationLeft = durationLeftEdit + 1;
        }
        if (duration < duration1) panel.style.width = `${(duration + durationLeft * -1) * singlePointDifference}px`;
        else panel.style.width = `${(formattedDuration + durationLeft * -1) * singlePointDifference}px`;
        panel.style.left = `${durationLeft * singlePointDifference}px`;
      }

      setKeyboardInput(false);
    }
  }, [startClipTime, endClipTime]);

  useEffect(() => {
    const ind = videoeditTimeblocksArray.findIndex(obj => {
      const [hours, minutes, seconds] = obj?.endTime.split(':').map(Number);
      let totalSecondsEnd = hours * 3600 + minutes * 60 + seconds;
      return totalSecondsEnd >= currentPlayedSecond;
    });
    if (videoeditTimeblocksArray[ind]?.active) {
      setIsCreateClipDisabled(true);
    } else setIsCreateClipDisabled(false);
  }, [currentPlayedSecond]);

  useEffect(() => {
    if (deleteVideoclipStatus == 1) {
      setIsCreateClipDisabled(false);
    }
  }, [deleteVideoclipStatus]);

  useEffect(() => {
    setIsCreateClipDisabled(isCreateClipDisabled);
  }, [isCreateClipDisabled]);

  useEffect(() => {}, [playing]);

  const initialLoad = useRef(true);
  const initialLoadforEdit = useRef(true);
 
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }
  
    if (saveclipStatus) {
      const toastId = "saveclip-toast";
      if (saveclipStatus.StatusCode === '-1') {
        toast.error("Clip Upload Failed.", {
          toastId,
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else if (saveclipStatus.StatusCode === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "var(--primaryColor)", marginRight: '8px', fontSize: '1.5em' }} />
            <span>{"Clip Added Successfully!"}</span>
          </div>,
          {
            toastId,
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            icon: false,
            transition: Bounce,
          }
        );
      }
  
      setTimeout(() => {
        dispatch({ type: 'CLEAR_VIDEOTRIM' });
      }, 4500);
    }
  }, [saveclipStatus]);
  
  useEffect(() => {
    if (initialLoadforEdit.current) {
      initialLoadforEdit.current = false;
      return;
    }
  
    if (editClipStatus) {
      const toastId = "editclip-toast";
      if (editClipStatus === '-1') {
        toast.error("An error occurred while editing the clip. Please try again.", {
          toastId,
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else if (editClipStatus === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "var(--primaryColor)", marginRight: '8px', fontSize: '1.5em' }} />
            <span>{"Clip Edited Successfully!"}</span>
          </div>,
          {
            toastId,
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            icon: false,
            transition: Bounce,
          }
        );
      }
  
      setTimeout(() => {
        dispatch({ type: 'CLEAR_EDITCLIP' });
      }, 4500);
    }
  }, [editClipStatus]);
  


  useEffect(() => {
    if (saveclipStatus) {
        setSaveButtonClicked(false); 
    }
}, [saveclipStatus]);


useEffect(() => {
  if (editClipStatus) {
      setSaveButtonClicked(false); 
  }
}, [editClipStatus]);
  
 

  useEffect(() => {
    setTimeout(() => {
      setSaveButtonClicked(false);
    }, 3000);
  }, [clipTimeError, clipTimeErrorForEdit, sectionmessage]);

  useEffect(() => {
    setTimeout(() => {
      setClipTimeErrorForEdit(null);
    }, 3000);
  }, [clipTimeErrorForEdit]);

  const handleSeekChange = () => {
    // setGlobalState({ ...globalState, played: parseFloat(e.target.value), seeking: true });
    var a = moveSeekTo.split(':');
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    playerRef.current.seekTo(seconds, 'seconds');
    setPlaying(true);
  };

  const handleProgress = e => {
    const timeInMin = new Date(e.playedSeconds * 1000).toISOString().substr(11, 8);
    const integerTime = Math.floor(e.playedSeconds);
    setCurrentPlayedSecond(integerTime);
    if (endTime) {
      var a = endTime.split(':');

      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      if (e.playedSeconds >= seconds) {
        setPlaying(false);
      }
    }
  };

  const handlePause = e => {
    const currentTime = Math.floor(playerRef.current.getCurrentTime());
  

    setCurrentPlayedSecond(currentTime);
  }

  const handleClipTime = (field, value) => {
    if (field == 'startClipTime') {
      setStartClipTime(value);
    } else {
      setEndClipTime(value);
    }
  };


  const [getSectionId, setGetSectionId] = useState(null);
  const [getQuestion_ID, setGetQuestion_ID] = useState(null);

  function modifyByOneSec(timeString, isEnd) {
    const [hours, minutes, seconds] = timeString?.split(':').map(Number);
    let totalSeconds = hours * 3600 + minutes * 60 + seconds;
    if (isEnd) totalSeconds -= 1;
    else totalSeconds += 1;
    const newHours = Math.floor(totalSeconds / 3600);
    const newMinutes = Math.floor((totalSeconds % 3600) / 60);
    const newSeconds = totalSeconds % 60;

    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(
      2,
      '0'
    )}`;
  }

  const compareTimeDifference = (timeString1, timeString2) => {
    let distance1 =
      moment(timeString1, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss'])) / 1000;
    let distance2 =
      moment(timeString2, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss'])) / 1000;
    return distance1 > distance2;
  };

  const saveClip = () => {
    clipCreated(startClipTime, endClipTime, getSectionId, getQuestion_ID);
    setGetSectionId(null);
    setGetQuestion_ID(null);
    setStartClipTime('00:00:00');
    setEndClipTime('00:00:00');
    createClip(false);
  };

  const captureGuideData = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
  // console.log('VideoEdit', captureGuideData.captureGuide[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const handleActionButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const [activeIndex, setActiveIndex] = useState();

  const handleAccordionClick = index => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const handleMouseEnter = index => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  // written for resize
  // const handleVideoEditRef = (ref) =>{
  //   setVideoEditRef(ref);
  // }

  // const handleVideoEditStartTime = (stime) =>{
  //   setVideoEditStartTime(stime);
  // }

  // const handleSinglePointDifference = (singlePoint) =>{
  //   setSinglePointDifference(singlePoint);
  // }

  const handleVideoEditRef = ref => {
    setVideoEditRef(ref);
  };

  const handleVideoEditStartTime = stime => {
    setVideoEditStartTime(stime);
  };

  const handleVideoEditEndTime = etime => {
    setVideoEditEndTime(etime);
  };

  const handleSinglePointDifference = singlePoint => {
    setSinglePointDifference(singlePoint);
  };

  const handleVideoEditGuideTimings = gtimings => {
    setVideoEditGuideTimings(gtimings);
  };

  const handleVideoEditPrevClipData = pdata => {
    setVideoEditPrevClipData(pdata);
  };

  const handleVideoEditNextClipData = ndata => {
    setVideoEditNextClipData(ndata);
  };

  const handleClipTimeErrorForEdit = (startTime, endTime) => {
    if (!prevclipStartTime || !nextclipEndTime) {
      setClipTimeErrorForEdit(null);
      return false;
    } else if (!compareTimeDifference(startTime, prevclipStartTime)) {
      if (prevclipStartTime === '00:00:00') {
      } else {
        setClipTimeErrorForEdit('Time range error');
        return true;
      }
    } else if (compareTimeDifference(endTime, nextclipEndTime)) {
      setClipTimeErrorForEdit('Time range error');
      return true;
    }
    setClipTimeErrorForEdit(null);
    return false;
  };

  const handleTimeBlocksArray = arr => {
    setVideoeditTimeblocksArray(arr);
  };

  const handleTimelineRef = ref => {
    setVideoEditTimelineRef(ref);
  };

  const accordion_item = {
    border: hoveredIndex ? '1px solid var(--lightGreen)' : null,
    background: hoveredIndex ? 'var(--white)' : null,
    borderRadius: hoveredIndex ? '15px' : null,
    color: hoveredIndex ? 'var(--deepBlue)' : null,
    textAlign: hoveredIndex ? 'left' : null,
    cursor: hoveredIndex ? 'pointer' : null
  };

  return (
    <Container>
      <div className="row">
        <div 
          className="col-md-8 "
        >
          <ReactPlayer
            ref={playerRef}
            width="100%"
            height="320px"
            url={videoUrl}
            //   pip={pip}
            playing={playing}
            controls={true}
            //   light={light}
            //   playbackRate={playbackRate}
            //   loop={loop}
            //   volume={volume}
            //   muted={muted}
            //   onReady={() => console.log('onReady')}
            //   onStart={() => console.log('onStart')}
            //   onPlay={handlePlay}
            //   onEnablePIP={handleEnablePIP}
            //   onDisablePIP={handleDisablePIP}
            //   onPause={handlePause}
            //   onBuffer={() => console.log('onBuffer')}
            onSeek={e => {
              // console.log(new Date(e * 1000).toISOString().substr(11, 8));
              // let minutes = e / 60;
              // let minutesString = minutes.toString();
              // let minArr = minutesString.split('.');
              // let mins = minArr[0];
              // let secToMin = parseInt(parseFloat(`.${minArr[1]}`) * 60);
              // let minAndSec = secToMin.toString().length == 1 ? `${mins}:0${secToMin}` : `${mins}:${secToMin}`;
              // console.log(minAndSec);
            }}
            //   onError={e => console.log('onError', e)}
            //   onDuration={handleDuration}
            onPause={handlePause}
            onProgress={handleProgress}
            //   stopOnUnmount={false}
          />
          {/* <img
            style={{
              marginLeft: "1rem",
              paddingRight: "1.2rem",
              width: "inherit",
            }}
            src={editVideo}
            className="img-fluid"
          />
          <div className="py-2 d-flex align-items-center mx-2 px-2">
            <VideoAcionButtons />

            <Slider
              type="range"
              min="1"
              max="100"
              id="myRange"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <Heading
              type={3}
              fontSize="10px"
              fontWeight="500"
              color="var(--middleGrey)"
              className="mb-0 pb-0"
            >
              00:00:00
            </Heading>
          </div> */}
        </div>
        <div className="col-md-4">
          <RightContainer
            selectedSideTab={selectedSideTab}
            playerRef={playerRef}
            tagList={tagList}
            selectedGuideNotes={selectedGuideNotes}
            setSelectedGuideNotes={setSelectedGuideNotes}
            enableNoteEdit={enableNoteEdit}
            enableDisableNoteEdit={enableDisableNoteEdit}
            noteUpdated={noteUpdated}
            projectId={projectId}
            selectedRespondantVideoId={selectedRespondantVideoId}
            setIsSaveBtnDisabled={setIsSaveBtnDisabled}
            initialNote={initialNote}
            setInitialTag={setInitialTag}
            setInitialNote={setInitialNote}
          />
        </div>
      </div>
      <div
        className="time-hold-container"
        style={{
          background: ' #1f1f1f',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px'
        }}
      >
        <VideoTimeline
          playerRef={playerRef}
          selectedGuideNotes={selectedGuideNotes}
          captureGuideTimes={captureGuideTimes}
          setStartClipTime={setStartClipTime}
          startClipTime={startClipTime}
          setEndClipTime={setEndClipTime}
          isCreateClip={isCreateClip}
          createClip={createClip}
          setPlaying={setPlaying}
          selectedRespondantVideoId={selectedRespondantVideoId}
          videoUrl={videoUrl}
          currentPlayedSecond={currentPlayedSecond}
          startTimeOfClip={startTimeOfClip}
          setStartTimeOfClip={setStartTimeOfClip}
          startTimeOfClipForNew={startTimeOfClipForNew}
          setIsSaveBtnDisabled={setIsSaveBtnDisabled}
          passReferenceToVideoEdit={handleVideoEditRef}
          passStartTimeToVideoEdit={handleVideoEditStartTime}
          passEndTimeToVideoEdit={handleVideoEditEndTime}
          passSinglePointDifference={handleSinglePointDifference}
          passGuideTimings={handleVideoEditGuideTimings}
          passPrevClipDatatoVideoEdit={handleVideoEditPrevClipData}
          passNextClipDatatoVideoEdit={handleVideoEditNextClipData}
          passTimeBlocksArray={handleTimeBlocksArray}
          passTimelineRef={handleTimelineRef}
        />
        <BottomContainer>
          <div className="add_new">
            <CreateClipTooltip
              left="-10px"
              top="-30px"
              direction="bottom"
              information="A clip already exist on this timestamp"
              disabled={isCreateClipDisabled}
            >
              <ActionButton2
                fontWeight="500"
                fontSize="9px"
                size="small"
                variant="outlined"
                buttonColor="var(--lightGrey)"
                color="var(--lightGrey)"
                normalBtn={true}
                disable={isCreateClipDisabled}
                // left="6.5%"
                bottom="155%"
                isOpenpopup={isOpen}
                onActionButtonClick={handleActionButtonClick}
              >
                Create New <img src={whiteDropdown} alt="White Dropdown" />
                <TaskMenu className="task-menu" width="150px" background="#f2f4f4 !important">
                  <div className="sc">
                    {captureGuideData &&
                      captureGuideData.captureGuide &&
                      captureGuideData.captureGuide.map((data, index) => {
                        return (
                          <>
                            <Accordion2
                              className=" py-1 "
                              title={data?.sectionName}
                              index={index}
                              isActive={activeIndex === index}
                              onClick={() => handleAccordionClick(index)}
                            >
                              <ul>
                                {data?.questionName_array.map((value, clipIndex) => {
                                  const isHovered = hoveredIndex === clipIndex + 1;
                                  const liStyles = isHovered ? accordion_item : {};
                                  return value?.buttonName ? (
                                    <li
                                      className="pl-3 recording_button"
                                      index={clipIndex + 1}
                                      style={liStyles}
                                      onMouseEnter={() => handleMouseEnter(clipIndex + 1)}
                                      onMouseLeave={() => handleMouseLeave()}
                                      onClick={() => {
                                        setGetSectionId(data.questionPaperSection_ID);
                                        setGetQuestion_ID(value?.question_ID);
                                        setClipNameError(null);
                                        if (isCreateClip === true) {
                                          createClip(false);
                                          setTimeout(() => {
                                            createClip(true);
                                          }, 1000);
                                        } else {
                                          createClip(true);
                                        }

                                        setIsSaveBtnDisabled(false);
                                        setIsOpen(false);
                                        setSelectedGuideNotes(null);
                                        setClipName(value?.buttonName);
                                        dispatch({ type: 'CLEAR_DELETEVIDEOCLIP' });
                                      }}
                                      key={clipIndex}
                                    >
                                      {value?.buttonName}
                                    </li>
                                  ) : null;
                                })}
                              </ul>
                            </Accordion2>
                            <Divider background="var(--lightGrey)" height="0.5px" width="100%" margin="0" />
                          </>
                        );
                      })}
                  </div>
                </TaskMenu>
              </ActionButton2>
            </CreateClipTooltip>
          </div>
          <div className="d-flex align-items-center edit_time">
            <img src={time} />
            <Heading type={3} fontSize="10px" fontWeight="400" color="var(--middleGrey)" className="mb-0 pb-0">
              Edit:
            </Heading>

            <CustomInput
              value={startClipTime}
              onInput={e => {
                setKeyboardInput(true);
                handleClipTime('startClipTime', e.target.value);
              }}
            ></CustomInput>
            <Heading type={3} fontSize="10px" fontWeight="400" color="var(--middleGrey)" className="mb-0 pb-0">
              to
            </Heading>
            <CustomInput
              value={endClipTime}
              onInput={e => {
                setKeyboardInput(true);
                handleClipTime('endClipTime', e.target.value);
              }}
            ></CustomInput>
            <PrimaryButton
              fontWeight="700"
              fontSize="10px"
              size="small"
              variant="outlined"
              className="px-3"
              borderRadius="3px"
              buttonColor="var(--middleGreen)"
              disabled={isSaveBtnDisabled}
              onClick={() => {
                let error;
                if (isCreateClip) {
                  error = handleClipNameError(clipName);
                } else {
                  error = handleClipNameError(selectedGuideNotes?.buttonName);
                }
                if (isCreateClip && !error) {
                  saveClip();
                  setStartClipTime('00:00:00');
                  setEndClipTime('00:00:00');
                  setIsSaveBtnDisabled(true);
                  setSaveButtonClicked(true);
                } else if (!error) {
                  let error1 = handleClipTimeErrorForEdit(startClipTime, endClipTime);
                  if (!error1) {
                    let editData = {
                      user_ID: userId,
                      startTime: startClipTime,
                      endTime: endClipTime,
                      clip_ID: selectedGuideNotes?.clip_ID,
                      question_ID: selectedGuideNotes?.question_ID
                    };
                    let test = dispatch({ type: 'REQUEST_EDITCLIP', payload: editData });
                    setStartClipTime('00:00:00');
                    setEndClipTime('00:00:00');
                    setIsSaveBtnDisabled(true);
                    setSaveButtonClicked(true);
                  }
                }
                // setIsSaveBtnDisabled(false);
              }}
            >
              {saveButtonClicked ? 'Saving...' : 'Save clip'}
            </PrimaryButton>
          </div>
          <div className="d-flex align-items-center end">
            <img src={playLeft} />
            <img src={playPause} />
            <img src={playRight} />
          </div>
        </BottomContainer>
      </div>
      <ToastContainer />  
    </Container>
   
  );

}

const mapStateToProps = state => {
  return {
    // will be available as props.captureData
    video: state.GetRecordTaskVideoReducer.recordedVideo,
    editClipResponse: state.EditClipReducer.editClipResponse
  };
};

// export default VideoEdit;
export default connect(mapStateToProps)(VideoEdit);
