import styled from '@emotion/styled'
import { getInitials, randomColor } from '../helpers';
import profile_user from '../assets/images/profile_user.png';

const IMG = styled.img`
    width: ${props => props.width || '36px'};
    height: ${props => props.height || '36px'};
    border-radius: 50%;
`

const NamedDiv = styled.div`
    width: ${props => props.width+'px' || '36px'};
    height: ${props => props.height+'px' || '36px'};
    font-size: ${props => props.fontSize || '0.9rem'};
    border-radius: 50%;
    background: var(--ternaryColor);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
`

export default function Avatar({src=null, alt="", className="", name=null, width=null, height=null, fontSize=null}) {

    if (!src && !name) {
        return <IMG src={profile_user} alt="Default Avatar" className={className} width={width} height={height} />;
    }

    if(name) {
        return <NamedDiv color={randomColor()} className={className} width={width} height={height} fontSize={fontSize}>{getInitials(name)}</NamedDiv>
    }

    return (
        <IMG src={src} alt={alt} className={className} width={width} height={height}/>
    )
}