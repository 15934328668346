import React, { useState } from 'react';
import { css } from '@emotion/react'
import styled from '@emotion/styled';
import { Text, Icon } from '../../common';
import { startOfWeek, addDays, format, isSameMonth, startOfMonth, endOfMonth, endOfWeek, addMonths, subMonths,compareAsc, isSameDay, compareDesc } from 'date-fns'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const Days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const Container = styled.div`

    .container-fluid {
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .cell {
        text-align: center;
        flex: 1;
        padding: 0.56rem 0.75rem;
        cursor: default;

        &.selected {
			background: var(--seaBlue);
			border-radius: 5px;
			p {
				color: var(--white);
			}
		}

		&.restricted {
			// background: var(--lightGrey);
			opacity: 0.5;
			border-radius: 5px;
			cursor: not-allowed;
			pointer-events: all !important;
		}

        &.active {
            /* background: ${props => props.activeColor || 'var(--seaBlue)'}; */
            
            p {
                color: var(--white);
            }
        }

        &.active.pre {
            background: var(--seaBlue);
        }

        &.active.during {
            background: var(--limeGreen);
        }

        &.active.post {
            background: var(--deepPurple);
        }

        &.start{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &.end{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }
`

const DaysContainer = styled.div`
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

// const Day = styled(Text)`
//     flex: 1;
//     text-align: center;
// `

const Header = styled.div`
    background: var(--darkBorderColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
`

const ArrowStyles = (props) => css`
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    padding: 0.25rem;

    .material-icons {
        color: #898989;
        font-size: 2rem;
    }
`


const Prev = styled.button`
    ${ArrowStyles}
`

const Next = styled.button`
    ${ArrowStyles}
`



export default function DatePicker({ activeColor, type, onEndSelect,selectedDates }) {
    // console.log("type",type);
    const dispatch = useDispatch();
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const userId = useSelector(state => state.LoginReducer.userId);
    const projectId = useSelector(state => state.ProjectReducer.project_ID);
    const durationData = useSelector(state => state.projectReducer.duration_data);

    const [fieldSelection, setFieldSelection] = useState({

        'pre': {

            selectedStartDate: null,
            selectedEndDate: null,
        },
        'during': {
            selectedStartDate: null,
            selectedEndDate: null,
        },
        'post': {
            selectedStartDate: null,
            selectedEndDate: null,
        }
    });

    const data = { user_ID: userId, project_ID: projectId }
    useEffect(() => {

        dispatch({
            type: 'REQUEST_SCHEDULE_DATES',
            payload: data
        });
        // console.log("durationn data", durationData);
    }, []);

    // const [selectedStartDate, setSelectedStartDate] = useState();
    // const [selectedEndDate, setSelectedEndDate] = useState();
    const [tempEndDate, setTempEndDate] = useState();
    const [selection, setSelection] = useState(false);

    const checkActive = ({ cloneDay, type, tempEndDate }) => {
        // console.log("helloo", cloneDay, type, tempEndDate);
        var date1 = new Date(durationData.preFieldDatesStart);
        var date2 = new Date(durationData.preFieldDatesEnd);

        date1 = new Date(date1.setDate(date1.getDate()));
        date2 = new Date(date2.setDate(date2.getDate()));
        // console.log("start date", date1);
        // console.log("end date", date2);
        // if (durationData.preFieldDatesStart != null) {
        //     var type = 'pre';


        //     var Difference_In_Time = date2.getTime() - date1.getTime();
        //     Difference_In_Time = Math.floor(Difference_In_Time / (24 * 60 * 60 * 1000));

        //     console.log("d", Difference_In_Time);
        //     // date = new Date(date.setDate(date.getDate() + 1));
        //     // console.log("dd",date);

        //     // add a day
        //     // while(durationData.preFieldDatesStart <= durationData.preFieldDatesEnd)
        //     // {
        //     //     date.setDate(date.getDate() + 1);
        //     //     console.log(durationData.preFieldDatesStart);
        //     // }
        //     console.log("helloo");
        // }
        // var i = 1;
        let className = '';
        // // console.log("type",type);
        // // console.log("clone day",cloneDay);
        // // console.log("end day",tempEndDate);
        // // console.log("field",fieldSelection['pre'].selectedStartDate);
        // var d1 = new Date(date1);
        // while (i <= Difference_In_Time) {
        //     console.log("d1", d1);
        //     console.log("d2", date2);
        //     if (d1 <= date2) {
        //         className += ' active pre'
        //         console.log("j");
        //     }
        //     d1 = new Date(date1.setDate(date1.getDate() + 1));

        //     i++;
        // }
        
        if(cloneDay >= new Date(durationData.preFieldDatesStart) && cloneDay <= new Date(durationData.preFieldDatesEnd)){
            className += ' active pre'
        }
        
        if(cloneDay >= new Date(durationData.fieldDatesStart) && cloneDay <= new Date(durationData.fieldDatesEnd)){
            className += ' active during'
        }
        
        if(cloneDay >= new Date(durationData.postFieldDatesStart) && cloneDay <= new Date(durationData.postFieldDatesEnd)){
            className += ' active post'
        }
        // ----------------------------------------------------------------------------------------------
        
        if (cloneDay >= fieldSelection['pre'].selectedStartDate?.cloneDay
        && (cloneDay <= fieldSelection['pre'].selectedEndDate?.cloneDay || (selection && type === 'pre' && cloneDay <= tempEndDate?.cloneDay))) {
            className += ' active pre'
        }


        if (cloneDay >= fieldSelection['during'].selectedStartDate?.cloneDay && (cloneDay <= fieldSelection['during'].selectedEndDate?.cloneDay || (selection && type === 'during' && cloneDay <= tempEndDate?.cloneDay))) {
            className += ' active during'
        }
        
       
        
        if (cloneDay >= fieldSelection['post'].selectedStartDate?.cloneDay && (cloneDay <= fieldSelection['post'].selectedEndDate?.cloneDay || (selection && type === 'post' && cloneDay <= tempEndDate?.cloneDay))) {
            className += ' active post';
        }
        
        return className;
    }

    const checkStart = ({ cloneDay, type, cloneFormattedDate, monthStart }) => {
        for (var type in fieldSelection) {
            if (cloneFormattedDate === fieldSelection[type].selectedStartDate?.cloneFormattedDate && !!isSameMonth(cloneDay, monthStart)) {
                return 'start'
            }
        }

        return '';
    }

    const checkEnd = ({ cloneDay, type, cloneFormattedDate, monthStart, tempEndDate }) => {
        for (var type in fieldSelection) {
            if (cloneFormattedDate === fieldSelection[type].selectedEndDate?.cloneFormattedDate || cloneFormattedDate === tempEndDate?.cloneFormattedDate && !!isSameMonth(cloneDay, monthStart)) {
                return 'end'
            }
        }

        return '';

    }

    const checkSelected = ({ cloneDay, selectedDates }) => {
		const val = selectedDates.find((item) => {
			return isSameDay(item, cloneDay);
		});

		if (val) {
			return 'selected';
		}
		return false;
	};

	const restricted = (cloneDay, selectedDates, val) => {
		if (!checkSelected({ cloneDay, selectedDates })) {if (val) return 'restricted';}
	};


    const renderDates = (type) => {
        // console.log("render", type);

        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
		const today = new Date();

        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";


        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                let cloneFormattedDate = format(cloneDay, 'P');
                // console.log("r", cloneDay);
                // console.log("after format",day);
                // console.log(durationData.preFieldDatesStart);
                days.push(
                    <div
                        className={`cell  ${checkActive({ cloneDay, type, tempEndDate })} ${checkStart({ cloneDay, type, cloneFormattedDate, monthStart })} ${checkEnd({ cloneDay, type, cloneFormattedDate, monthStart, tempEndDate })} 
                        ${restricted( cloneDay, selectedDates, !!isSameMonth(day, monthStart) && compareAsc(cloneDay, today) === -1 &&
							!isSameDay(cloneDay, today) )} ${ !!isSameMonth(day, monthStart) && checkSelected({cloneDay,selectedDates,})}`}
                        key={day}
                        onClick={() => {
							if (compareAsc(cloneDay, today) !== -1 ||
                            isSameDay(cloneDay, today))
								if (
									fieldSelection[type]
										.selectedEndDate ||
									cloneDay <=
										fieldSelection[type]
											.selectedStartDate
											?.cloneDay ||
									!fieldSelection[type]
										.selectedStartDate
								) {
                                    if (
										fieldSelection[type]
											.selectedStartDate &&
										fieldSelection[type]
											.selectedStartDate
											.cloneDay &&
										isSameDay(
											fieldSelection[type]
												.selectedStartDate
												.cloneDay,
											cloneDay
										)
									) {
										const tempFieldSelection = {
											...fieldSelection,
											[type]: {
												...fieldSelection[
													type
												],
												selectedEndDate: {
													cloneDay,
													cloneFormattedDate,
												},
											},
										};

										setFieldSelection({
											...tempFieldSelection,
										});

										setSelection(false);

										if (onEndSelect) {
											onEndSelect(
												tempFieldSelection
											);
										}
									} else {
                                        setFieldSelection(
                                            (fieldSelection) => ({
                                                ...fieldSelection,
                                                [type]: {
                                                    selectedStartDate: 
                                                    {
                                                        cloneDay,
                                                        cloneFormattedDate,
                                                    },
                                                    selectedEndDate:
                                                        null,
                                                },
                                            })
                                        );
                                        setTempEndDate({
                                            cloneDay,
                                            cloneFormattedDate,
                                        });
                                        setSelection(true);
                                    }
								} else {
									const tempFieldSelection = {
										...fieldSelection,
										[type]: {
											...fieldSelection[type],
											selectedEndDate: {
												cloneDay,
												cloneFormattedDate,
											},
										},
									};

									setFieldSelection({
										...tempFieldSelection,
									});

									setSelection(false);

									if (onEndSelect) {
										onEndSelect(
											tempFieldSelection
										);
									}
								}
						}}

                        onMouseEnter={() => {
							if (compareAsc(day, today) !== -1)
								if (
									selection &&
									fieldSelection[type]
										.selectedStartDate &&
									!fieldSelection[type]
										.selectedEndDate
								) {
									if (
										cloneDay <=
										fieldSelection[type]
											.selectedStartDate
											?.cloneDay
									) {
										setTempEndDate({
											...fieldSelection[type]
												.selectedStartDate,
										});
									} else {
										setTempEndDate({
											cloneDay,
											cloneFormattedDate,
										});
									}
								}
						}}

                    >
                        <Text type="6" className="number">{(!!isSameMonth(day, monthStart)) && formattedDate}</Text>
                    </div>
                );


                day = addDays(day, 1);
            }
            rows.push(
                <div className="container-fluid" key={day}>
                    <div className="row">
                        {days}
                    </div>
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    }

    return <Container activeColor={activeColor}>
        <Header>
            <Prev onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}>
                <Icon>keyboard_arrow_left</Icon>
            </Prev>
            <Text type="2">{format(currentMonth, "MMMM yyyy")}</Text>
            <Next onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}>
                <Icon>keyboard_arrow_right</Icon>
            </Next>
        </Header>
        <DaysContainer>

            {/* {Days.map(day => <Day type="5" color="var(--deepBlue)" key={day}>{day}</Day>)} */}
        </DaysContainer>
        {renderDates(type)}
    </Container>
}
