import React, { useState, useEffect } from 'react';
import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryTooltip,
  VictoryLabel,
  VictoryLegend,
} from 'victory';
import { Heading, Icon, Button } from '../../common';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled from '@emotion/styled';
import 'react-loading-skeleton/dist/skeleton.css'
import copy_active from '../../images/copy_active.svg';
import { useInView } from 'react-intersection-observer';
import { Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const StyledAccordion = styled(Accordion)`
  display: block;
  border: none;
  background-color: transparent;

  .accordion-item {
    border: none;
    background-color: transparent;
  }

  .accordion-header {
    button {
      background-color: transparent;
      border: none;
      white-space: nowrap;
    }
  }
`;

const VerticalStackedRankedBarChart = ({ questionObject, qid, question, information, handleCopyClick, data, filters, options, questionID, isLoadingQuestion }) => {
  const colors = [
    'var(--seaBlue)',      // Primary blue
    'var(--mediumYellow)', // Orange/Yellow
    'var(--middleGreen)',  // Green
    'var(--lightRed)',     // Red
    'var(--darkGrey)',     // Dark grey
    'var(--deepPurple)',   // Purple
    'var(--greyBlue)',     // Grey blue
    'var(--yellow)',       // Yellow
    'var(--limeGreen)',    // Lime green
    'var(--sapGreen)',     // Sap green
    'var(--deepBlue)',     // Deep blue
    'var(--middleGrey)',   // Middle grey
    'var(--lightBlue)',    // Light blue
    'var(--orange)',       // Orange
    'var(--darkPurple)',   // Dark purple
  ];

  const CustomButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: var(--midWhite);
	color: var(--sapGreen);
	border-radius: 3px;
	padding: 0.4rem 0.9rem;
	img {
		padding-right: 5px;
	}
	:hover {
		background: var(--midWhite);
		color: ${(props) => props.buttonColor};
	}

	:disabled,
	[disabled] {
		background: var(--midWhite);
		border: none;
		color: var(--middleGrey);

		:hover {
			background: var(--midWhite);
			border: none;
			color: var(--middleGrey);
			cursor: not-allowed;
		}
	}
`;
const [addReport,setAddReport] = React.useState({
  contribution: null, graphItem: null
})
const updateReport = (selection)=>{
  setAddReport(selection)
}
const [ref, inView] = useInView({
  triggerOnce: true, 
  threshold: 0.5,    
});

  // Add legend data
  const legendData = data.map((choice, index) => ({
    name: choice.choiceName,
    symbol: { fill: colors[index] }
  }));

  // Simplify data transformation
  const transformedData = data.map((choice, choiceIndex) => ({
    choiceName: choice.choiceName,
    color: colors[choiceIndex],
    data: choice.rankings.map((rank, rankIndex) => ({
      x: (rankIndex + 1).toString(),
      y: parseInt(rank.anscnt || 0, 10),
      label: `${choice.choiceName}: ${rank.anscnt || 0}`
    }))
  }));

  const [activeKey, setActiveKey] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [forceRender, setForceRender] = useState(0);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(prev => !prev);
    setTimeout(() => {
      setForceRender(prev => prev + 1);
    }, 100);
  };

  useEffect(() => {
    if (isAccordionOpen) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isAccordionOpen]);

  return (
    <div style={{ marginTop: '1rem' }}>
      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <div className='d-flex justify-content-between align-items-start'>
            <Accordion.Header>
              <div>
                <Heading
                  fontSize='14px'
                  fontWeight='700'
                  color='var(--deepBlue)'
                  className='mb-0 pb-0'
                  breakHeading={false}
                  onClick={() => {
                    setActiveKey(activeKey === '0' ? null : '0');
                    handleAccordionToggle();
                  }}
                >
                  {question}
                  {activeKey === '0' ? (
                    <FaChevronUp size={10} style={{ marginLeft: '8px' }} />
                  ) : (
                    <FaChevronDown size={10} style={{ marginLeft: '8px' }} />
                  )}
                  {information && (
                    <span>
                      <Heading fontSize='12px' fontWeight='400' color='var(--deepBlue)' className='mb-0 pb-0 pl-1'>
                        {information}
                      </Heading>
                    </span>
                  )}
                </Heading>
              </div>
            </Accordion.Header>
            <div>
              <CustomButton
                fontWeight='400'
                fontSize='10px'
                size='small'
                variant='outlined'
                buttonColor='var(--sapGreen)'
                onClick={() => handleCopyClick(qid, addReport, question, questionObject)}
              >
                <img src={copy_active} />
                {' '}
                Copy to Report
              </CustomButton>
            </div>
          </div>

          <Accordion.Body>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ maxWidth: '1000px', width: '100%' }} key={`chart-${forceRender}`}>
                <VictoryChart
                  domainPadding={{ x: 20, y: 0 }}
                  height={200}
                  width={600}
                  padding={{ top: 30, bottom: 50, left: 50, right: 120 }}
                >
                  <VictoryLegend
                    x={530}
                    y={40}
                    orientation="vertical"
                    gutter={5}
                    symbolSpacer={2}
                    style={{ 
                      labels: { 
                        fontSize: 8, 
                        dy: -2,
                        fill: 'var(--paraColor)'
                      },
                      fontFamily: 'Noto Sans'
                    }}
                    data={legendData}
                  />
                  <VictoryAxis
                    label="Rankings"
                    style={{
                      axis: { stroke: 'var(--grey20)' },
                      grid: { stroke: 'var(--grey20)', strokeDasharray: '5,5' },
                      tickLabels: { 
                        fontSize: 8, 
                        padding: 5,
                        fill: 'var(--paraColor)'
                      },
                      axisLabel: { 
                        fontSize: 8, 
                        padding: 20,
                        fill: 'var(--paraColor)'
                      },
                      fontFamily: 'Noto Sans'
                    }}
                  />
                  <VictoryAxis
                    dependentAxis
                    label="Number of Users"
                    style={{
                      axis: { stroke: 'var(--grey20)' },
                      grid: { stroke: 'var(--grey20)', strokeDasharray: '5,5' },
                      tickLabels: { 
                        fontSize: 8, 
                        padding: 5,
                        fill: 'var(--paraColor)'
                      },
                      axisLabel: { 
                        fontSize: 8, 
                        padding: 30,
                        fill: 'var(--paraColor)'
                      },
                      fontFamily: 'Noto Sans'
                    }}
                  />
                  <VictoryStack>
                    {transformedData.map((item, index) => (
                      <VictoryBar
                        key={index}
                        data={item.data}
                        labels={({ datum }) => datum.label}
                        labelComponent={
                          <VictoryTooltip
                            style={{ fontSize: 8, fill: "var(--white)", fontFamily: 'Noto Sans' }}
                            flyoutStyle={{ 
                              fill: "var(--mediumGrey)", 
                              stroke: "var(--white)", 
                              strokeWidth: 1 
                            }}
                            cornerRadius={5}
                            pointerLength={10}
                          />
                        }
                        style={{
                          data: {
                            fill: item.color,
                            width: 15,
                            stroke: "var(--white)",
                            strokeWidth: 1,
                            transition: "all 0.3s ease-in-out",
                            cursor: "pointer",
                          },
                          labels: { fill: "var(--white)" }
                        }}
                      />
                    ))}
                  </VictoryStack>
                </VictoryChart>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>
      <div style={{ borderBottom: '1px solid var(--greyBlue30)' }}></div>
    </div>
  );
};

export default VerticalStackedRankedBarChart;
