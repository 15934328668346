import React, { useEffect, Suspense, lazy, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Store, persistor } from './config/sagaConfig';
import './App.css';
import { Nav, AppNav, SideNav, Spinner } from './common';
import SideNav_SA from './common/SideNav_SA';
import SideNav_A from './common/SideNav_A';
import SideNav_Respondent from './common/SideNav_Respondent';
import { BeARespondentScreen, HowItWorksScreen, LandingScreen } from './views/StaticScreen';
import SignInScreen from './views/SignInScreen/SignInScreen';
import DashboardScreen from './views/DashboardScreen/DashboardScreen';
import DashboardScreen_Respondent from './views/DashboardScreen/RespondentdashboardScreen';
import EmptyDashboardScreen from './views/DashboardScreen/EmptyDashboardScreen';
import DesignScreen from './views/Project/DesignScreen';
import InviteRecruit from './views/Project/RecruitInvite';
import EmptyRecruitInvite from './views/Project/EmptyRecruitInvite';
import ViewClipScreen from './views/Project/ViewClipsScreen';
import PreVideoCallScreen from './views/Project/PreVideoCallScreen';
import VideoCallScreen from './views/Project/VideoCallScreen';
import CallClipping from './views/Project/VideoClipScreen';
import Projects from './views/Project/Projects';
import PreCallScreen from './views/Project/PreCallScreen';
import AnalysisScreen from './views/analysis/AnalysisScreen';
import Help from './common/Help';
import ProfileScreen from './views/Profile/ProfileScreen';
import SecurityScreen from './views/Profile/SecurityScreen';
import NotificationScreen from './views/Notificaions/NotificationScreen';
import AnalysisReport from './views/analysis/AnalysisReport';
import DownloadReport from './views/analysis/DownloadReport';
import VerifyCode from './views/VerifyCode/VerifyCode';
import ResetPassword from './views/ResetPassword/ResetPassword';
import DashboardScreen_SA from './views/super_admin/DashboardScreen_SA/DashboardScreen_SA';
import DashboardScreen_A from './views/admin/DashboardScreen_A/DashboardScreen_A';
import ResetPasswordEmailNotification from './views/ResetPassword/ResetPasswordEmailNotification';
import PreCallScreenResp from './views/Project/PreCallScreenResp';
import AppNavResp from './common/AppNavResp';
import AppNavAdmin from './common/AppNavAdmin';
import AppNavSA from './common/AppNavSA';
import PageLoader from './views/PageLoader';
import RenderDashboard from './RenderDashboard';

const EditSetupScreen = lazy(() => import('./views/Project/EditSetupScreen'));
const SetupScreen = lazy(() => import('./views/Project/SetupScreen'));
const FieldsScreen = lazy(() => import('./views/Project/FieldsScreen'));
const CriteriaBudgetScreen = lazy(() => import('./views/Project/CriteriaBudgetScreen'));
const SetupSummary = lazy(() => import('./views/Project/SetupSummary'));
const ScheduleScreen = lazy(() => import('./views/Project/ScheduleScreen'));

export default function AppScreen() {
    const location = useLocation();
    const urlSegments = location.pathname.split('/');
    const lastPath = urlSegments[urlSegments.length - 1];
    const value = useSelector(state => state.LoginReducer);
    const navigate = useNavigate();
    // console.log("location.pathname",location.pathname);

    // useEffect(() => {
    //     if (value.roleId === 5) {
    //         navigate('/dashboard-super-admin', {replace : true});
    //     } else if (value.roleId === 3) {
    //         navigate('/dashboard-admin', {replace : true});
    //     } else if (value.roleId === 4) {
    //         navigate('/dashboard-respondent', {replace : true});
    //     } else if (value.roleId === 1) {
    //         navigate('/dashboard', {replace : true});
    //     }
    // }, [value.roleId]);

    // useEffect(() => {
    //   if (value.roleId === 5) {
    //       navigate('/dashboard-super-admin', {replace : true});
    //   } else if (value.roleId === 3) {
    //       navigate('/dashboard-admin', {replace : true});
    //   } else if (value.roleId === 4) {
    //       navigate('/dashboard-respondent', {replace : true});
    //   } else if (value.roleId === 1) {
    //       navigate('/dashboard', {replace : true});
    //   }
    // }, []);

    // useEffect(() => {
    //     if (value.isloggedIn && location.pathname != '/') {
    //         sessionStorage.setItem('lastLoggedInPath', location.pathname);
    //     }
    // }, [value.isloggedIn, location.pathname]);

    // useEffect(() => {
    //     const lastLoggedInPath = sessionStorage.getItem('lastLoggedInPath');
    //     console.log("lastLoggedInPath",lastLoggedInPath);
    //     if (lastLoggedInPath && value.isloggedIn && lastLoggedInPath != '/') {
    //         navigate(lastLoggedInPath, { replace: true });
    //     }
    // }, [value.isloggedIn, navigate]);



    function Loading() {
        return <PageLoader size="100px" />;
    }

    return (
        <div className="admin">
            {location.pathname !== '/dashboard-super-admin' &&
            location.pathname !== '/dashboard-admin' &&
            location.pathname !== '/dashboard-respondent' &&
            lastPath !== 'pre-call-resp' && <Help />}
            
            {location.pathname === '/dashboard-super-admin' ? <AppNavSA /> :
            location.pathname === '/dashboard-admin' ? <AppNavAdmin /> :
            location.pathname === '/dashboard-respondent' || lastPath === 'pre-call-resp' ? <AppNavResp /> : <AppNav />}
            
            {location.pathname === '/dashboard-super-admin' ? <SideNav_SA /> :
            location.pathname === '/dashboard-admin' ? <SideNav_A /> :
            location.pathname === '/dashboard-respondent' || lastPath === 'pre-call-resp' ? <SideNav_Respondent /> : <SideNav />}
            
            <Routes>
              {/* <Route path="/" element={<Navigate to="/dashboard" />} /> */}
                <Route exact path="/" element={<RenderDashboard roleId={value.roleId} isloggedIn={value.isloggedIn} />} />
                <Route path="/dashboard" element={<DashboardScreen />} />
                <Route path="/empty-dashboard" element={<EmptyDashboardScreen />} />
                <Route path="/project" element={<Projects />} />
                <Route path="/project/create" element={<Suspense fallback={<Loading />}><SetupScreen /></Suspense>} />
                <Route path="/project/:projectId/edit" element={<Suspense fallback={<Loading />}><EditSetupScreen /></Suspense>} />
                <Route path="/project/:projectId/criteria-and-budget" element={<Suspense fallback={<Loading />}><CriteriaBudgetScreen /></Suspense>} />
                <Route path="/project/:projectId/publish" element={<Suspense fallback={<Loading />}><SetupSummary /></Suspense>} />
                <Route path="/project/:projectId/invite-recruit" element={<Suspense fallback={<Loading />}><InviteRecruit /></Suspense>} />
                <Route path="/project/1/empty-invite-recruit" element={<EmptyRecruitInvite />} />
                <Route path="/project/:projectId/design" element={<DesignScreen />} />
                <Route path="/project/:projectId/schedule" element={<Suspense fallback={<Loading />}><ScheduleScreen /></Suspense>} />
                <Route path="/project/:id/field" element={<Suspense fallback={<Loading />}><FieldsScreen /></Suspense>} />
                <Route path="/project/:id/pre-call" element={<PreCallScreen />} />
                <Route path="/project/:id/pre-call-resp" element={<PreCallScreenResp />} />
                <Route path="/project/1/view-clips" element={<ViewClipScreen />} />
                <Route path="/project/1/pre-video-call" element={<PreVideoCallScreen />} />
                <Route path="/project/1/video-call" element={<VideoCallScreen />} />
                <Route path="/project/1/clipping" element={<CallClipping />} />
                <Route path="/project/:id/analysis/report" element={<AnalysisReport />} />
                <Route path="/project/:id/analysis" element={<AnalysisScreen />} />
                <Route path="/notifications" element={<NotificationScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route path="/security" element={<SecurityScreen />} />
                <Route path="/project/export" element={<DownloadReport />} />
                <Route path="/dashboard-super-admin" element={<DashboardScreen_SA />} />
                <Route path="/dashboard-admin" element={<DashboardScreen_A />} />
                <Route path="/dashboard-respondent" element={<DashboardScreen_Respondent />} />
              {/* <Route /> */}
            </Routes>
        </div>
    );
};