// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const theme = {
	fontFamily: 'Noto Sans',
	textColor: 'var(--deepBlue)',
	fontSize: '10px',
	fontWeight: '700',

	fill: 'var(--deepBlue)',
};
const StackedBarChart = ({
	data /* see data tab */,
	keys,
	colors,
	top,
	bottom,
	left,
	right,
}) => {
	const getColor = (bar) => colors[bar.id];

	return (
		<ResponsiveBar
			theme={theme}
			data={data}
			keys={keys}
			indexBy='name'
			margin={{ top: top, right: right, bottom: bottom, left: left }}
			padding={0.3}
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={getColor}
			// borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisTop={null}
			axisRight={null}
			axisLeft={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				tickValues: [0, 5, 10, 15],
			}}
			axisBottom={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
			}}
			labelSkipWidth={10}
			labelSkipHeight={12}
			labelTextColor='var(--white)'
			markers={[
				{
					axis: 'x',
					value: 0,

					lineStyle: { stroke: 'var(--grey20)', strokeWidth: 1 },
				},
				{
					axis: 'y',
					value: 0,
					lineStyle: { stroke: 'var(--grey20)', strokeWidth: 1 },
				},
			]}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'top-right',
					direction: 'row',
					justify: false,
					translateX: 144,
					translateY: -54,
					itemsSpacing: 0,
					itemWidth: 85,
					itemHeight: 49,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 13,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			role='application'
			ariaLabel='Nivo bar chart demo'
			barAriaLabel={function (e) {
				return (
					e.id +
					': ' +
					e.formattedValue +
					' in country: ' +
					e.indexValue
				);
			}}
		/>
	);
};

export default StackedBarChart;
