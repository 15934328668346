import React, { useCallback, useState, useEffect, useRef } from 'react';

import ProfileSettingWrapper, { Input } from './ProfileSettingWrapper';
import profile_pic from '../../images/profile_photo.png';
import styled from '@emotion/styled';
import { Button, Icon, Text } from '../../common';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
// import ImageCrop from '../../components/common/ImageCrop';
import { REQUEST_GETPROFILEDATA } from '../../constants/profile/GetProfileData';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_EDITPROFILEDATA } from '../../constants/profile/EditProfileData';
import avatar from '../../images/researcher.png';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const Image = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 100px;
`;
const CustomLabel = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Colour/Sap Green/Text */

  color: var(--sapGreen);
  padding: 0;
  margin-bottom: 0;
  padding-bottom: 0;

  border-bottom: 1px solid var(--sapGreen);
  &:hover {
    color: var(--sapGreen);
  }
`;

const StyledButton = styled(Button)`
  width: 140px;
`;
const initialState = {
  full_name: 'Lisa M.',
  phone: '+91  9988776655',
  email: 'lisa@yourworkemail.com'
};
function ProfileScreen() {
  const [values, setValues] = useState(initialState);
  const [image_crop, setImage_crop] = useState(profile_pic);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [GetProfileData, setGetProfileData] = useState([]);
  const [IsFlag, setIsFlag] = useState(0);
  const dispatch = useDispatch();
  const userId = useSelector(state => state.LoginReducer.userId);
  const EditProfileData = useSelector(state => state.EditProfileReducer.EditProfileData);
  const EditProfileStatus = useSelector(state => state.EditProfileReducer.EditProfileStatus);
  const [formData, setFormData] = useState({
    mobile: '',
    email: '',
    name: ''
  });

  const [errors, setErrors] = useState({
    mobile: '',
    email: '',
    name: ''
  });

 const initialLoad = useRef(true);
  const toastDisplayed = useRef(false);

  useEffect(() => {

    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (EditProfileStatus  && !toastDisplayed.current ) {
      const message = EditProfileStatus.StatusDesc;

      if (EditProfileStatus?.StatusCode === '-1') {
        toast.error(<>{message}</>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        });
      } else if (EditProfileStatus?.StatusCode === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'var(--primaryColor)', marginRight: '8px', fontSize: '1.5em' }}
            />
            <span>{message}</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: false,
            transition: Bounce
          }
        );
      }
      toastDisplayed.current = true;
  
      setTimeout(() => {
        dispatch({ type: 'CLEAR_EDITPROFILEDATA' });
        toastDisplayed.current = false;
      }, 4500);
    }
  }, [EditProfileStatus]);

  const getGetProfileData = useSelector(state => state.GetProfileReducer.GetProfileData?.userProfileData[0]);
  useEffect(() => {
    setGetProfileData(getGetProfileData);
  }, [getGetProfileData]);

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      try {
        if (image_crop) {
          const croppedImage = await getCroppedImg(image_crop, croppedAreaPixels);
          setCroppedImage(croppedImage);
        }
      } catch (e) {
        // console.error(e);
      }
    },
    [image_crop]
  );

  const handleCropChange = crop => {
    setCrop(crop);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image_crop, croppedAreaPixels);

      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const onImageChange = event => {
    const fileInput = event.target; // Reference to the file input
  
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
  
      if (fileSizeInMB > 5) {
        // Show error toast
        toast.error('File size exceeds 5 MB. Please upload a smaller file.', {
          position: 'top-center',
          autoClose: 5000,
        });
  
        // Reset image to API-provided image or default profile picture
        setImage_crop(GetProfileData?.presignedUrl || profile_pic);
  
        // Reset file input value
        fileInput.value = null;
  
        return; // Prevent further execution
      }
  
      // Set the image crop with the uploaded file
      setImage_crop(URL.createObjectURL(file));
    } else {
      // Reset image to API-provided image or default profile picture
      setImage_crop(GetProfileData?.presignedUrl || profile_pic);
  
      // Reset file input value
      fileInput.value = null;
    }
  };
  
  
  

  useEffect(() => {
    if (GetProfileData?.presignedUrl) {
      setImage_crop(GetProfileData.presignedUrl);
    } else {
      setImage_crop(profile_pic);
    }
  }, [GetProfileData]);
  

  // const handleConfim = async evt => {
  //   let imageName = evt.target.name;

  //   let file = await fetch(croppedImage)
  //     .then(r => r.blob())
  //     .then(blobFile => new File([blobFile], imageName, { type: 'image/png' }));

  //   setGetProfileData({
  //     ...GetProfileData,
  //     presignedUrl: croppedImage,
  //     [imageName]: croppedImage,
  //     file: file
  //   });
  // };


  const handleConfim = async evt => {
    if (!croppedImage) {
      // If croppedImage is invalid, fall back to the API-provided image or default profile picture
      setImage_crop(GetProfileData?.presignedUrl || profile_pic);
      return;
    }
  
    try {
      let imageName = evt.target.name;
  
      // Create a file blob from the croppedImage
      let file = await fetch(croppedImage)
        .then(r => r.blob())
        .then(blobFile => new File([blobFile], imageName, { type: 'image/png' }));
  
      setGetProfileData(prevData => ({
        ...prevData,
        presignedUrl: croppedImage,
        [imageName]: croppedImage,
        file: file,
      }));
  
     
    } catch (error) {
      
    }
  };
  
  
  useEffect(() => {
    if (GetProfileData) {
      setFormData({
        mobile: GetProfileData.mobile || '',
        email: GetProfileData.email || ''
      });

      setErrors({
        mobile: '',
        email: ''
      });
    }
  }, [GetProfileData]);

  const handleChnage = evt => {
    const value = evt.target.value;
    setGetProfileData({
      ...GetProfileData,
      [evt.target.name]: value
    });
  };

  const validatePhone = phone => {
    // Validate phone number (10 digits)
    return /^[0-9]{10}$/.test(phone);
  };

  const validateEmail = email => {
    // Email regex to match proper formats
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in)$/;
    return emailRegex.test(email);
  };

  const handleChangePhone = e => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, '').slice(0, 10);
    setFormData(prevData => ({ ...prevData, mobile: numericValue }));
  };

  const handleChangeEmail = e => {
    const { value } = e.target;
    const trimmedValue = value.trim(); // Remove extra spaces
    setFormData(prevData => ({ ...prevData, email: trimmedValue }));
    setErrors(prevErrors => ({
      ...prevErrors,
      email: validateEmail(trimmedValue) ? '' : 'Enter a valid email address (e.g., user@domain.com or user@domain.in).'
    }));
  };

  const applyProfileChanges = () => {
    // Validate inputs
    const updatedErrors = {
      mobile: validatePhone(formData.mobile) ? '' : 'Phone number must be 10 digits.',
      email: validateEmail(formData.email)
        ? ''
        : 'Enter a valid email address (e.g., user@domain.com or user@domain.in).'
    };

    // Set validation errors
    setErrors(updatedErrors);

    // Check if there are any errors
    const hasErrors = Object.values(updatedErrors).some(error => error !== '');

    if (hasErrors) {
      // Show error toast
      toast.error('Please correct the errors before submitting.', {
        position: 'top-center',
        autoClose: 5000
      });
      return; // Prevent further action
    }

    // Create updated profile data
    const updatedProfileData = {
      ...GetProfileData,
      mobile: formData.mobile,
      email: formData.email
    };

    // Prepare payload
    const formDataPayload = new FormData();
    formDataPayload.append('user_ID', updatedProfileData.user_ID);
    formDataPayload.append('email', updatedProfileData.email);
    formDataPayload.append('mobile', updatedProfileData.mobile);
    formDataPayload.append('name', updatedProfileData.name);
    formDataPayload.append('profilePicture', updatedProfileData.file);

    // Dispatch changes
    dispatch({
      type: REQUEST_EDITPROFILEDATA,
      payload: formDataPayload
    });

    // Reset errors after successful submission
    setErrors({
      mobile: '',
      email: ''
    });

    // Refresh profile data after changes
    setTimeout(() => {
      dispatch({
        type: REQUEST_GETPROFILEDATA,
        payload: {
          user_ID: userId
        }
      });
    }, 500);
  };

  const discardProfileChanges = () => {
    dispatch({
      type: REQUEST_GETPROFILEDATA,
      payload: {
        user_ID: userId
      }
    });
    setIsFlag(1);
  };

  return (
    <ProfileSettingWrapper>
      <div
        class="modal fade "
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
          style={{
            width: '620px'
          }}
        >
          <div class="modal-content" style={{ height: '300px' }}>
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <Icon>close</Icon>
              </button>

              <div className="d-flex align-items-center px-3 py-4" style={{ position: 'relative' }}>
                <div
                  style={{
                    width: '200px',
                    height: '230px',
                    // background: 'red',
                    position: 'relative'
                  }}
                  className="mr-5"
                >
                  <div
                    style={{
                      width: '200px',
                      height: '230px'
                    }}
                  >
                    <Cropper
                      cropShape="round"
                      showGrid={false}
                      image={image_crop}
                      crop={crop}
                      minZoom={0}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={handleCropChange}
                      onCropComplete={onCropComplete}
                      showCroppedImage={showCroppedImage}
                      onZoomChange={setZoom}
                      restrictPosition={false}
                      cropSize={{ width: 170, height: 170 }}
                    />
                  </div>
                </div>
                <div>
                  <Text fontSize="18px" fontWeight="500" color="var(--deepBlue)" className="mb-4">
                    Crop Profile Picture
                  </Text>
                  <div className="d-flex">
                    <StyledButton
                      fontWeight="700"
                      fontSize="13px"
                      size="small"
                      buttonColor="var(--sapGreen)"
                      name="profileImagePath"
                      onClick={e => {
                        handleConfim(e);
                        document.querySelector('[data-dismiss="modal"]').click();
                      }}
                    >
                      Confirm
                    </StyledButton>
                    <StyledButton
                      variant="outlined"
                      fontWeight="700"
                      fontSize="13px"
                      size="small"
                      buttonColor="var(--sapGreen)"
                      className="ml-2"
                      data-dismiss="modal"
                    >
                      Cancel
                    </StyledButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-6 ">
          <div className="d-flex align-items-center">
            <Image src={GetProfileData?.presignedUrl != null ? GetProfileData?.presignedUrl : avatar} />

            <div className="mx-4">
              <CustomLabel for="file-upload" class="custom-file-upload" data-toggle="modal" data-target="#exampleModal">
                Change Profile Picture
              </CustomLabel>
              <input id="file-upload" type="file" style={{ display: 'none' }} onChange={onImageChange} />

              <div className="d-flex align-items-center mt-2">
                <Text fontSize="11px" fontWeight="300" color="var(--deepBlue)" className="mr-2">
                  Jpeg, Png, Jpg
                </Text>
                <Text fontSize="11px" fontWeight="300" color="var(--deepBlue)">
                  Maximum file size 5MB
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Input
            labelText="Full Name"
            name="name"
            value={GetProfileData.name}
            onChange={handleChnage}
            setIsFlag={setIsFlag}
            IsFlag={IsFlag}
          />
        </div>
      </div>

      {/* <div className="row align-items-center mt-5">
        <div className="col-md-6">
          <Input
            labelText="Phone Number"
            name="mobile"
            value={formData.mobile}
            showCharacters={false}
            onKeyPress={e => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
              if (formData.mobile.length >= 10) {
                e.preventDefault();
              }
            }}
            maxLength={10}
            style={{ paddingRight: '2rem' }}
            onChange={handleChangePhone}
            setIsFlag={setIsFlag}
            IsFlag={IsFlag}
          />

          {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
        </div>
        <div className="col-md-6">
          <Input
            showCharacters={false}
            labelText="Email"
            name="email"
            value={formData.email}
            placeholder="e.g.,user@domain.com or user@domain.in"
            onChange={handleChangeEmail}
            setIsFlag={setIsFlag}
            IsFlag={IsFlag}
          />
          {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
        </div>
      </div> */}
      <div className="row align-items-center mt-5">
        <div className="col-md-6">
          <Input
            labelText="Phone Number"
            name="mobile"
            value={GetProfileData?.mobile}
            showCharacters={false}
            onChange={handleChnage}
            setIsFlag={setIsFlag}
            readOnly={true} 
            style={{ paddingRight: '2rem', cursor: 'not-allowed' }}
            IsFlag={IsFlag}
          />
          
        </div>
        <div className="col-md-6">
          <Input
            showCharacters={false}
            labelText="Email"
            name="email"
            value={GetProfileData?.email}
            onChange={handleChnage}
            setIsFlag={setIsFlag}
            readOnly={true} 
            style={{ cursor: 'not-allowed' }}
            IsFlag={IsFlag}
          />
        </div>
      </div>
      <div className="d-flex mt-5">
        <Button
          fontSize="13px"
          fontWeight="700"
          size="small"
          buttonColor="var(--sapGreen)"
          className="mr-4"
          onClick={applyProfileChanges}
        >
          Apply Changes
        </Button>
        <Button
          fontSize="13px"
          fontWeight="700"
          size="small"
          buttonColor="var(--sapGreen)"
          variant="outlined"
          onClick={discardProfileChanges}
        >
          Discard Changes
        </Button>
      </div>
      <ToastContainer />
    </ProfileSettingWrapper>
  );
}

export default ProfileScreen;
