import React, { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { LandingScreen } from "./views/StaticScreen";

const RenderDashboard = ({ roleId, isloggedIn }) => {
    const value = useSelector(state => state.LoginReducer);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isloggedIn) {
            switch (roleId) {
                case 4:
										setTimeout(() => {
												navigate('/dashboard-respondent', { replace: true });
										},500)
                    break;
                case 5:
										setTimeout(() => {
												navigate('/dashboard-super-admin', { replace: true });
										},500)
                    break;
                case 3:
										setTimeout(() => {
												navigate('/dashboard-admin', { replace: true });
										},500)
                    break;
                case 1:
                    setTimeout(() => {
                        navigate('/dashboard', { replace: true });
                    },500)
                    break;
                default:
                    break;
            }
        }
    }, [isloggedIn, roleId, location.pathname, navigate]);

    useEffect(() => {
     
        if (isloggedIn) {
            switch (roleId) {
                case 4:
                    if(location.pathname !== '/dashboard-respondent'){
                        navigate('/dashboard-respondent', { replace: true });
                    }
                    break;
                case 5:
                    if(location.pathname !== '/dashboard-super-admin'){
                        navigate('/dashboard-super-admin', { replace: true });
                    }
                    break;
                case 3:
                    if(location.pathname !== '/dashboard-admin'){
                        navigate('/dashboard-admin', { replace: true });
                    }
                    break;
                case 1:
                    setTimeout(() => {
                        navigate('/dashboard', { replace: true });
                    },500)
                    break;
                default:
                    break;
            }
        }
    }, []);

    useEffect(() => {
        if (value.isloggedIn) {
            sessionStorage.setItem('lastLoggedInPath', location.pathname);
        }
    }, [value.isloggedIn, location.pathname]);

    useEffect(() => {
        const lastLoggedInPath = sessionStorage.getItem('lastLoggedInPath');
        if (lastLoggedInPath && value.isloggedIn) {
            navigate(lastLoggedInPath, { replace: true });
        }
    }, [value.isloggedIn, navigate]);

    if (isloggedIn) {
        return null;
    } else {
        return <LandingScreen />;
    }
}

export default RenderDashboard;